@import "../button/assets";
@import "./style";

$default-label-style: map.get($label-styles, "default");

@mixin e-label {
  @include typography("body");

  align-items: center;
  background: map.get($default-label-style, "background");
  border: 1px solid map.get($default-label-style, "border");
  border-radius: $border-radius;
  display: inline-flex;
  -webkit-font-smoothing: subpixel-antialiased;
  height: size("base", 3);
  line-height: size("base", 3);
  margin: 0 $space-2xs 0 0;
  padding: 0 $space-xs;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;

  &-actionable {
    cursor: pointer;
    transition: background-color $transition-time ease-in-out;

    &:hover {
      background: map.get($default-label-style, "hover-background");
    }
  }
}

@mixin e-label-close {
  align-items: center;
  cursor: pointer;
  display: flex;
  -webkit-font-smoothing: none;
  height: 100%;
  justify-content: center;
  margin-right: (-$space-2xs);
  vertical-align: top;
  width: size("icon", "medium");

  &::after {
    color: var(--token-text-default);
    content: var(--token-glyphs-close);
    font-family: var(--token-icon-default-fontFamily);
    font-size: var(--token-icon-default-size);
  }
}

@mixin e-label-close-white {
  &::after {
    color: var(--token-text-light);
    content: var(--token-glyphs-close);
    font-family: var(--token-icon-default-fontFamily);
    font-size: var(--token-icon-default-size);
  }

  @include has-focus-state(-2px, solid, 2px, var(--token-outline-focusAlternative));

  &:active,
  &:active:hover {
    border-color: var(--token-outline-focusAlternative);
  }
}

@mixin e-label-tag-close {
  cursor: pointer;
  display: inline-block;
  height: 100%;
  vertical-align: top;
  width: 26px;

  &::after {
    color: var(--token-text-default);
    content: var(--token-glyphs-close);
    font-family: var(--token-icon-default-fontFamily);
    font-size: var(--token-icon-default-size);
  }
}

.e {
  &-label {
    @include e-label;

    @each $type, $data in $label-styles {
      @if $type != "default" {
        &-#{$type} {
          background: map.get($data, "background");
          border-color: map.get($data, "border");
          color: map.get($data, "text");

          &.e-label-actionable:hover {
            background: map.get($data, "hover-background");
          }

          .e-label__close {
            @include e-label-close-white;
          }
        }
      }
    }

    &__close {
      @include e-label-close;
      @include e-btn-style("on_overlay", $btn-styles);

      border: 2px solid transparent;
      border-radius: $border-radius - 2px;
      height: size-unitless("base", 3) - 4px;
      margin-left: $space-2xs;
      margin-right: (-$space-xs) + 1px;
      width: size-unitless("base", 3) - 4px;

      &::after {
        color: var(--token-icon-default-text);
        content: var(--token-glyphs-close);
        font-family: var(--token-icon-default-fontFamily);
        font-size: var(--token-icon-default-size);
      }

      @include has-focus-state(-2px, solid, 2px, var(--token-outline-focus));

      &:hover {
        background-color: color-mix(in srgb, var(--token-neutral-999) 10%, transparent);
      }

      &:active,
      &:active:hover {
        border-color: var(--token-button-toolbar-borderActive);
      }
    }

    &__icon {
      left: 0;
      margin-right: $space-2xs;
    }

    &-tag {
      .e-label__close {
        @include e-label-tag-close;

        height: size("base", 3);
        margin-right: -$space-2xs;
      }
    }

    &-tagselector {
      cursor: auto;
      height: auto;
      margin: $space-3xs;
      min-height: size("base", 3);
      text-align: left;
      white-space: normal;

      .e-label__close {
        flex-shrink: 0;
      }
    }

    &-active {
      background-color: var(--token-error-300);
    }

    &-disabled {
      color: var(--token-text-faint);
      cursor: not-allowed;

      .e-label__close {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}
