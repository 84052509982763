@import "../../style/core/palette/palette";
@import "../../style/components/radio/style";

$label-selector: string.unquote(
  "+ .e-checkbox__label, + .e-checkbox__box + .e-checkbox__label, + label, + .e-checkbox__box + label"
);
$checkbox-selector: string.unquote("+ .e-checkbox__box, + .e-checkbox__label, + label");
$default-checkbox-style: map.get($radio-styles, "default");
$error-checkbox-style: map.get($radio-styles, "error");

e-checkbox {
  display: inline-block;
  line-height: normal;
}

.e-checkbox {
  display: inline;
  opacity: 0;
  position: absolute;

  e-checkbox & {
    left: 0;
    top: 0;

    &__wrapper {
      display: inline-flex;
      position: relative;
    }

    &:disabled + .e-checkbox__box {
      opacity: 0.4;
    }

    &,
    #{$label-selector},
    #{$checkbox-selector} {
      flex: 1 1 auto;
    }
  }

  #{$label-selector} {
    @include typography("body");

    cursor: pointer;
    display: inline-block;
    margin-right: $space-s;
    position: relative;
    user-select: none;
  }

  #{$checkbox-selector} {
    cursor: pointer;
    display: inline-block;
    min-height: size("base", 2);
    padding-left: size-unitless("base", 2) + $space-xs;
    position: relative;

    &::before {
      background-color: map.get($default-checkbox-style, "background");
      border: 1px solid map.get($default-checkbox-style, "border-color");
      border-radius: math.div($border-radius, 2);
      content: "";
      display: inline-block;
      height: size("base", 2);
      left: 0;
      margin-right: $space-xs;
      position: absolute;
      top: $space-2xs;
      transition: all $transition-time ease-in-out;
      width: size("base", 2);
    }

    &:hover::before {
      @include glow(map.get($default-checkbox-style, "hover-glow"));

      background-color: map.get($default-checkbox-style, "hover-background");
    }
  }

  &:focus {
    #{$label-selector} {
      color: map.get($default-checkbox-style, "focus-color");
    }

    #{$checkbox-selector} {
      &::before {
        border-color: map.get($default-checkbox-style, "focus-border-color");

        @include can-have-focus-style {
          @include has-focus-style(2px, solid, 2px, var(--token-outline-focus));
        }
      }
    }
  }

  &:checked {
    #{$checkbox-selector} {
      &::after {
        color: var(--token-text-highlight);
        content: var(--token-glyphs-checkmark);
        font-family: var(--token-icon-default-fontFamily);
        font-size: var(--token-icon-default-sizeSmall);
        height: size("base", 2);
        left: 0;
        line-height: 1;
        position: absolute;
        top: $space-2xs;
        width: size("base", 2);
      }

      &:hover::before {
        @include glow(map.get($default-checkbox-style, "hover-glow"));

        background-color: map.get($default-checkbox-style, "hover-background");
      }
    }
  }

  &:indeterminate {
    #{$checkbox-selector} {
      &::after {
        color: var(--token-text-highlight);
        content: var(--token-glyphs-minus);
        font-family: var(--token-icon-default-fontFamily);
        font-size: var(--token-icon-default-sizeSmall);
        left: 0;
        line-height: normal;
        position: absolute;
        top: 4px;
      }
    }
  }

  &:disabled {
    #{$label-selector} {
      cursor: not-allowed;
      opacity: 0.5;
    }

    #{$checkbox-selector} {
      cursor: not-allowed;

      &::after {
        opacity: 1;
      }

      &::before {
        background-color: map.get($default-checkbox-style, "background");
        opacity: 1;
      }

      &:hover::before {
        @include glow("none");

        background-color: map.get($default-checkbox-style, "background");
      }
    }
  }

  &.e-input-error {
    opacity: 0;

    #{$label-selector} {
      color: map.get($error-checkbox-style, "color");
    }

    #{$checkbox-selector} {
      &::before {
        background-color: map.get($error-checkbox-style, "background");
        border: 1px solid map.get($error-checkbox-style, "border-color");
      }

      &:hover::before {
        @include glow(map.get($error-checkbox-style, "hover-glow"));

        background-color: map.get($error-checkbox-style, "hover-background");
      }
    }

    &:disabled {
      #{$checkbox-selector}:hover:before {
        @include glow("none");

        background-color: map.get($error-checkbox-style, "background");
      }
    }

    &:checked {
      #{$checkbox-selector} {
        &::before {
          background-color: map.get($error-checkbox-style, "background");
        }

        &::after {
          color: var(--token-error-default);
        }
      }
    }
  }

  &-block {
    #{$label-selector} {
      display: inline-block;
    }

    #{$checkbox-selector} {
      &::before {
        top: $space-2xs;
      }
    }

    &:checked {
      #{$checkbox-selector} {
        &::after {
          top: $space-2xs;
        }
      }
    }
  }

  &-nocolor_empty:not(:checked) {
    #{$checkbox-selector} {
      &::before {
        background: transparent;
      }
    }
  }

  &-onlycheckbox {
    #{$checkbox-selector} {
      margin-right: 0;
      padding-left: size-unitless("base", 2) + $space-xs;
    }
  }

  &-right {
    #{$label-selector} {
      display: block;
      margin-right: 0;
      padding-left: 0;
      padding-right: $space-l;
    }

    #{$checkbox-selector} {
      &::before {
        left: auto;
        margin-left: $space-xs;
        margin-right: 0;
        position: absolute;
        right: 0;
        top: $space-2xs;
      }
    }

    &:checked {
      #{$checkbox-selector} {
        &::after {
          left: auto;
          right: 0;
          top: $space-2xs;
        }
      }
    }
  }

  @each $type, $data in $radio-styles {
    @if $type != "default" {
      &-#{$type} {
        #{$label-selector} {
          color: map.get($data, "color");
        }

        #{$checkbox-selector} {
          &::before {
            background-color: map.get($data, "background");
            border-color: map.get($data, "border-color");
          }

          &:hover::before {
            @include glow(map.get($data, "hover-glow"));

            background-color: map.get($data, "hover-background");
          }
        }

        &:focus {
          #{$label-selector} {
            color: map.get($data, "focus-color");
          }

          #{$checkbox-selector} {
            &::before {
              border-color: map.get($data, "focus-border-color");
            }
          }
        }

        &:indeterminate {
          #{$checkbox-selector} {
            &::after {
              color: var(--token-#{$type}-default);
            }
          }
        }

        &:checked {
          #{$checkbox-selector} {
            &::after {
              color: var(--token-#{$type}-default);
            }

            &:hover::before {
              @include glow(map.get($data, "hover-glow"));

              background-color: map.get($data, "hover-background");
            }
          }
        }

        &:disabled {
          #{$checkbox-selector} {
            &::before {
              background-color: map.get($default-checkbox-style, "background");
            }

            &:hover::before {
              @include glow("none");

              background-color: map.get($default-checkbox-style, "background");
            }
          }
        }
      }
    }
  }

  $combined-palettes: list.join(
    list.join($palette_reporting, $palette_socialads),
    $palette_smartinsight
  );

  @each $class, $data in $combined-palettes {
    &-#{$class} {
      #{$label-selector} {
        color: #{$data};
      }

      &:focus {
        #{$label-selector} {
          color: #{$data};
        }
      }
    }
  }
}
