.e {
  &-legend {
    line-height: 1;

    .e-icon:not(.e-icon-helper) {
      font-size: var(--token-icon-default-sizeSmall);
      height: var(--token-icon-default-sizeSmall);
      margin: (-$space-2xs) $space-2xs 0 0;
      width: var(--token-icon-default-sizeSmall);
    }

    &__title {
      & + .e-legend__value {
        margin-top: $space-3xs;
      }
    }

    &__value {
      @include typography("heading3");

      small {
        @include typography("small");

        color: var(--token-text-faint);
      }

      & + .e-legend__description {
        margin-top: $space-3xs;
      }
    }

    &__trend {
      align-items: center;
      background-color: var(--token-neutral-300);
      border-radius: $border-radius-small;
      display: inline-flex;
      height: size("base", 2);
      justify-content: center;
      vertical-align: bottom;
      width: size("base", 2);

      &-upwards {
        background-color: var(--token-success-default);
      }

      &-downwards {
        background-color: var(--token-error-default);
      }
    }

    &__secondaryvalue {
      @include typography("small");

      color: var(--token-text-faint);
      padding-left: $space-2xs;

      &-block {
        margin-top: 0;
        padding: 0;
      }
    }

    &__description {
      @include typography("small");

      color: var(--token-text-faint);
    }

    &-large {
      .e-legend__title {
        @include typography("body");

        .e-icon {
          font-size: var(--token-icon-default-sizeSmall);
          height: var(--token-icon-default-sizeSmall);
          margin: (-$space-2xs) $space-xs 0 0;
          width: var(--token-icon-default-sizeSmall);
        }
      }

      .e-legend__value {
        @include typography("heading1");

        small {
          @include typography("body");
        }

        & + .e-legend__description {
          margin-top: $space-2xs;
        }
      }
    }

    & + .e-legend {
      margin-top: $space-xs;
    }
  }
}
