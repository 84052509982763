@mixin e-box-style {
  background-color: var(--token-box-default-background);
}

@mixin e-box {
  @include e-box-style;

  margin-bottom: $space-xl;
  padding: $space-m;
  padding-bottom: $space-xl;
}

.e {
  &-box {
    @include e-box;

    border: 1px solid var(--token-box-default-border);
    border-radius: $border-radius;

    &-inner {
      background-color: var(--token-box-alternate-background);
      border-color: var(--token-box-alternate-border);
      box-shadow: none;
      padding: $space-xl;
    }

    &-charts {
      background-color: var(--token-box-alternate-background);
      border-color: var(--token-box-alternate-border);
      box-shadow: none;
      padding: 0;
    }

    &-chart {
      box-shadow: none;
      padding: $space-m;
    }

    &-simple {
      margin: 0;
      padding: $space-m;
    }

    &-nopadding {
      padding: 0;

      .e-box__header {
        margin: 0;
      }
    }

    &-no_margin {
      margin: 0;
    }

    &-smallmargin {
      margin-bottom: $space-m;
    }

    &-summary {
      border: 1px solid var(--token-box-default-border);
      border-bottom: 0;
      border-right: 0;
      border-top: 0;
      bottom: 0;
      box-shadow: var(--token-shadow-medium);
      margin-bottom: 0;
      max-width: size("base", 45);
      padding: $space-xl;
      position: absolute;
      right: 0;
      top: 0;
      z-index: z("fixed-element");

      &__close {
        position: absolute;
        right: $space-2xs;
        top: $space-2xs;
      }

      &__title {
        @include word-wrap;
        @include typography("body");

        color: var(--token-box-alternate-text);
      }
    }

    &-login {
      @include clearfix;

      background-color: var(--token-login-background);
      box-shadow: none;
      color: var(--token-login-text);
      display: inline-block;
      padding: $space-l;

      &-dark {
        background-color: var(--token-login-backgroundDark);
      }

      &-wide {
        max-width: size("base", 150);
      }

      h1 {
        color: var(--token-login-text);
      }
    }

    &__loginform {
      display: inline-block;
      margin-top: -$space-s;
      vertical-align: top;
      width: size("base", 45);
    }

    &__loginsplash {
      margin-left: $space-l;
    }

    &__header {
      align-items: center;
      background-color: var(--token-box-alternate-background);
      border-bottom: 1px solid var(--token-box-alternate-border);
      display: flex;
      height: size("base", 5);
      margin: (-$space-m) (-$space-m) 0;

      > * {
        flex: 0 0 auto;

        &.e-box__title {
          flex: 1 1 auto;
        }
      }
    }

    &__title {
      @include text-overflow;
      @include typography("body");

      padding-left: $space-m;
    }
  }
}
