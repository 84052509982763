.e {
  &-popup {
    background-color: var(--token-background-default);
    height: 100%;

    .e-layout__header {
      background: transparent;
      box-shadow: none;
    }

    &__hidden {
      .e-popup & {
        // stylelint-disable declaration-no-important
        display: none !important;
      }
    }

    .fixedheader-container {
      padding-top: 0;
    }

    .e {
      &-topnav {
        display: none;
      }

      &-container {
        min-width: size("base", 60);
      }
    }
  }
}
