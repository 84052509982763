ec-chart {
  display: block;
  font-size: 0;
  line-height: 1.667;
  position: relative;

  [uuid] {
    pointer-events: none;
  }

  [axis-x],
  [axis-y-left],
  [axis-y-right] {
    @include typography("small");

    text {
      fill: var(--token-text-default);
    }
  }

  [axis-y-left] line,
  [gridline] line {
    stroke: var(--token-border-default);
  }

  [interaction-layer] {
    fill-opacity: 0;
    stroke: var(--token-background-default);

    rect {
      stroke-width: 0;
    }
  }

  [tooltip] {
    fill-opacity: 0;
    stroke: var(--token-background-default);
  }

  [locator-group] {
    display: none;

    circle {
      fill-opacity: 1;
      stroke-width: 2;

      &:where([locator]) {
        fill: var(--token-background-default);
      }
    }
  }

  [type="area"] {
    [area] {
      fill-opacity: 0.75;
    }
  }

  [type="line"] {
    [line] {
      fill: none;
      stroke-width: 2;
    }
  }

  [type="column"] {
    [highlight] {
      filter: brightness(1.25) saturate(0.75);
    }

    [highlight-last] {
      filter: brightness(0.75) saturate(1.25);
    }

    [highlight][highlight-last] {
      filter: none;
    }
  }

  [markers] {
    circle {
      fill: var(--token-background-default);
      stroke-width: 2;
    }
  }

  [baseline] {
    line {
      stroke: var(--token-border-strong);
    }
  }

  .domain {
    stroke: var(--token-border-strong);
  }
}

.ec {
  &-chart {
    &__series {
      &-muted {
        opacity: 0.4;
      }
    }
  }

  &-tooltip {
    background-color: var(--token-box-strong-background);
    border: 1px solid 1px var(--token-box-strong-border);
    border-radius: $border-radius;
    box-shadow: var(--token-shadow-medium);
    min-width: size("base", 20);
    overflow: auto;
    pointer-events: none;
    z-index: 602;

    &-hidden {
      display: none;
    }

    &__header {
      @include typography("body");

      align-items: center;
      background: var(--token-box-alternate-background);
      border-bottom: 1px solid var(--token-box-alternate-border);
      display: flex;
      font-weight: 500;
      height: size("base", 4);
      overflow-wrap: break-word;
      padding: 0 $space-s;
      word-break: break-word;
      word-wrap: break-word;

      &:first-child {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }

      &:not(:first-child) {
        border-top: 1px solid var(--token-box-default-border);
      }
    }
  }

  &-table {
    width: 100%;

    &__row {
      border-bottom: 1px solid var(--token-tableCell-default-border);
      color: var(--token-text-default);
      font-size: 14px;

      &:last-child {
        border-bottom: 0;
      }

      &-header {
        background-color: var(--token-tableCell-alternate-background);
        font-weight: 500;
      }

      &-stack {
        background-color: var(--token-tableCell-alternate-background);
        border-top: 0;
        font-weight: 500;
      }
    }

    &__name {
      padding: $space-2xs $space-xs $space-2xs $space-s;
    }

    &__data {
      padding: $space-2xs $space-s $space-2xs $space-xs;
      text-align: right;
    }
  }
}

@keyframes ec-fade {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.e {
  &-chart {
    &-si {
      background-color: var(--token-box-alternate-background);
      border: var(--token-box-alternate-border);
      display: table;
      table-layout: fixed;
      width: 100%;
    }

    &__expand {
      float: right;
      position: relative;
      top: -20px;
    }

    &-small {
      height: size("base", 5);
    }

    &-medium {
      height: size("base", 13);
    }

    &__pie {
      float: left;
      height: size("base", 13);
      padding-right: $space-m;

      &-reporting {
        height: size("base", 15);
      }

      &-centered {
        float: none;
        margin: $space-m 0 $space-xs;
        padding-right: 0;
        text-align: center;
      }

      > div {
        height: size("base", 16);
        width: size("base", 16);
      }
    }

    &__info {
      text-align: center;

      &-reporting {
        padding-bottom: $space-m;
        text-align: left;
      }

      &__icon {
        float: left;
        margin-right: $space-m;

        .e-btn {
          height: size("base", 7);
          width: size("base", 7);

          .e-icon {
            font-size: var(--token-icon-default-size);
            height: var(--token-icon-default-size);
            margin-top: $space-2xs;
            width: var(--token-icon-default-size);
          }
        }
      }

      &__title {
        @include typography("heading1");
      }

      &__subtitle {
        @include typography("body");

        font-weight: 500;
      }
    }

    &__title {
      @include text-overflow;
      @include typography("body");

      small {
        padding-left: $space-2xs;
      }
    }

    &__value {
      @include text-overflow;
      @include typography("body");

      small {
        padding-left: $space-2xs;
      }
    }
  }
}
