.e {
  &-node {
    &__label {
      text-align: center;
      width: size("base", 16);

      &__text {
        background-color: var(--token-highlight-default);
        color: var(--token-neutral-000);
        display: inline-block;
        min-width: size("base", 5);
        padding: $space-3xs $space-xs;

        &.status-error {
          background-color: var(--token-error-default);
        }

        &.status-warning {
          background-color: var(--token-warning-default);
        }

        &.status-healthy {
          background-color: var(--token-highlight-default);
        }
      }
    }

    &__datacontent {
      background: var(--token-highlight-default);
      color: var(--token-neutral-000);
      display: inline-block;
      font-weight: $text-bold-weight;
      height: size("base", 5);
      margin-bottom: $space-xs;
      margin-top: $space-xs;
      padding-top: $space-s;
      width: size("base", 6);

      .e-icon {
        fill: var(--token-neutral-000);
        margin-top: -$space-xs;
      }
    }

    .e-node-report .e-node__datacontent {
      background: color-mix(in srgb, var(--token-highlight-default) 40%, transparent);
    }
  }
}

.node {
  color: var(--token-neutral-800);
  font-family: typo("body", "family");
  font-size: map.get($typography, "body", "font-size");
  font-weight: map.get($typography, "body", "font-weight");
  white-space: normal;
  width: size("base", 15);
}
