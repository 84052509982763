e-image {
  display: inline-flex;
  vertical-align: middle;
}

.e-image {
  &__wrapper {
    display: inline-block;
    line-height: 0;
  }

  &__logo {
    display: inline-block;
    height: size("icon", "medium");
    width: size("icon", "medium");

    &-size_large {
      height: size("icon", "large");
      width: size("icon", "large");
    }

    &-size_small {
      height: size("icon", "small");
      width: size("icon", "small");
    }

    &-size_original {
      height: auto;
      width: auto;
    }
  }

  &__illustration {
    display: inline-block;
    height: 160px;
    width: 160px;

    &-size_large {
      height: 240px;
      width: 320px;
    }

    &-size_small {
      height: 128px;
      width: 128px;
    }

    &-size_original {
      height: auto;
      width: auto;
    }
  }
}
