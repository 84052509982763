e-float-container {
  height: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: z("modal");

  &.e-float-container-navigation {
    z-index: z("navigation");
  }
}
