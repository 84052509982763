$grid-fixed-sizes: (
  "small": size-unitless("base", 31),
  "medium": size-unitless("base", 41),
  "large": size-unitless("base", 61),
);

.e-fixed {
  display: flex;
  flex-direction: row;

  &__full {
    flex-basis: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    padding: $space-m;

    &-centered {
      align-items: center;
      display: flex;
    }
  }

  &__center {
    display: block;

    &-fullwidth {
      width: 100%;
    }
  }

  @each $class, $data in $grid-fixed-sizes {
    &__#{$class} {
      flex: 0 0 $data;
      padding: $space-m;

      &-bordered {
        border-left: 1px solid var(--token-border-default);
        border-right: 1px solid var(--token-border-default);
        flex: 0 0 $data + 2px;
        margin: 0 -1px;
      }
    }
  }
}
