e-criteria {
  background-color: var(--token-background-default);
  border: 1px solid var(--token-ruleBuilder-variant2-border);
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  position: relative;

  &-header {
    align-items: center;
    background-color: var(--token-ruleBuilder-variant2-background);
    border-bottom: 1px solid var(--token-ruleBuilder-variant2-border);
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    color: var(--token-ruleBuilder-variant2-text);
    display: flex;
    height: size("base", 7);
    margin-bottom: $space-m;
    order: -1;
    padding: $space-m;
  }

  &-label {
    @include typography("body");

    margin-right: $space-m;
  }

  &-addcondition {
    margin: 0 $space-m;

    &-emptystate {
      display: block;
    }

    &-footer {
      border-top: 1px solid var(--token-border-default);
      display: block;
      padding: $space-s $space-m;
    }

    &-button {
      margin: 0 (-$space-m);
    }
  }

  &-template {
    background-color: var(--token-background-default);
    border: 1px solid var(--token-border-default);
    border-bottom-width: 0;
    display: none;
    flex-direction: column;
    margin: 0 $space-m;
    padding: 0 $space-m;

    &-header {
      align-items: center;
      display: flex;
      font-weight: 500;
      height: size("base", 5);
      margin: 0 (-$space-m) $space-m;
      order: -1;
      outline: 1px solid var(--token-border-default);
      padding: $space-2xs $space-m;
    }

    &-footer {
      align-items: center;
      display: flex;
      height: size("base", 8);
      margin: $space-m (-$space-m) 0;
      order: 1;
      outline: 1px solid var(--token-border-default);
      padding: $space-m;
    }
  }

  &-condition {
    border: 1px solid var(--token-border-default);
    border-bottom-width: 0;
    cursor: pointer;
    margin: 0 $space-m;

    &:hover {
      e-criteria-condition-content {
        background-color: var(--token-background-strong);
      }
    }

    &-content {
      align-items: center;
      background-color: var(--token-background-default);
      display: flex;
      height: size("base", 5);
      justify-content: space-between;
      padding: 0 0 0 $space-m;

      &[disabled] {
        background-color: transparent;
        color: var(--token-text-faint);
        cursor: default;

        &:hover {
          background-color: transparent;
        }
      }
    }

    &-actions {
      display: flex;

      e-criteria-condition-content[disabled] & {
        display: none;
      }
    }

    &-value {
      &::before {
        color: var(--token-text-faint);
        content: "•";
        font-weight: 500;
        margin: 0 $space-xs;
      }
    }
  }
}

.e {
  &-criteria {
    margin-bottom: $space-xl;
    overflow-x: auto;
    width: 100%;

    &-noscroll {
      overflow-x: inherit;
    }

    // scss-lint:disable SelectorFormat
    .table_condition {
      border: 1px solid var(--token-border-default);
    }

    &__operator {
      width: size("base", 20);

      .e-select-small {
        width: size("base", 10);
      }
    }

    &__title,
    &__crits,
    &__actions {
      display: table-cell;
    }

    &__title {
      font-weight: 500;
    }

    &__actions {
      vertical-align: middle;
    }

    &__item {
      background-color: var(--token-neutral-200);
      padding: $space-2xs;

      &:hover {
        background-color: var(--token-neutral-300);
      }

      &-table {
        border-bottom: 1px solid var(--token-border-default);
        display: table;
        padding: $space-xl $space-2xs $space-xl $space-xl;
        width: 100%;

        &:last-child {
          border-bottom: 0;
        }
      }

      &-behavior {
        @include word-wrap;

        padding: $space-xl $space-2xs $space-xl $space-xl;
      }

      .e-select,
      .e-input {
        margin-left: $space-2xs;
      }

      .e-btn {
        margin: $space-2xs;
      }

      .buttonRemove {
        margin-left: $space-m;
      }
    }
  }
}
