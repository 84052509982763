tags-input {
  display: block;

  .host {
    height: 100%;
    margin-bottom: $space-2xs;
    margin-top: $space-2xs;
    position: relative;

    &:active {
      outline: none;
    }
  }

  .tags {
    @include e-input-old;
    @include word-wrap;

    border-radius: $border-radius;
    cursor: text;
    height: 100%;
    line-height: size("base", 5);
    overflow: hidden;
    padding: $space-2xs $space-2xs 0 $space-2xs;

    &.focused {
      border-color: var(--token-highlight-default);
      outline: none;
    }

    .tag-list {
      display: inline-block;
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    .tag-item {
      @include e-label;

      float: left;
      margin-bottom: $space-2xs;
      margin-top: 0;
      padding-bottom: 0;

      &.selected {
        background-color: var(--token-red-300);

        &:hover {
          background-color: var(
            --token-red-300
          ) !important; /* stylelint-disable-line declaration-no-important */
        }
      }

      // scss-lint:disable SelectorDepth
      .remove-button {
        @include e-label-close;

        display: inline-flex;
        font-size: 0;
        -webkit-font-smoothing: subpixel-antialiased;
        height: size("icon", "medium");
        margin-left: 0;
        margin-right: -$space-xs;
        margin-top: $space-3xs;
        text-decoration: none;

        &::after {
          font-size: var(--token-icon-default-sizeSmall);
        }

        &:active {
          color: var(--token-red-500);
        }
      }
    }

    .input {
      background-color: var(--token-background-default);
      border: 0;
      margin-bottom: $space-xs;
      outline: none;
      padding: $space-2xs $space-2xs 0 $space-2xs;

      &.invalid-tag {
        color: var(--token-red-500);
      }
    }
  }

  .autocomplete {
    background-color: var(--token-background-default);
    border: 1px solid var(--token-border-strong);
    margin-top: $space-2xs;
    padding: $space-2xs 0;
    position: absolute;
    width: 100%;
    z-index: 999;

    .suggestion-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    // scss-lint:disable SelectorDepth
    .suggestion-item {
      background-color: var(--token-background-default);
      color: var(--token-text-default);
      cursor: pointer;
      font:
        16px "Helvetica Neue",
        Helvetica,
        Arial,
        sans-serif;
      overflow: hidden;
      padding: $space-2xs $space-xs;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.selected {
        background-color: var(--token-highlight-500);
        color: var(--token-text-light);

        em {
          background-color: var(--token-highlight-500);
          color: var(--token-text-light);
        }
      }

      em {
        background-color: var(--token-background-default);
        color: var(--token-text-default);
        font:
          normal bold 16px "Helvetica Neue",
          Helvetica,
          Arial,
          sans-serif;
      }
    }
  }
}
