.e {
  &-fullheight {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $space-xs $space-m $space-m;

    &-has_header {
      padding-left: size("menu", "fullheightWidth");
      padding-top: 0;
    }

    &-has_footer {
      padding-bottom: size("steps", "height");
    }

    &-inner {
      padding: 0;

      .e-fullheight__container {
        left: 0;
        right: 0;
      }

      .e-fullheight__content {
        margin: 0;
      }
    }

    &-modal {
      height: calc(100% + #{size("base", 4)});
      margin: -$space-m;
      padding: 0;
    }

    &__wrapper {
      flex-grow: 1;
      position: relative;
    }

    &__container {
      bottom: 0;
      display: flex;
      left: -$space-2xs;
      position: absolute;
      right: -$space-2xs;
      top: 0;

      &-block {
        display: block;
        left: 0;
        right: 0;
      }

      &-ac {
        display: block;

        .e-fullheight {
          &__content {
            float: left;

            &-sidebar {
              width: size("base", 31);
            }

            &-main {
              width: calc(100% - #{size-unitless("base", 31) + size-unitless("base", 2.5)}px);
            }
          }
        }
      }
    }

    &__content {
      flex: 1 1 50%;
      height: 100%;
      margin: 0 $space-2xs;
      min-width: 0;
      position: relative;
      transition:
        flex-basis $transition-time ease-out,
        width $transition-time ease-out,
        margin-left $transition-time ease-out;

      &-no_animation {
        transition: none;
      }

      &-box {
        background-color: var(--token-box-default-background);
        border: 0;
        border-radius: $border-radius;
        box-shadow: none;
      }

      &-sidebar {
        flex-basis: size("base", 40);
        flex-grow: 0;
        flex-shrink: 0;
        width: size("base", 40);

        &.e-verticaltab,
        &.e-verticalnav {
          flex-basis: #{size-unitless("base", 40) + size-unitless("base", 9)}px;
          width: #{size-unitless("base", 40) + size-unitless("base", 9)}px;
        }
      }

      &-closed {
        flex-basis: 0;
        overflow: hidden;
        width: 0;
      }

      &-bordered {
        // email-preview
        border-right: 1px solid var(--token-box-default-border);
      }
    }

    &__body {
      height: calc(100% - #{size("base", 7)});
      overflow: auto;
      padding: $space-m;

      &-always_scroll {
        overflow-y: scroll;
      }

      &-no_scroll {
        overflow: hidden;
      }

      &-no_padding {
        padding: 0;
      }

      &-no_header {
        height: 100%;
      }
    }

    &__header {
      align-items: center;
      border-bottom: 1px solid var(--token-box-default-border);
      display: flex;
      height: size("base", 7);
      padding: 0 $space-m;

      h2 {
        line-height: $text-lineheight-condensed;
        margin: 0;
      }
    }

    &__actions {
      flex-basis: auto;
      flex-shrink: 0;
      margin-left: auto;
    }

    &__toggle {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      position: relative;
      right: -$space-m;
    }
  }
}
