$dropdown_style: (
  danger: var(--token-error-default),
  error: var(--token-error-default),
  success: var(--token-success-default),
);
$dropdown-box-arrow-size: 12px;
$dropdown-hover-transition: all 0.2s ease-in-out;

e-dropdown {
  display: inline-block;
  position: relative;
  user-select: none;
}

.e-dropdown {
  &-popper {
    margin-top: $space-s;
    top: -99999px;
    z-index: z("flying");

    .e-dropdown__content {
      background-color: var(--token-box-strong-background);
      box-shadow: none;
      margin-top: 0;
      position: static;

      .popper__arrow {
        display: none;
      }

      &::after {
        display: none;
      }
    }

    &[x-placement^="top-end"] {
      margin-bottom: 11px;

      .popper__arrow {
        bottom: 0;
        top: auto;
        transform: rotate(45deg);
      }
    }
  }

  &-popper_sticky {
    margin-top: -1px;

    .e-dropdown__content {
      border: 1px solid var(--token-box-strong-border);
      box-shadow: var(--token-shadow-light);
    }

    .popper__arrow {
      display: none;
    }

    &[x-placement^="top-end"] {
      margin-bottom: -1px;

      .e-dropdown__content {
        box-shadow: var(--token-shadow-light);
      }
    }
  }

  &__button {
    border: 1px solid transparent;
    border-radius: $border-radius;
    cursor: pointer;
    display: inline-block;

    .e-icon-table {
      margin-top: -$space-3xs;
    }

    &-open {
      @extend .e-btn-active;
    }
  }

  &__content {
    border-radius: $border-radius;
  }

  &__content,
  &__inner {
    background-color: var(--token-box-strong-background);
    border: 1px solid var(--token-box-strong-border);
    box-shadow: var(--token-shadow-medium);
    display: none;
    margin-top: $space-s;
    padding: $space-xs;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: z("dropdown");

    &::after {
      background-color: var(--token-box-strong-background);
      border-left: 1px solid var(--token-box-strong-border);
      border-top: 1px solid var(--token-box-strong-border);
      content: "";
      display: inline-block;
      height: size("base", 1.5);
      margin: -6px 0;
      position: absolute;
      right: 8px;
      top: 0;
      transform: rotate(45deg);
      width: size("base", 1.5);
    }

    &-visible {
      display: block;
    }

    &-sticky {
      border: 1px solid var(--token-box-strong-border);
      box-shadow: var(--token-shadow-light);
      left: auto;
      margin-top: -1px;
      min-width: 100%;
      right: 0;

      &::after {
        display: none;
      }

      .e-dropdown__item {
        line-height: $text-lineheight;
      }

      &.e-dropdown__content-alignright {
        left: 0;
        margin-left: 0;
        right: auto;
      }
    }

    .e-dropdown__item {
      line-height: size("base", 3);
    }

    &.e-popover {
      border: 0;
      padding: 0;
    }

    &.e-dropdown__content-alignright {
      left: 0;
      margin-left: 0;
      right: auto;
    }

    &-alignright {
      left: 100%;
      margin-left: -($dropdown-box-arrow-size + $space-m);
      right: auto;

      &::after {
        left: $space-xs;
        right: auto;
      }
    }

    &-aligntop {
      bottom: 100%;
      margin-bottom: $space-m;
      top: auto;

      &::after {
        bottom: 0;
        top: auto;
        transform: rotate(225deg);
      }
    }

    &-fullwidth {
      min-width: 100%;
    }

    @each $class, $color in $dropdown_style {
      &-#{$class},
      e-dropdown > &-#{$class} {
        background-color: $color;
        color: var(--token-text-light);

        &::after {
          background-color: $color;
        }
      }
    }

    .e-separator {
      margin-bottom: 0;
    }
  }

  &__item {
    @include typography("body");
    @include has-focus-state;

    background: none;
    border: 0;
    border-radius: $border-radius;
    color: var(--token-action-default-text);
    cursor: pointer;
    display: block;
    fill: var(--token-action-default-text);
    padding: $space-xs $space-s;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: none;
    white-space: nowrap;
    width: 100%;
    z-index: z("dropdown") + 1;

    &:focus {
      color: var(--token-action-default-text);
    }

    &:focus-within {
      @include can-have-focus-style {
        @include has-focus-style;
      }
    }

    svg {
      fill: var(--token-action-default-text);
    }

    e-icon svg {
      margin: 0;
      margin-right: $space-xs;
    }

    a {
      color: var(--token-action-default-text);
      display: block;
      text-decoration: none;
      transition: none;

      &:hover {
        color: var(--token-action-default-text);

        svg {
          fill: var(--token-action-default-text);
        }
      }
    }

    &:visited {
      color: var(--token-action-default-text);
    }

    &:hover {
      background-color: var(--token-action-default-backgroundHover);
      color: var(--token-action-default-text);
    }

    &-disabled {
      color: var(--token-action-default-text);
      cursor: not-allowed;
      opacity: 0.5;

      svg {
        fill: var(--token-action-default-text);
      }

      &:active,
      &:visited {
        color: var(--token-action-default-text);
      }

      &:hover {
        background-color: var(--token-action-default-background);
        color: var(--token-action-default-text);

        svg {
          fill: var(--token-action-default-text);
        }
      }
    }

    &-righticon {
      cursor: default;
      padding: $space-xs $space-s;

      a {
        margin-right: $space-xl;
      }

      .float-right e-icon svg {
        margin: 0;
        margin-left: $space-xs;
      }
    }

    &-fixwidth {
      @include text-overflow;

      max-width: size("base", 75);

      a {
        @include text-overflow;
      }
    }
  }

  &__container {
    padding: 0;
    text-align: left;
    white-space: normal;
    width: size("base", 50);

    &-item {
      @include typography("body");

      max-width: size("base", 50);
      padding: $space-2xs $space-l $space-2xs $space-s;
      width: auto;
      z-index: z("dropdown") + 1;
    }
  }
}

.e-buttongroup:not(.e-buttongroup-sticky) {
  .e-dropdown__inner,
  .e-dropdown__content {
    right: $space-xs;

    &-sticky {
      right: auto;
    }
  }
}
