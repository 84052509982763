.e {
  &-buttonradio,
  &-buttoncheckbox {
    $default-button-style: map.get($btn-styles, "default");
    $borderless-button-style: map.get($btn-styles, "borderless");

    &:is(input) {
      height: 0;
      opacity: 0;
      pointer-events: none;
      width: 0;
    }

    &:focus {
      + .e-btn {
        @include e-btn-state($default-button-style, "focus");

        &-borderless {
          @include e-btn-state($borderless-button-style, "focus");
        }
      }
    }

    &:checked {
      + .e-btn {
        @include e-btn-state($default-button-style, "active");

        &-borderless {
          @include e-btn-state($borderless-button-style, "active");
        }
      }
    }
  }
}

label.e-buttonradio,
label.e-buttoncheckbox {
  display: inline-block;

  input {
    height: 0;
    opacity: 0;
    pointer-events: none;
    width: 0;
  }
}
