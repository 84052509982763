@import "../box/styles";

$notice-box-line-width: 4px !default;
$notification_style: map.merge(
  $box-styles,
  (
    loading: (
      background: var(--token-box-highlight-background),
      border: var(--token-box-highlight-border),
      color: var(--token-box-highlight-text),
    ),
  )
);

e-notification {
  @include typography("body");

  background: var(--token-box-default-background);
  border: 1px solid var(--token-box-default-border);
  border-radius: $border-radius;
  color: var(--token-box-default-text);
  display: none;
  margin-bottom: 0;
  padding: $space-m $space-m $space-m $space-4xl;
  position: relative;
  text-align: left;

  @each $type, $data in $notification_style {
    &[type="#{$type}"] {
      background-color: map.get($data, "background");
      border-color: map.get($data, "border");

      .e-notification__icon {
        color: map.get($data, "icon");
      }
    }
  }

  &[closable]:not([closable="false"]) {
    padding-right: $space-3xl;
  }

  &::before {
    background-color: transparent;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    width: $notice-box-line-width;
  }

  &.e-notification-visible {
    display: block;
  }

  &.e-notification-borderless {
    border-width: 0;

    &::before {
      display: none;
    }
  }

  .e-notification__wrapper {
    display: contents;
  }

  .e-notification__icon {
    font-size: 0;
    left: $space-m;
    line-height: 0;
    position: absolute;
    top: $space-m;

    .e-spinner {
      margin-top: 0;
    }

    svg {
      height: size("base", 3);
      width: size("base", 3);
    }

    @each $type, $data in $notification_style {
      &-#{$type} {
        color: map.get($data, "color");
      }
    }
  }

  .e-notification__close {
    cursor: pointer;
    position: absolute;
    right: $space-xs;
    top: $space-xs;
  }

  .e-notification__progress {
    background: linear-gradient(
      to bottom,
      transparent,
      transparent 50%,
      var(--token-progressBar-notification-background) 50%
    );
    border-bottom-left-radius: $border-radius - 1;
    border-bottom-right-radius: $border-radius - 1;
    bottom: 0;
    height: size("base", 1);
    left: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;

    &::before {
      animation-duration: 5s;
      animation-name: e-notification-progressbar;
      animation-timing-function: linear;
      background: var(--token-progressBar-notification-bar);
      border-bottom-right-radius: $border-radius;
      border-top-right-radius: $border-radius;
      bottom: 0;
      content: "";
      height: size("base", 0.5);
      left: 0;
      position: absolute;
    }
  }
}

e-notification-title {
  @include typography("heading3");

  display: block;
  margin-bottom: $space-xs;
}

e-notification-subtitle {
  @include typography("small");

  color: var(--token-text-faint);
  display: block;
  margin-bottom: $space-xs;
  margin-top: -$space-xs;
}

e-notification-footer {
  display: block;
  padding-top: $space-xs;
}

e-notification-content {
  color: var(--token-box-default-text);
  display: block;
}

e-notification-overlay {
  align-items: center;
  background: color-mix(in srgb, var(--token-overlay-light) 80%, transparent);
  bottom: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: $space-m $space-m 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: z("modal-backdrop");

  &.e-notification-overlay-visible {
    display: flex;
  }
}

.e-fixed_notification_container {
  display: inline-flex;
  flex-direction: column;
  position: fixed;
  z-index: z("fixed-element");

  &-top {
    align-items: center;
    right: 50%;
    top: size-unitless("shellbar", "horizonHeight") + $space-s;
    transform: translateX(50%);

    e-notification {
      margin: 0 0 $space-m;
      width: size("base", 75);
    }
  }

  &-bottom_right {
    align-items: flex-end;
    bottom: $space-xl;
    right: $space-xl;

    e-notification {
      margin: $space-m 0 0 0;
      width: size("base", 50);
    }
  }

  e-notification {
    box-shadow: var(--token-shadow-strong);
  }
}

@keyframes e-notification-progressbar {
  from {
    width: 100%;
  }

  to {
    width: 0%;
  }
}
