@mixin e-buttongroup($btn-selector: ".e-btn") {
  display: flex;
  margin-left: -$space-2xs;
  margin-right: -$space-2xs;

  #{$btn-selector} {
    margin-left: $space-2xs;
    margin-right: $space-2xs;
  }
}

@mixin e-buttongroup-sticky {
  border-radius: 8px;
  margin-left: 0;
  margin-right: 0;
  width: fit-content;

  &:not(:has(.e-btn-borderless)) {
    background-color: var(--token-background-faint);
  }

  .e-btn {
    margin-left: 0;
    margin-right: 0;

    &:hover,
    &:focus {
      z-index: 1;
    }
  }
}

.e {
  &-buttongroup {
    @include e-buttongroup;

    &-sticky {
      @include e-buttongroup-sticky;
    }

    &-flex {
      align-items: baseline;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &::before,
      &::after {
        display: none;
      }

      > * {
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 0;
        margin-bottom: $space-xs;
      }
    }
  }
}
