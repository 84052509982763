@import "../input/assets";
@import "../select/select";

.e-campaignselector {
  &__button {
    @include e-select;
  }

  &__item {
    padding: 0 $space-xs;
  }

  &__list {
    border: 1px solid var(--token-border-default);
    height: size("base", 26);
    overflow-x: hidden;
    overflow-y: scroll;
  }

  &__popup {
    @extend .e-popup-panel;

    line-height: 38px;
    margin-top: $space-s;
    width: size("base", 89);
    z-index: 1;

    &::after {
      background-color: var(--token-box-strong-background);
      border-left: 1px solid var(--token-box-strong-border);
      border-top: 1px solid var(--token-box-strong-border);
      content: "";
      height: size("base", 1);
      left: $space-l;
      position: absolute;
      top: -#{size-unitless("base", 0.5) + 1}px;
      transform: rotate(45deg);
      width: size("base", 1);
    }
  }
}
