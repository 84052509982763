e-emoji {
  display: block;
  position: relative;

  .e-input {
    color: transparent;

    &:disabled {
      background-image: none;
      color: transparent;

      ~ .e-emoji {
        color: var(--token-text-faint);
        cursor: not-allowed;
      }
    }
  }

  .e-emoji {
    background: transparent;
    background-clip: padding-box;
    left: 0;
    position: absolute;
    top: 0;
  }

  .e-input:last-child {
    color: var(--token-text-default);
    z-index: 1;
  }

  .e-input-error ~ .e-emoji {
    color: var(--token-text-error);

    &:focus {
      border-color: transparent;
    }
  }
}

.e {
  &-emojipicker {
    display: block;
    padding-right: calc(#{size("base", 5)} + #{$space-xs});
    position: relative;

    .e-emoji {
      font-family: "Segoe UI Emoji", typo("body", "family");

      &::-ms-clear {
        display: none;
      }
    }

    &__picker {
      position: absolute;
      right: 0;
      top: 0;
    }

    &__close {
      display: none;
    }

    &__emoji {
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      display: inline-flex;
      height: size("base", 4);
      justify-content: center;
      line-height: 1;
      margin: $space-3xs;
      padding: 18px;
      width: size("base", 4);

      &::after {
        content: attr(data-emoji);
      }

      &:hover {
        background-color: var(--token-neutral-300);
      }

      &-active {
        @include has-secondary-focus-style;
      }
    }

    &__container {
      background-color: var(--token-box-strong-background);
      flex-direction: column;
      font-family: "Segoe UI Emoji", typo("body", "family");
      font-size: 20px;
      height: size("base", 30);
      margin-bottom: 0;
      max-width: 378px;
      padding: 0;
      top: -99999px;
      user-select: none;
      width: 378px;
      z-index: z("flying");

      &[x-placement*="top"] {
        border-bottom: 0;
      }

      &[x-placement*="bottom"] {
        border-top: 0;
      }
    }

    &__content {
      display: flex;
      flex-wrap: wrap;
      height: calc(#{size("base", 30)} - #{size("base", 5)});
      overflow: auto;
      padding: $space-xs;
    }

    &__emoji_button {
      font-size: 20px;

      &-active {
        @include has-secondary-focus-style;
      }
    }

    &__tabs {
      display: flex;
      height: auto;
      padding: $space-xs $space-xs 0 $space-xs;
      position: relative;

      &::after {
        border-bottom: 1px solid var(--token-border-strong);
        bottom: 0;
        content: "";
        height: 2px;
        margin-left: -$space-xs;
        position: absolute;
        width: 100%;
      }
    }

    &__tab {
      @include has-focus-state($color: var(--token-highlight-default));

      align-items: center;
      background: transparent;
      border-radius: $border-radius;
      cursor: pointer;
      display: flex;
      flex: 1 0 auto;
      height: 40px;
      justify-content: center;
      line-height: 1;
      margin-bottom: $space-xs;
      opacity: 1;
      text-align: center;
      width: 40px;

      &:hover {
        background-color: var(--token-background-strong);
        opacity: 1;
      }

      &-active {
        background-color: var(--token-box-strong-background);
        opacity: 1;
        position: relative;

        &::after {
          background-color: var(--token-highlight-default);
          border-radius: $border-radius $border-radius 0 0;
          bottom: -$space-xs;
          content: "";
          height: 3px;
          left: $space-xs;
          position: absolute;
          right: $space-xs;
          width: auto;
          z-index: 1;
        }
      }
    }
  }
}
