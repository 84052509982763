@import "assets";
@import "styles";

:where(:not(.e-input-search)) {
  &.e-input {
    @include e-input;
    @include e-input-style($styles: $input-styles);

    @each $style-name, $data in $input-styles {
      &-#{$style-name} {
        @include e-input-style($style-name, $input-styles);
      }
    }
  }
}

:where(.e-input-search) {
  &.e-input {
    @include e-input-search($input-styles);
  }
}

.e-input {
  @each $class, $data in map-get($map: $sizes, $key: "input") {
    &-#{$class} {
      @include e-input-size($class);
    }
  }
}
