.e {
  &-wysiwyg {
    .htmlarea {
      border: 1px solid var(--token-border-default);

      .toolbar {
        background-color: var(--token-background-strong);
        border: 0;
        padding: $space-3xs 0;

        select {
          margin-left: $space-2xs;
        }

        .button {
          background: transparent;
          border: 1px solid var(--token-border-default);
          cursor: pointer;
          display: inline-block;
          height: size("base", 3);
          line-height: size-unitless("base", 3) - 2px;
          margin-left: $space-2xs;
          padding: 0;
          text-align: center;
          vertical-align: middle;
          width: size("base", 3);

          &::after {
            display: none;
          }

          &:hover {
            background-color: var(--token-neutral-300);
          }

          img {
            filter: grayscale(100%);
            vertical-align: inherit;
          }

          &.buttonDisabled {
            cursor: default;
          }
        }
      }

      > iframe {
        border: 0;
        border-top: 1px solid var(--token-border-default);
        height: size("base", 50) !important; // stylelint-disable-line declaration-no-important
        width: 100% !important; // stylelint-disable-line declaration-no-important
      }
    }

    > textarea {
      color: var(--token-neutral-800) !important; // stylelint-disable-line declaration-no-important
      font-family: Consolas, Menlo, Monaco, monospace !important; // stylelint-disable-line declaration-no-important
      font-size: 16px !important; // stylelint-disable-line declaration-no-important
      height: size("base", 50) !important; // stylelint-disable-line declaration-no-important
      width: 100% !important; // stylelint-disable-line declaration-no-important
    }

    &-textmode {
      .htmlarea {
        border: 0;

        .toolbar {
          display: none;
        }
      }

      > textarea {
        height: size-unitless("base", 50) + size-unitless("base", 3) + (2 * $space-3xs) !important; // stylelint-disable-line declaration-no-important
      }
    }
  }
}
