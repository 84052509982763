e-iframe-loader {
  display: inline-block;
  position: relative;

  iframe {
    position: relative;
    vertical-align: top;
  }

  e-iframe-fit & {
    height: 100%;
    width: 100%;
  }
}
