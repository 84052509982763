.e-numberinput {
  position: relative;

  &__input {
    padding-right: $space-2xl;

    &-small {
      @include e-input-size("small");
    }

    &-medium {
      @include e-input-size("medium");
    }

    &-large {
      @include e-input-size("large");
    }

    &:where(e-numberinput:has(.e-numberinput__arrow:hover) .e-numberinput__input) {
      @include e-input-state($style: map.get($input-styles, "default"), $state: "hover");
    }

    &:where(e-numberinput[invalid]:has(.e-numberinput__arrow:hover) .e-numberinput__input) {
      @include e-input-state($style: map.get($input-styles, "error"), $state: "hover");
    }
  }

  &__arrow {
    align-items: center;
    border-radius: $border-radius-small;
    cursor: pointer;
    display: flex;
    height: calc(var(--token-input-default-height) / 2 - 2px);
    justify-content: center;
    line-height: size("base", 2);
    position: absolute;
    right: 2px;
    user-select: none;
    width: 37px;

    &-up {
      top: 2px;
    }

    &-down {
      bottom: 2px;
    }

    .e-icon,
    &:hover .e-icon {
      color: var(--token-text-default);
    }

    &:where(e-numberinput[invalid] *) {
      .e-icon {
        color: var(--token-text-error);
      }

      &:hover {
        background-color: var(--token-error-200);

        .e-icon {
          color: var(--token-text-error);
        }
      }
    }
  }
}
