@mixin e-grid($padClass: false, $gutter: 0) {
  &-#{$padClass} {
    margin: -$gutter;
  }
}

@mixin e-cell($padClass: false, $gutter: 0) {
  &-#{$padClass} {
    padding: $gutter;
  }
}

.e {
  &-grid {
    display: flex;

    @include e-grid("xsmall", $space-2xs);
    @include e-grid("small", $space-xs);
    @include e-grid("medium", $space-s);
    @include e-grid("large", $space-m);

    &-around,
    &-justify_around {
      justify-content: space-around;
    }

    &-between,
    &-justify_between {
      justify-content: space-between;
    }

    &-center,
    &-justify_center {
      justify-content: center;
    }

    &-justify_end {
      justify-content: end;
    }

    &-align_start {
      align-items: flex-start;
    }

    &-vertical_center,
    &-align_center {
      align-items: center;
    }

    &-align_end {
      align-items: flex-end;
    }

    &-baseline,
    &-align_baseline {
      align-items: baseline;
    }

    &-wrap {
      flex-wrap: wrap;
    }

    &-column {
      flex-direction: column;
    }

    &-fullheight {
      height: 100%;
    }
  }

  &-cell {
    flex: 0 0 auto;

    @for $i from 1 through 12 {
      &-#{$i} {
        width: #{math.percentage(math.div($i, 12))};
      }
    }

    @include e-cell("xsmall", $space-2xs);
    @include e-cell("small", $space-xs);
    @include e-cell("medium", $space-s);
    @include e-cell("large", $space-m);

    &-border {
      &_left {
        border-left: 1px solid var(--token-border-default);
      }

      &_right {
        border-right: 1px solid var(--token-border-default);
      }

      &_top {
        border-top: 1px solid var(--token-border-default);
      }

      &_bottom {
        border-bottom: 1px solid var(--token-border-default);
      }
    }

    &-auto {
      flex: 1 1 0%;
      min-width: 0;
    }

    &-grow {
      flex-grow: 1;
    }

    &-sticky {
      margin-left: -1px;
    }
  }
}
