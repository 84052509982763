.e {
  &-filedrop {
    @include center-content;

    background-color: var(--token-background-strong);
    border: 3px solid var(--token-border-default);
    border-radius: $border-radius;
    display: inline-block;
    height: 315px;
    position: relative;
    width: 570px;

    &-fullwidth {
      width: 100%;
    }

    &__content {
      padding: $space-m;
      text-align: center;
    }

    &-overlay {
      background-color: color-mix(in srgb, var(--token-background-strong) 85%, transparent);
      bottom: $space-m;
      display: none;
      height: auto;
      left: $space-m;
      position: fixed;
      right: $space-m;
      top: $space-m;
      width: auto;
      z-index: z("modal");

      &.e-filedrop-over {
        background-color: color-mix(in srgb, var(--token-background-strong) 85%, transparent);
        display: block;
      }
    }
  }
}
