$space-icon: $space-m + $space-s;

e-validator {
  border-bottom: 1px solid var(--token-border-default);
  display: block;
  fill: var(--token-text-faint);
  line-height: $text-lineheight-condensed;

  &[standalone]:not([standalone="false"]) {
    border: 1px solid var(--token-border-default);
    border-radius: $border-radius;
    overflow: hidden;

    e-validator-header::before {
      border-top: 1px solid var(--token-border-default);
      bottom: -1px;
      content: "";
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  &-header {
    display: block;
    position: relative;
    transition: background $transition-time;
  }

  &-content {
    box-sizing: content-box;
    display: block;
    height: 0;
    line-height: $text-lineheight;
    overflow: hidden;
    padding: 0 $space-m 0 #{$space-s + 2 * $space-icon};
    transition:
      height $transition-time,
      padding $transition-time;

    &[opened] {
      padding-bottom: $space-xs;
      padding-top: $space-xs;
    }
  }
}

.e-validator {
  &__header {
    @include has-focus-state;
    @include typography("body");

    background: none;
    border: 0;
    display: flex;
    justify-content: space-between;
    padding: $space-xs $space-s;
    position: relative;
    width: 100%;

    h2 {
      margin-bottom: 0;
    }
  }

  &__title {
    flex-grow: 1;
    text-align: left;
  }

  &__mark {
    background-color: transparent;
    font-weight: 500;

    &-default {
      color: var(--token-text-faint);
    }

    &-success {
      color: var(--token-success-default);
    }

    &-warning {
      color: var(--token-warning-default);
    }

    &-error {
      color: var(--token-error-default);
    }

    &-info {
      color: var(--token-info-default);
    }
  }

  &__subject {
    color: var(--token-text-faint);
    margin-left: $space-2xs;
  }

  &__summary {
    display: flex;
  }

  &__summary_item {
    @include typography("small");

    align-items: center;
    display: inline-flex;
    flex: 1 1 auto;
    margin-left: $space-s;

    e-icon {
      margin-left: $space-2xs;
    }
  }

  &__icon {
    display: inline-block;
    flex-shrink: 0;
    margin-right: $space-s;
    position: relative;
    width: size("base", 2);

    e-spinner {
      left: 0;
      position: absolute;
      top: -1px;
    }
  }

  &__action {
    @include typography("body");

    flex-shrink: 0;
    font-size: 14px;
    margin-left: $space-s;

    &-icon {
      margin-left: 0;
      margin-right: $space-s;
    }

    e-icon {
      transform-origin: center;
      transition: transform $transition-time;
    }

    .e-icon {
      font-size: var(--token-icon-default-sizeSmall);
      height: var(--token-icon-default-sizeSmall);
      width: var(--token-icon-default-sizeSmall);
    }
  }

  &__subheader:not(:empty) {
    @include text-overflow;

    color: var(--token-neutral-500);
    padding: 0 $space-m $space-xs #{$space-s + $space-icon};

    &:where(e-validator-header:has(~ e-validator-content) .e-validator__subheader) {
      padding: 0 $space-m $space-xs #{$space-s + 2 * $space-icon};
    }
  }

  &__toggle {
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: var(--token-background-strong);
    }

    &-opened {
      .e-validator__action e-icon {
        transform: rotate(180deg);
      }
    }
  }
}
