.e {
  &-device-wallet {
    height: 100%;
    position: relative;

    e-device[type="ios"] & {
      background-color: transparent;
      background-image: url(#{$e-device-sms-assets}/e-device-wallett-ios-status_bar_with_more_icon.svg);
      background-repeat: no-repeat;

      &.back-of-ios {
        background-image: url(#{$e-device-push-assets}/e-device-wallett-ios-no_status_bar.svg);
      }
    }

    &__content {
      border: 1px solid var(--token-border-default);
      border-radius: $border-radius;
      box-shadow: var(--token-shadow-medium);
      display: flex;
      flex-direction: column;
      margin: 16px;
      position: absolute;
      top: 80px;
      width: 92%;

      e-device[type="android"] & {
        top: 10px;
      }
    }

    &__logo {
      align-items: center;
      display: flex;
      gap: $space-s;
      height: 125px;
      justify-content: space-between;
      padding: $space-s;

      &-image {
        height: 36px;
        width: 36px;

        img.error {
          outline: 3px dashed var(--token-error-default);
        }
      }

      &-label.error {
        .CodeMirror,
        .CodeMirror:hover,
        .highlight-editable-content & .CodeMirror {
          outline: 3px dashed var(--token-error-default);
        }
      }

      &-label {
        .logo-error-message {
          position: absolute;
        }
      }

      &-label,
      &-label > input {
        font-size: 12px;
        width: 100%;
      }
    }

    &__description {
      color: var(--token-neutral-500);
      font-size: 16px;
      margin: $space-xl $space-m $space-xs $space-m;
      text-align: left;
    }

    &__banner {
      text-align: center;

      &-image {
        max-height: 100px;
      }
    }

    &__fields {
      display: block;
      padding: $space-s;

      &-container {
        display: inline-block;
        width: 49%;

        &-right {
          float: right;
          text-align: right;
        }
      }

      &:first-child {
        margin-right: 3px;
      }

      &-divider {
        height: 8px;
      }

      &-label {
        font-size: 12px;
      }

      &-value {
        font-size: 16px;
      }
    }

    &__code-type {
      margin-top: $space-l;
      padding: $space-s;
      text-align: center;

      &-image {
        height: 100px;
        width: 100px;
      }
    }

    .html-editor {
      &.disabled {
        cursor: not-allowed;
        opacity: 0.4;

        * {
          pointer-events: none;
        }
      }

      .CodeMirror {
        background-color: transparent;

        .CodeMirror-lines {
          padding: 0;
        }

        .CodeMirror-linebackground {
          background-color: transparent;
        }
      }

      &.error {
        outline: 2px dashed var(--token-error-default);
      }
    }

    img:not(.e-device-wallet__code-type-image):not(.disabled):not(.error):hover,
    .highlight-editable-content & img:not(.e-device-wallet__code-type-image):not(.error) {
      outline: 2px dashed rgb(51 51 51 / 80%);
      outline-offset: 0;
    }

    .html-editor:not(.error):not(.disabled):hover,
    .highlight-editable-content & .html-editor:not(.error) {
      outline: 2px dashed rgb(51 51 51 / 80%);
      outline-offset: 0;
    }

    .error {
      color: var(--token-error-default);
    }

    &__ios-back {
      max-height: 100%;
      -ms-overflow-style: none;
      overflow-y: auto;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      &__description {
        color: var(--token-neutral-500);
        font-size: 14px;
        font-weight: 700;
        margin-bottom: $space-2xl;
        margin-top: 80px;
        text-align: center;
      }

      &__settings {
        &-container {
          background-color: var(--token-background-light);
          border: 1px solid var(--token-border-default);
          border-radius: $border-radius;
          box-shadow: var(--token-shadow-medium);
          margin: $space-s;
        }

        &-item {
          align-items: center;
          display: flex;
          justify-content: space-between;
          padding: $space-xs;

          .e-field {
            margin: 0;
          }

          &:first-of-type {
            border-bottom: 1px solid var(--token-neutral-100);
          }
        }

        &-title {
          color: var(--token-text-dark);
        }
      }

      &__remove {
        &-container {
          background-color: var(--token-background-light);
          border: 1px solid var(--token-border-default);
          border-radius: $border-radius;
          box-shadow: var(--token-shadow-medium);
          margin: $space-xl $space-s;
          padding: $space-xs;
        }

        &-title {
          color: var(--token-error-default);
        }
      }

      &__information {
        &-container {
          background-color: var(--token-background-light);
          border: 1px solid var(--token-border-default);
          border-radius: $border-radius;
          box-shadow: var(--token-shadow-medium);
          color: var(--token-neutral-500);
          margin: $space-xl $space-s;
          padding: $space-xs;
        }

        &-item:not(:first-of-type) {
          margin-top: $space-m;
        }

        &-label {
          font-size: 12px;
        }

        &-value {
          font-size: 16px;
          margin-top: 6px;
        }
      }
    }

    &__android-back {
      &-overlay {
        background: rgb(0 0 0 / 50%);
        height: 100%;
        position: fixed;
        width: 100%;
        z-index: 10000;
      }

      &-container {
        background: var(--token-background-light);
        border: 1px solid var(--token-border-default);
        border-radius: $border-radius $border-radius 0 0;
        bottom: 0;
        box-shadow: var(--token-shadow-medium);
        color: var(--token-neutral-500);
        padding: $space-l;
        position: absolute;
        width: 100%;
      }

      &-header {
        font-size: 12px;
        margin-bottom: $space-m;
        text-transform: uppercase;
      }

      &__information {
        &-item:not(:first-of-type) {
          margin-top: $space-m;
        }

        &-label {
          font-size: 12px;
        }

        &-value {
          font-size: 16px;
          margin-top: 6px;
        }
      }
    }
  }
}
