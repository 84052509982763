$tooltip-old-transition: all $transition-time ease-in-out !default;
$tooltip-old-transition-long: all $transition-time 1s ease-in-out !default;

@mixin tooltip-old-container() {
  background-color: var(--token-box-strong-background);
  bottom: 135%;
  color: var(--token-box-strong-text);
  opacity: 0;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  transition: $tooltip-old-transition;
  visibility: hidden;
  z-index: z("tooltip");
}

@mixin tooltip-old-tip() {
  box-shadow: 1px 1px 0 0 var(--token-box-strong-border);
  content: "";
  height: size("base", 1);
  margin: -3px;
  position: absolute;
  transform: rotate(45deg);
  width: size("base", 1);
}

@mixin tooltip-old-content() {
  @include typography("small");

  border: 1px solid var(--token-box-strong-border);
  border-radius: 0;
  box-shadow: var(--token-shadow-medium);
  content: attr(data-e-tooltip);
  padding: $space-xs $space-m;
  text-align: left;
  white-space: nowrap;
}

.e {
  &-tooltip {
    display: inline-block;
    position: relative;

    &::after,
    &::before {
      @include tooltip-old-container;
    }

    &::after {
      @include tooltip-old-tip;
    }

    &::before {
      @include tooltip-old-content;
    }

    &:hover,
    &-hover {
      &::after,
      &::before {
        bottom: 130%;
        opacity: 1;
        visibility: visible;
      }
    }

    &-cell {
      display: table-cell;

      &::before,
      &::after {
        bottom: 105%;
      }

      &:hover {
        &::before,
        &::after {
          bottom: 100%;
        }
      }
    }

    &-top-left {
      &::before {
        left: 50%;
        right: auto;
        transform: translateX(-15px);
      }
    }

    &-top-right {
      &::before {
        left: auto;
        right: 50%;
        transform: translateX(15px);
      }
    }

    &-bottom-left,
    &-bottom-right {
      &::after,
      &::before {
        bottom: auto;
        top: 140%;
      }

      &::after {
        transform: rotate(-135deg);
      }

      &:hover {
        &::before {
          bottom: auto;
        }
      }
    }

    &-bottom-left {
      &::before {
        left: 50%;
        right: auto;
        transform: translateX(-15px);
      }

      &:hover {
        &::before {
          bottom: auto;
        }
      }
    }

    &-bottom-right {
      &::before {
        left: auto;
        right: 50%;
        transform: translateX(15px);
      }
    }

    &-right {
      &::after,
      &::before {
        bottom: 50%;
        left: 110%;
        right: auto;
      }

      &::after {
        transform: rotate(135deg);
      }

      &::before {
        transform: translateY(50%);
      }

      &:hover {
        &::after,
        &::before {
          bottom: 50%;
          left: 105%;
        }
      }

      .e-tooltip__content {
        bottom: 50%;
        left: 110%;
        transform: translateY(50%);
      }
    }

    &-left {
      &::after,
      &::before {
        bottom: 50%;
        left: auto;
        right: 110%;
      }

      &::after {
        transform: rotate(-45deg);
      }

      &::before {
        transform: translateY(50%);
      }

      &:hover {
        &::after,
        &::before {
          bottom: 50%;
          left: auto;
          right: 105%;
        }
      }

      .e-tooltip__content {
        bottom: 50%;
        left: auto;
        right: 110%;
        transform: translateY(50%);
      }
    }

    &-bottom {
      &::after,
      &::before {
        bottom: auto;
        top: 140%;
      }

      &::after {
        transform: rotate(-135deg);
      }

      &:hover {
        &::after,
        &::before {
          bottom: auto;
          top: 135%;
        }
      }

      .e-tooltip__content {
        bottom: auto;
        top: 135%;
      }

      &.e-tooltip-sticky {
        &::after,
        &::before {
          top: 100%;
        }

        .e-tooltip__content {
          top: 100%;
        }
      }
    }

    &-bottomright {
      &::after,
      &::before {
        bottom: auto;
        top: 140%;
      }

      &::after {
        transform: rotate(-135deg);
      }

      &:hover {
        &::after,
        &::before {
          bottom: auto;
          top: 135%;
        }
      }

      .e-tooltip__content {
        bottom: auto;
        left: auto;
        right: 50%;
        top: 135%;
        transform: translateX(15px);

        &-large {
          left: -$space-m;
        }
      }

      &.e-tooltip-sticky {
        &::after,
        &::before {
          top: 100%;
        }

        .e-tooltip__content {
          top: 100%;
        }
      }
    }

    &-longtext {
      &::before {
        white-space: normal;
        width: size("base", 25);
      }
    }

    &-breakword {
      &::before {
        word-break: break-all;
      }
    }

    &-longdelay {
      &::before,
      &::after,
      & .e-tooltip__content {
        transition: $tooltip-old-transition-long;
      }

      &:hover {
        &::before,
        &::after,
        & .e-tooltip__content {
          transition: $tooltip-old-transition;
        }
      }
    }

    &-html {
      &:hover {
        &::before {
          opacity: 0;
          visibility: hidden;
        }

        &::after {
          bottom: 135%;
          opacity: 1;
          visibility: visible;
        }

        .e-tooltip {
          &:hover {
            &::before,
            &::after {
              opacity: 0;
              visibility: hidden;
            }
          }

          &__content {
            opacity: 1;
            visibility: visible;

            &::after {
              display: none;
            }
          }
        }
      }

      &.e-tooltip-left {
        &:hover {
          &::after {
            bottom: 50%;
            right: 110%;
          }
        }
      }

      &.e-tooltip-right {
        &:hover {
          &::after {
            bottom: 50%;
            left: 110%;
          }
        }
      }

      &.e-tooltip-bottomright {
        &:hover {
          &::after,
          &::before {
            bottom: auto;
            top: 135%;
          }
        }
      }
    }

    &__content {
      @include tooltip-old-container;
      @include tooltip-old-content;

      border-radius: $border-radius;
      white-space: normal;
      width: size("base", 25);

      &-medium {
        width: size("base", 50);
      }

      &-large {
        width: size("base", 75);
      }

      &::after {
        @include tooltip-old-tip;
      }

      .e-charttooltip {
        margin: -5.863px -11px;
      }
    }
  }
}
