.e-accordion-item {
  display: none;
}

.e-accordion-list {
  display: block;

  &__wrapper {
    background-color: var(--token-box-default-background);
    border: 1px solid var(--token-box-default-border);
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

    &-borderless {
      border: 0;
    }
  }

  &__item {
    border-bottom: 1px solid var(--token-box-default-border);
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    &-last {
      border: 0;
    }
  }

  &__label_container {
    @include has-focus-state;

    align-items: center;
    background: none;
    border: 0;
    display: flex;
    flex: 1 1 auto;
    gap: $space-xs;
    padding: $space-s;

    &:where(:not(.e-accordion-list__label_container-disabled)):hover {
      background-color: var(--token-neutral-100);
    }

    &-first {
      border-top-left-radius: $border-radius - 1;
      border-top-right-radius: $border-radius - 1;
    }

    &-last {
      border-bottom-left-radius: $border-radius - 1;
      border-bottom-right-radius: $border-radius - 1;
    }

    &-disabled {
      color: var(--token-text-faint);
    }
  }

  &__indicator_icon {
    transform: rotate(-90deg);

    &-active {
      transform: none;
    }
  }

  &__content {
    border-top: 1px solid var(--token-box-default-border);
    flex: 1 1 auto;
    padding: $space-s;
  }
}
