// scss-lint:disable SelectorFormat,IdSelector,ImportantRule,ColorVariable,PlaceholderInExtend,NameFormat,SelectorDepth

.ui-datepicker {
  @include e-datepicker;

  background-color: var(--token-background-default);
  border: 1px solid var(--token-border-default);
  box-shadow: var(--token-shadow-medium);
  display: none;
  margin-top: $space-2xs;
  padding-bottom: 0;

  &,
  & *,
  & *::before,
  & *::after {
    box-sizing: border-box;
  }

  a {
    @include typography("body");

    color: var(--token-text-default);
    text-decoration: none;
  }

  .ui-datepicker-title {
    @include e-datepicker-title;
  }

  .ui-datepicker-prev {
    @include e-datepicker-action("prev");

    font-size: 0;
    top: $space-xs;
    width: size("base", 3);

    &::after {
      color: var(--token-text-default);
      content: var(--token-glyphs-move-left);
      font-family: var(--token-icon-default-fontFamily);
      font-size: var(--token-icon-default-size);
    }
  }

  .ui-datepicker-next {
    @include e-datepicker-action("next");

    font-size: 0;
    top: $space-xs;
    width: size("base", 3);

    &::after {
      color: var(--token-text-default);
      content: var(--token-glyphs-move-right);
      font-family: var(--token-icon-default-fontFamily);
      font-size: var(--token-icon-default-size);
    }
  }

  &-calendar {
    // scss-lint:disable QualifyingElement
    a.ui-state-default {
      @include e-datepicker-day;

      display: inline-block;
      padding: 0;
      text-align: center;

      &.ui-state-active {
        @include e-datepicker-day-modifier("current");
      }
    }
  }

  .timepicker {
    &-title {
      @extend .e-field__label;

      display: block;
      margin: 0 0 $space-2xs; // from @extend .e-field__label;
    }
  }

  .ui-timepicker {
    &-div {
      padding: 0 $space-m;
    }

    &-title {
      @extend .e-field__label;
      @extend .e-field__label-inline;

      margin: 0 $space-2xs 0 0; // from @extend .e-field__label and @extend .e-field__label-inline;
    }

    &-select-input {
      @include e-input-old;
      @include e-input-size("small");
    }
  }

  &-buttonpane {
    @include clearfix;

    padding-bottom: $space-m;

    // scss-lint:disable QualifyingElement
    button.ui-state-default {
      @include e-btn;

      float: left;
      margin-left: $space-m;

      &.ui-priority-primary {
        @include e-btn-style("primary", $btn-styles);

        float: right;
        margin-right: $space-m;
      }
    }
  }
}
