.e-layout {
  display: block;
  margin: auto;
  max-width: map.get($screen-sizes, "large");
  min-width: map.get($screen-sizes, "small");
  padding: #{size-unitless("shellbar", "horizonHeight") + $space-m} $space-l $space-l
    #{size-unitless("menu", "width") + $space-l};

  &-with_steps {
    padding-bottom: size("steps", "height");
  }

  &-without {
    &_shellbar {
      padding-top: $space-m;

      &::after {
        top: 0;
      }
    }

    &_menu {
      padding-left: $space-l;
    }

    &_navigation {
      padding-left: $space-l;
      padding-top: $space-m;

      &::after {
        left: 0;
        top: 0;
      }
    }
  }

  &-inner {
    padding-left: $space-l;

    .e-layout__header {
      background: none;
      box-shadow: none;
      margin-bottom: 0;
    }
  }

  &__iframe,
  &__application {
    height: calc(100vh - #{size("shellbar", "horizonHeight")});
    left: size("menu", "width");
    position: absolute;
    top: #{size("shellbar", "horizonHeight")};
    width: calc(100% - #{size("menu", "width")});

    &::before {
      background-color: var(--token-background-default);
      content: "";
      display: block;
      height: $border-radius;
      left: -$border-radius;
      position: absolute;
      top: 0;
      width: $border-radius;
    }

    &-without_navigation {
      height: 100vh;
      left: 0;
      top: 0;
      width: 100%;
    }

    &-with_steps {
      padding-bottom: size("steps", "height");
    }
  }

  &__iframe {
    font-size: 0;

    > iframe {
      vertical-align: top;
    }
  }

  &-fullheight {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    max-width: none;
  }

  &__header {
    align-items: stretch;
    background: var(--token-box-default-background);
    border-bottom: none;
    box-shadow:
      #{map.get($screen-sizes, "small") - (size-unitless("menu", "width") + $space-l * 2)} 0 0 0
        var(--token-box-default-background),
      -#{map.get($screen-sizes, "small") - (size-unitless("menu", "width") + $space-l * 2)} 0 0 0
        var(--token-box-default-background);
    display: flex;
    flex: 0 0 auto;
    margin: -#{$space-m} 0 $space-l 0;
    padding: $space-m 0;
    position: relative;
    width: 100%;

    &-no-background {
      background: transparent;
      box-shadow: none;

      &::before {
        border-bottom: 0;
        box-shadow: none;
      }
    }
  }

  &__back {
    align-self: center;
    background: transparent;
    border: 0;
    flex: 0 0 auto;
    height: size("base", 5);
    margin-right: $space-m;
    position: relative;
    width: 32px;
  }

  &__title {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    font-weight: 900;
    margin-bottom: 0;
  }

  &__actions {
    align-items: center;
    align-self: center;
    display: flex;
    flex: 0 0 auto;
    flex-shrink: 0;
    margin-left: auto;
    padding-left: $space-s;
  }

  &__action {
    align-self: center;
    flex-shrink: 0;
    margin-left: $space-s;
  }

  &__content {
    display: flex;
    flex: 1 1 auto;
    margin: 0 #{-$space-s};
    min-height: 0;
    padding-left: 0;
    padding-right: 0;

    &-form {
      margin: auto;
      max-width: #{size-unitless("base", 100) + 2 * $space-s};
    }

    &-vertical {
      flex-direction: column;
    }
  }

  &__section {
    @include e-box-style;

    background: var(--token-box-default-background);
    border: 1px solid var(--token-box-default-border);
    border-radius: $border-radius;
    box-shadow: none;
    flex: 1 1 0;
    margin: 0 $space-s;
    min-width: 0;

    &-transparent {
      background: transparent;
      border: 0;
      box-shadow: none;
    }

    &-with_verticalnav {
      border: 0;
      box-shadow: none;
    }

    &-sidebar {
      flex: 0 0 size("base", 40);
    }

    &-sidebar_with_verticalnav {
      flex: 0 0 size("base", 50);
    }
  }
}
