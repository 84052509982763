.e {
  &-datagrid {
    container: datagrid / inline-size;
    display: block;

    &-bordered {
      border: 1px solid var(--token-box-default-border);
      border-radius: $border-radius;
    }

    &__header {
      background-color: var(--token-box-default-background);
      border-bottom: 1px solid var(--token-box-default-border);
      border-top-left-radius: $border-radius - 1;
      border-top-right-radius: $border-radius - 1;
      display: flex;
      flex-wrap: wrap;

      label {
        font-size: map.get($typography, "body", "font-size");
      }
    }

    &__header_left {
      align-items: center;
      display: flex;
      flex: 1 0 auto;
      padding: $space-s 0 $space-s $space-m;

      > div {
        margin-right: $space-m;
      }
    }

    &__header_right {
      flex: 1 0 auto;
      padding: $space-s $space-m;
    }

    &__advanced_filters,
    &__bulk {
      align-items: center;
      border-top: 1px solid var(--token-box-default-border);
      display: flex;
      flex: 1 0 100%;
      flex-wrap: wrap;
      padding: 0 0 $space-s $space-m;

      > div {
        margin: $space-s $space-m 0 0;
      }
    }

    &__item_actions {
      background-color: var(--token-box-default-background);
      border: 1px solid var(--token-box-default-border);
      border-radius: $border-radius;
      box-shadow: var(--token-shadow-light);
      opacity: 0;

      &:focus-within {
        opacity: 1;
      }

      .e-datagrid__row:hover & {
        opacity: 1;
      }
    }

    &__item_action {
      @include has-focus-state(-$space-3xs);

      background: none;
      border: 0;
      padding: 0;

      &,
      &:visited,
      &:focus,
      &:hover {
        color: var(--token-box-default-text);
      }

      &-loading {
        display: grid;
        place-content: center;
        width: size("base", 5);
      }
    }

    &__bulk_dropdown {
      align-items: center;
      display: flex;
    }

    &__bulk_dropdown_button {
      margin-right: $space-xs;
    }

    &__table_wrapper {
      overflow-x: auto;

      .e-datagrid__column_header_title {
        white-space: nowrap;
      }
    }

    &__card_wrapper {
      background: var(--token-tableCell-default-background);
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      display: grid;
      gap: $space-m;
      grid-auto-rows: auto;
      grid-template-columns: repeat(4, 1fr);
      padding: $space-m;
    }

    @container datagrid (width < 900px) {
      .e-datagrid__card_wrapper {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &__table {
      position: relative;
    }

    &__column_header {
      &:hover {
        background: var(--token-tableCell-default-backgroundHover);

        .e-datagrid__column_settings_button {
          opacity: 1;
        }
      }

      &::after {
        content: "";
        display: none;
        height: 0;
      }

      &-narrow,
      &-wide {
        &::after {
          display: block;
        }

        .e-datagrid__column_header_title {
          white-space: normal;
        }
      }

      &-narrow::after {
        width: 150px - $space-m - $space-xl;
      }

      &-wide::after {
        width: 250px - $space-m - $space-xl;
      }
    }

    &__column_header_wrapper {
      align-items: flex-end;
      display: flex;
      gap: $space-xs;
      justify-content: space-between;
    }

    &__column_header_sort {
      flex: 0 1 auto;
    }

    &__column_header_title {
      align-items: center;
      display: flex;
      flex: 1 1 auto;
      justify-content: flex-start;

      &-right {
        justify-content: flex-end;
      }
    }

    &__column_header_sort_button {
      @include has-focus-state(0);
    }

    &__column_settings {
      flex: 0 1 auto;
    }

    &__column_settings_button {
      opacity: 0;

      &:focus,
      &.e-btn-active {
        opacity: 1;
      }
    }

    &__column_settings_popup {
      .e-actionlist {
        padding: 0;
      }
    }

    &__card_group {
      grid-column: span 4;
      margin-bottom: 0;
      margin-top: $space-s;

      @container datagrid (width < 900px) {
        .e-datagrid__card_wrapper {
          grid-column: span 3;
        }
      }
    }

    &__footer {
      background-color: var(--token-box-default-background);
      border-bottom-left-radius: $border-radius - 1;
      border-bottom-right-radius: $border-radius - 1;
      border-top: 1px solid var(--token-box-default-border);
      display: flex;
      justify-content: space-between;
      padding: $space-s $space-m;
    }

    &__page_size {
      align-items: center;
      display: flex;
    }

    &__pagination {
      align-items: center;
      display: flex;
    }

    &__counter {
      padding-right: $space-m;
    }

    &__switch_tooltip {
      display: inline-block;
    }

    [data-e-version="2"].e-table .e-table__sort::before {
      content: none;
    }

    &__empty_state {
      &-card_view {
        grid-column: 1 / 4;
      }
    }
  }
}

e-datagrid > e-datagrid-empty-state,
e-datagrid > e-datagrid-error-state {
  display: none;
}

e-datagrid-layout-card > e-datagrid-empty-state,
e-datagrid-layout-card > e-datagrid-error-state {
  display: none;
}

e-datagrid-wrapper {
  display: block;
}

[data-e-version="2"].e-table th.e-datagrid__column_header {
  padding: $space-s $space-xs;
}

[data-e-version="2"].e-table td.e-datagrid__item_actions_wrapper {
  padding: 0 $space-s 0 0;
  position: sticky;
  right: 0;
  vertical-align: middle;
}
