e-illustrated-message {
  align-items: center;
  display: flex;
  flex-direction: column;

  &::part(hidden) {
    display: none;
  }

  &::part(content) {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: size("base", 40);
  }

  &::part(icon) {
    align-items: center;
    background-color: var(--token-background-strong);
    border-radius: 50%;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    margin-bottom: $space-l;
  }

  &::part(icon-small) {
    height: size("base", 6);
    width: size("base", 6);
  }

  &::part(icon-default) {
    height: size("base", 8);
    width: size("base", 8);
  }

  &::part(icon-large) {
    height: size("base", 12);
    width: size("base", 12);
  }

  &::part(title) {
    @include typography("heading3");

    display: block;
    line-height: $text-lineheight-condensed;
    margin-bottom: $space-s;
    text-align: center;
  }

  &::part(description) {
    display: block;
    margin-bottom: $space-s;
    text-align: center;
  }

  &::part(actions) {
    align-items: center;
    display: flex;
    gap: $space-s;
    justify-content: center;
    margin-bottom: $space-s;
  }

  $types: (error, warning, success, ai);

  @each $type in $types {
    &:where([type="#{$type}"]) {
      &::part(icon) {
        background-color: var(--token-#{$type}-200);
        color: var(--token-text-#{$type});
      }

      &::part(title) {
        color: var(--token-text-#{$type});
      }
    }
  }

  &:where([type="faint"]) {
    &::part(icon) {
      background-color: var(--token-background-strong);
      color: var(--token-text-faint);
    }

    &::part(title) {
      color: var(--token-text-faint);
    }

    &::part(description) {
      color: var(--token-text-faint);
    }
  }

  &:where([layout="horizontal"]) {
    flex-direction: row;
    justify-content: center;

    &::part(content) {
      align-items: flex-start;
      margin-left: $space-l;
    }

    &::part(title),
    &::part(description) {
      text-align: left;
    }
  }
}
