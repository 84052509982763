$modal-box-header-height: 65px !default;
$modal-box-footer-height: 66px !default;

e-modal {
  display: none;
}

.e {
  &-modal {
    bottom: 0;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: size("shellbar", "horizonHeight");
    width: 100%;
    z-index: z("modal") - 1;

    &__visible {
      display: block;
    }

    &__background {
      background-color: color-mix(in srgb, var(--token-overlay-dark) 60%, transparent);
      bottom: 0;
      height: 100%;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      z-index: z("modal-backdrop");
    }

    &__container {
      @include center-content(true, ".e-modal__content");

      height: 100%;
      position: relative;
    }

    &__close {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      right: $space-m;
      top: $space-m;
    }

    &__header {
      height: $modal-box-header-height;
      left: 0;
      line-height: 1.2;
      padding: $space-m $space-xl;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &__footer {
      border-top: 1px solid var(--token-box-default-border);
      bottom: 0;
      height: size("base", 9);
      left: 0;
      padding: $space-m;
      position: absolute;
      width: 100%;
    }

    &__content {
      background-color: var(--token-box-default-background);
      display: inline-block;
      max-width: size("base", 81);
      padding: $space-3xl $space-xl;
      position: relative;
      text-align: left;
      vertical-align: middle;
      width: 100%;
      z-index: z("modal");

      &-small {
        max-width: size("base", 63);
      }

      &-large {
        max-width: size("base", 125);
      }

      .e-separator-fullwidth {
        margin-left: -$space-xl;
        margin-right: -$space-xl;
      }

      &-condensed {
        padding: $space-m;

        .e-separator-fullwidth {
          margin-left: -$space-m;
          margin-right: -$space-m;
        }

        .e-modal__scrollable {
          margin: 0 (-$space-m);
          padding: 0 $space-m $space-m;
        }

        .e-modal__header {
          padding: $space-m;
        }
      }
    }

    &__image {
      margin-bottom: -$space-3xl;
      margin-top: -$space-m;
    }

    &-image {
      .e-modal__content {
        width: auto;
      }

      .e-modal__close {
        right: 5px;
        top: 5px;
      }
    }

    &-iframe {
      .e-modal__content {
        font-size: 0;
        padding: $space-4xl 0 0;
      }

      .e-modal__close {
        right: $space-2xs;
        top: $space-2xs;
      }
    }

    &-multiple {
      .e-modal__content {
        font-size: 0;
        padding: $space-3xl $space-xl;
        text-align: center;
      }
    }

    &-withheader {
      .e-modal__content {
        padding-top: $modal-box-header-height;
      }
    }

    &__scrollable {
      bottom: $modal-box-footer-height;
      margin: 0 (-$space-xl);
      overflow: auto;
      padding: 0 $space-xl $space-3xl;
      position: absolute;
      top: $modal-box-header-height;
      width: 100%;

      .e-modal__content {
        padding: $space-3xl $space-xl;
      }
    }

    &__prev_item,
    &__next_item {
      bottom: $space-l;
      cursor: pointer;
      left: 0;
      padding: $space-2xs $space-3xs;
      position: absolute;
      top: $space-xl;
      transition: background 0.2s;
      width: size("base", 4);

      &:hover {
        background-color: var(--token-background-strong);
      }

      a {
        left: $space-3xs;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__next_item {
      left: auto;
      padding: $space-2xs $space-2xs;
      right: 0;

      a {
        left: $space-2xs;
      }
    }
  }
}

.modal-open {
  overflow: hidden;
}

.modal {
  background-color: var(--token-box-default-background);
  border-radius: $border-radius;
  display: none;
  left: 0;
  margin: 32px auto !important; /* stylelint-disable-line declaration-no-important */
  margin-top: calc(
    32px + size("shellbar", "horizonHeight")
  ) !important; /* stylelint-disable-line declaration-no-important */

  max-height: calc(100% - (64px + size("shellbar", "horizonHeight")));
  max-width: 100%;
  outline: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 0;
  width: size("base", 75);
  z-index: z("modal");

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.modal-body {
  background-clip: padding-box;
  background-color: var(--token-box-default-background);
  max-height: size("base", 57);
  outline: 0;
  overflow-y: auto;
  padding: 0 $space-m;
  position: relative;
}

.modal-backdrop {
  background-color: color-mix(
    in srgb,
    var(--token-overlay-dark) 60%,
    transparent
  ) !important; /* stylelint-disable-line declaration-no-important */

  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: z("modal-backdrop");

  &.fade {
    opacity: 0;
  }

  &.in {
    opacity: 0.5;
  }
}

.modal-header {
  background-color: var(--token-box-default-background);
  padding: $space-m $space-m $space-2xs;

  h3 {
    @extend %h2;
  }

  .close {
    font-size: 0;
    position: absolute;
    right: $space-xl;
    text-decoration: none;
    top: $space-s;

    &::before {
      color: var(--token-icon-default-text);
      content: var(--token-glyphs-close);
      font-family: var(--token-icon-default-fontFamily);
      font-size: var(--token-icon-default-size);
      height: size("icon", "medium");
      position: absolute;
      width: size("icon", "medium");
    }
  }
}

.modal-title {
  margin: 0;
}

.modal-footer {
  background-color: var(--token-box-default-background);
  padding: $space-xl;
  text-align: left; // right align buttons

  .btn {
    @include e-btn;

    border-radius: $border-radius;
  }

  .btn-primary {
    @include e-btn-style("primary", $btn-styles);

    border-radius: $border-radius;
  }
}

.modal-scrollbar-measure {
  height: size("base", 6);
  overflow: scroll;
  position: absolute;
  top: -9999px;
  width: size("base", 6);
}

div.modal {
  .modal-header {
    height: size("base", 8);
    padding: $space-s;
  }

  .modal-body {
    height: calc(
      100% - #{size("base", 16)}
    ) !important; /* stylelint-disable-line declaration-no-important */

    max-height: none;

    iframe {
      height: 100% !important; /* stylelint-disable-line declaration-no-important */
      top: 0 !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &.modal-body-only {
    .modal-body {
      height: calc(
        100% - #{size("base", 8)}
      ) !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  .modal-footer {
    height: size("base", 8);
    padding: $space-s;
  }
}
