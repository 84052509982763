e-calendar {
  &:focus {
    outline: none;
  }
}

@mixin e-calendar {
  background-color: var(--token-background-default);
  border: 1px solid var(--token-border-default);
  margin: 0 auto $space-m;
  position: relative;
  text-align: center;
  user-select: none;
  width: (size-unitless("base", 5) * 7) + 2px;

  table {
    @include typography("body");

    margin: auto;
    table-layout: fixed;
    text-align: center;
    width: 100%;
  }

  thead {
    th {
      font-weight: 500;
      height: size("base", 5);
      line-height: size("base", 5);
      text-transform: uppercase;
      vertical-align: middle;
      width: size("base", 5);
    }
  }
}

@mixin e-calendar-day {
  cursor: pointer;
  height: size("base", 5);
  line-height: size("base", 5);
  transition: background $transition-time ease-in-out;
  width: size("base", 5);

  &:hover {
    background-color: var(--token-background-strong);
  }
}

@mixin e-calendar-day-modifier($modifier: "default") {
  @if $modifier == "current" {
    background-color: var(--token-highlight-400);
    color: 1px solid var(--token-text-light);

    &:hover {
      background-color: var(--token-highlight-default);
    }
  }

  @if $modifier == "alternate" {
    color: var(--token-neutral-400);
  }

  @if $modifier == "disabled" {
    color: var(--token-text-faint);
    cursor: default;

    &:hover {
      background: transparent;
    }
  }

  @if $modifier == "today" {
    color: var(--token-highlight-default);
  }
}

.e {
  &-calendar {
    @include e-calendar;

    border-radius: $border-radius;

    &-sticky {
      border: 0;
      margin: 0;
    }

    &__head {
      align-items: center;
      border-bottom: 1px solid var(--token-border-default);
      color: var(--token-text-faint);
      display: flex;
      height: size("base", 5);
    }

    &__action {
      cursor: pointer;
      margin: 0 $space-2xs;
    }

    &__now {
      box-shadow: -7px 0 0 -6px var(--token-border-default);
      padding: 10px;
      position: relative;
    }

    &__now_day {
      @include typography("small");

      font-weight: 500;
      left: $space-m;
      line-height: 1;
      position: absolute;
      top: $space-m + 3px;
    }

    &__title {
      @include text-overflow;

      flex: 1 1 0%;
    }

    &__day {
      @include e-calendar-day;

      &-alternate {
        @include e-calendar-day-modifier("alternate");
      }

      &-today {
        @include e-calendar-day-modifier("today");
      }

      &-current {
        @include e-calendar-day-modifier("current");
      }

      &-disabled {
        @include e-calendar-day-modifier("disabled");
      }
    }
  }
}
