e-funnelline {
  display: block;
}

.e {
  &-funnelchart {
    background-image: url("horizon/e-funnel-pattern.svg?token=morningHorizon,border-default"); // TODO: keep as backgronud
    background-position: 0 -2.5px;
    display: flex;
    justify-content: space-between;
    max-width: 100%;

    @include flipper-on("theme-eveningHorizon") {
      background-image: url("horizon/e-funnel-pattern.svg?token=eveningHorizon,border-default"); // TODO: keep as backgronud
    }

    &__legend {
      flex: 0 1 auto;
      width: calc(100% - #{size("base", 32.5)});
    }

    &__row {
      display: flex;
      justify-content: space-between;
      line-height: size("base", 5);
    }

    &__title {
      @include text-overflow;

      padding-right: $space-m;
    }

    &__value {
      font-size: typo("title", "size");
      font-weight: typo("title", "weight");
    }

    &__chart {
      flex: 0 0 auto;
      font-size: 0;
      line-height: 1.667;

      svg {
        margin-bottom: $space-2xs;
      }
    }
  }
}
