input.editable-input {
  @include e-input-old;
}

select.editable-input {
  @include e-select-old;
}

input,
select {
  &.editable-input {
    @include e-input-size("large");
  }
}

.editable-input.editable-input-medium {
  @include e-input-size("medium");
}

.editable-input.editable-input-small {
  @include e-input-size("small");
}

.editable-controls {
  @include e-buttongroup("button, .editable-input");

  display: inline-block;
  margin-left: 0;
  margin-right: 0;
  vertical-align: middle;
}

.editable-error {
  @include typography("small");

  background: var(--token-error-default);
  color: var(--token-text-light);
  margin: $space-2xs $space-xs;
  padding: $space-xs;
  position: absolute;
  top: 100%;
  z-index: 1;
}
