.e-section {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    align-items: center;
    border-bottom: 1px solid var(--token-border-default);
    display: flex;
    flex: 0 0 auto;
    padding: 0 $space-l;

    &::before {
      content: "";
      height: #{map.get($typography, "heading2", "line-height") + 2 * $space-s};
    }
  }

  &__title {
    @include typography("heading2");

    align-items: center;
    display: flex;
    flex: 1 1 auto;
    padding: $space-s 0;
  }

  &__actions {
    align-items: center;
    display: flex;
    flex: 0 1 auto;
    margin-left: auto;
    padding-left: $space-s;
  }

  &__action {
    margin-left: $space-s;
  }

  &__content {
    flex: 1 1 100%;
    overflow: auto;
    padding: $space-l;

    .e-stretch {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
