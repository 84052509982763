$verticalnav-transition: $transition-time * 2 cubic-bezier(0.25, 0.8, 0.25, 1) !default;

e-verticalnav-menu {
  background-color: var(--token-box-default-background);
  border-bottom-left-radius: $border-radius;
  border-top-left-radius: $border-radius;
  box-shadow: inset -1px 0 0 0 var(--token-box-default-border);
  display: block;
  flex: 0 0 auto;
  overflow: hidden;
  transition: width $verticalnav-transition;
  width: size("base", 8);
}

.e {
  &-verticalnav {
    background-color: var(--token-box-default-background);
    border-radius: $border-radius;
    display: flex;
    margin-bottom: 0;

    &-fullheight {
      height: 100%;
      margin-bottom: 0;
    }

    &-condensed {
      .e-verticalnavitem {
        height: size("base", 5);
        line-height: size("base", 5);

        .e-icon {
          font-size: var(--token-icon-default-size);
          height: var(--token-icon-default-size);
          width: var(--token-icon-default-size);
        }

        &-active {
          &::after {
            height: size("base", 5);
          }
        }
      }
    }

    &__content {
      @include e-box;

      border: 0;
      border-bottom-right-radius: $border-radius;
      border-top-right-radius: $border-radius;
      box-shadow: none;
      flex: 1 1 auto;
      margin-bottom: 0;
      min-width: 0; // Overflow issue: http://stackoverflow.com/questions/26895349/how-can-i-get-ff-33-x-flexbox-behavior-in-ff-34-x/26916542#26916542
      padding-bottom: $space-m;
      position: relative;

      &-fullheight {
        height: 100%;
      }

      &-has_container {
        padding: $space-xs 0 0;
      }

      &-nopadding {
        padding: 0;

        .e-verticalnav__header {
          margin: 0 0 $space-m;
        }
      }

      &-scrollable {
        overflow: auto;
      }
    }

    &__toggle {
      align-items: center;
      display: flex;
      height: size("base", 8);
      justify-content: center;
      width: size("base", 8);

      &-active {
        .e-icon {
          transform: rotate(180deg);
        }
      }

      .e-icon {
        transition: transform $verticalnav-transition;
      }
    }

    &__header {
      align-items: center;
      border-bottom: 1px solid var(--token-box-default-border);
      display: flex;
      height: size("base", 7);
      margin: -$space-m;
      margin-bottom: $space-m;
      padding: 0 $space-m;

      &-no_border {
        border-bottom: none;
        margin-bottom: -1px;
      }
    }

    &__body {
      height: calc(100% - #{size("base", 8)});
      margin-top: -$space-m;
      overflow: auto;
      padding: $space-m;
    }

    &__title {
      @include typography("body");
    }

    &__actions {
      flex-basis: auto;
      flex-shrink: 0;
      margin-left: auto;
    }

    &__extended {
      e-verticalnav-item {
        display: block;
      }

      .e-verticalnavitem-active .e-verticalnavitem,
      .e-verticalnavitem.e-verticalnavitem-active {
        &::after {
          left: calc(100% - #{$space-2xs});
        }
      }
    }
  }

  &-verticalnavitem {
    border-bottom: 0;
    border-top: 0;
    border-top-left-radius: $border-radius;
    cursor: pointer;
    font-size: 0;
    height: size("base", 8);
    line-height: size("base", 8);
    margin-bottom: 0;
    padding-right: $space-m;
    position: relative;
    white-space: nowrap;

    &:where(:not(.e-verticalnavitem-disabled):not(.e-verticalnavitem-active)) {
      &:hover::before {
        background-color: var(--token-tab-default-backgroundHover);
        border-radius: $border-radius;
        content: "";
        height: size("base", 6);
        left: $space-xs;
        position: absolute;
        top: $space-xs;
        width: size("base", 6);
      }

      &:where(:is(.e-verticalnav-condensed .e-verticalnavitem)) {
        &:hover::before {
          content: "";
          height: size("base", 5);
          top: 0;
        }
      }
    }

    &:hover {
      background: transparent;
    }

    &-faded {
      .e-icon,
      .e-verticalnavitem__value {
        opacity: 0.5;
      }
    }

    &-disabled {
      cursor: not-allowed;

      .e-verticalnavitem__item,
      .e-verticalnavitem__icon,
      .e-verticalnavitem__value {
        opacity: 0.5;
      }

      .e-svgclickfix {
        cursor: not-allowed;
      }

      &:hover {
        background: transparent;
        color: var(--token-tab-default-text);

        &.e-verticalnavitem-active {
          color: var(--token-tab-selected-text);
        }
      }
    }

    &__icon {
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      width: size("base", 8);
    }

    &__value {
      @include typography("body");

      display: inline-block;
      vertical-align: middle;
    }

    &__badge {
      align-items: center;
      background-color: var(--token-label-error-background);
      border-radius: 50px;
      color: var(--token-label-error-text);
      display: inline-flex;
      font-size: 12px;
      font-weight: bold;
      height: size("base", 2);
      justify-content: center;
      left: $space-2xl;
      min-width: size("base", 2);
      padding: $space-xs #{$space-2xs + 1};
      pointer-events: none;
      position: absolute;
      top: $space-xs;
      width: auto;
    }
  }
}

e-verticalnav-item {
  display: inline-block;
  position: relative;

  &:focus {
    outline: 0;
  }

  &.e-verticalnavitem-active .e-verticalnavitem,
  .e-verticalnavitem-active {
    background: transparent;
    border-top: 0;
    color: var(--token-tab-selected-text);

    &:hover {
      background: transparent;
    }

    &::after {
      @include tab-bar-indicator;
      @include tab-bar-indicator-vertical;

      left: #{size-unitless("base", 8) - size-unitless("base", 0.5) + 1}px;
      right: auto;
      width: 3px;
    }

    .e-icon {
      color: var(--token-tab-selected-text);
    }
  }
}
