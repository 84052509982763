e-actionlist {
  display: block;
  min-width: $space-xl;

  &-wrapper {
    display: flex;
    flex-direction: column;
  }
}

e-actionlist-item {
  display: none;
}

.e {
  &-actionlist {
    border: 0;
    display: flex;
    flex-direction: column;
    line-height: size("base", 3);
    min-width: size("base", 25);
    padding: $space-m;
    text-align: left;

    &-spacing_none {
      padding: 0;
    }

    &-popover {
      outline: 0;
      overflow: hidden;
    }

    &-disable_min_width {
      min-width: 0;
    }

    &__input {
      margin: 0 0 $space-2xs;
      position: relative;
    }

    &__selected_marker {
      color: var(--token-action-default-marker);
      left: $space-xs;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      :where(.e-actionlist__item-selected) & {
        color: var(--token-action-selected-text);
      }

      :where(.e-actionlist__item-custom_color) & {
        color: inherit;
      }
    }

    &__grouplabel,
    &__item {
      position: relative;
      user-select: none;
    }

    &__grouplabel {
      background: var(--token-background-default);
      display: block;
      margin: $space-xs 0;
      padding: 0 $space-xs 0 0;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    &__item {
      @include has-focus-state;
      @include typography("body");
      @include word-wrap;

      background: none;
      border: 0;
      border-radius: $border-radius;
      cursor: pointer;
      display: block;
      line-height: calc(var(--token-button-default-height) - 2px);
      min-height: var(--token-button-default-height);
      padding: 0 $space-xs 0 $space-2xl;
      text-align: left;
      width: 100%;

      &:hover {
        background-color: var(--token-action-default-backgroundHover);
        color: var(--token-action-default-text);

        &.e-actionlist__item-selected {
          background-color: var(--token-action-selected-backgroundHover);
          color: var(--token-action-selected-text);
        }
      }

      e-actionlist:focus-within &-active,
      .e-actionlist__itemscontainer-show_active &-active {
        @include has-secondary-focus-style;
      }

      &-selected {
        background-color: var(--token-action-selected-background);
        color: var(--token-action-selected-text);
      }

      &-disabled,
      &-disabled:hover {
        background-color: var(--token-action-default-backgroundDisabled);
        color: var(--token-action-default-text);
        cursor: not-allowed;
        opacity: 0.5;

        &.e-actionlist__item-selected {
          background-color: var(--token-action-selected-backgroundHover);
          color: var(--token-action-selected-text);
        }
      }

      &-selection_disabled {
        padding-left: $space-s;
      }
    }

    &__itemscontainer {
      @include has-focus-state($space-3xs);

      min-height: 0;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;

      &:focus {
        outline: 0;
      }
    }

    &__emptystate {
      padding: $space-xs;
    }

    &__spinner {
      display: block;
      height: size("base", 3);
      margin-top: -$space-3xs;
    }
  }
}
