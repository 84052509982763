$grid-columns: 12;

@mixin make-grid-columns($class: "default", $i: 1) {
  @if $class == "default" {
    $class: "";
  } @else {
    $class: $class + "-";
  }

  $list: ".e-col-#{$class}1";

  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .e-col-#{$class}#{$i}";
  }

  #{$list} {
    float: left;
    padding-left: size("grid", "gutter");
    padding-right: size("grid", "gutter");
  }
}

@mixin calc-grid-column($index, $class, $type) {
  @if $type == width and $index > 0 {
    .e-col-#{$class}#{$index} {
      width: math.percentage(math.div($index, $grid-columns));
    }
  }

  @if $type == push and $index > 0 {
    .e-col-#{$class}push-#{$index} {
      left: math.percentage(math.div($index, $grid-columns));
    }
  }

  @if $type == push and $index == 0 {
    .e-col-#{$class}push-0 {
      left: auto;
    }
  }

  @if $type == pull and $index > 0 {
    .e-col-#{$class}pull-#{$index} {
      right: math.percentage(math.div($index, $grid-columns));
    }
  }

  @if $type == pull and $index == 0 {
    .e-col-#{$class}pull-0 {
      right: auto;
    }
  }

  @if $type == offset {
    .e-col-#{$class}offset-#{$index} {
      margin-left: math.percentage(math.div($index, $grid-columns));
    }
  }
}

@mixin loop-grid-columns($columns, $class, $type) {
  @for $i from 0 through $columns {
    @include calc-grid-column($i, $class, $type);
  }
}

@mixin make-grid($class: "default") {
  @if $class == "default" {
    $class: "";
  } @else {
    $class: $class + "-";
  }

  @include loop-grid-columns($grid-columns, $class, width);
}

@mixin container-fixed($gutter: size("grid", "gutter")) {
  @include clearfix;

  margin-left: auto;
  margin-right: auto;
  padding-left: $gutter;
  padding-right: $gutter;
}

@mixin make-row($gutter: size("grid", "gutter")) {
  @include clearfix;

  margin-left: -$gutter;
  margin-right: -$gutter;
}

// Class generation
.e-container {
  @include container-fixed;

  max-width: size("screens", "large");
  min-width: size("screens", "minimum");
  padding-top: $space-xs;

  &-fullwidth {
    max-width: none;
  }

  &-flexible {
    min-width: 0;

    @include screen("x-small", "down") {
      padding: 0;

      > .e-row {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &-withsteps {
    padding-bottom: size("steps", "height");

    .e-box {
      margin-bottom: $space-m;
    }
  }
}

.e-row {
  @include make-row;

  position: relative;

  &__height {
    display: table;
    height: 100%;
    table-layout: fixed;
    width: 100%;
  }

  &-condensed {
    .e-col-6:first-child {
      padding-right: size("grid", "gutter");
    }

    .e-col-6:last-child {
      padding-left: size("grid", "gutter");
    }

    .e-col-6:not(:first-child, :last-child) {
      padding-left: size("grid", "gutter");
      padding-right: size("grid", "gutter");
    }
  }
}

@include make-grid-columns;
@include make-grid;

@each $screen-name, $size in $screen-sizes {
  @media all and (min-width: $size) {
    @include make-grid-columns($screen-name);
    @include make-grid($screen-name);
  }
}

.e-col {
  &-height {
    display: table-cell;
    float: none;
    height: 100%;
    vertical-align: middle;

    &-bordered {
      border-right: 1px solid var(--token-border-default);
    }

    &-relative {
      position: relative;
    }
  }

  &-top {
    vertical-align: top;
  }

  &-bottom {
    vertical-align: bottom;
  }

  &-nopadding {
    padding: 0;
  }

  &-condensed {
    padding-left: size("grid", "gutter");
    padding-right: size("grid", "gutter");

    &:first-child {
      padding-left: size("grid", "gutter");
    }

    &:last-child {
      padding-right: size("grid", "gutter");
    }
  }

  &-even {
    padding-left: size("grid", "gutter");
    padding-right: size("grid", "gutter");

    &:first-child {
      padding-left: size("grid", "gutter");
    }

    &:last-child {
      padding-right: size("grid", "gutter");
    }
  }

  &__separator {
    background-color: var(--token-border-default);
    height: 100%;
    left: 50%;
    position: absolute;
    width: 1px;

    &-3 {
      left: math.percentage(math.div(3, $grid-columns));
    }

    &-4 {
      left: math.percentage(math.div(4, $grid-columns));
    }

    &-8 {
      left: math.percentage(math.div(8, $grid-columns));
    }

    &-9 {
      left: math.percentage(math.div(9, $grid-columns));
    }
  }
}

a.e-col-height {
  &:hover {
    background-color: var(--token-neutral-200);
  }
}
