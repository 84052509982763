.e {
  &-flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 (-$space-m) (-$space-xl);

    &__item {
      flex: 0 0 auto;
      margin: 0 ($space-m) $space-xl;

      &-auto {
        flex: 1 1 0%;
      }

      &-center {
        align-self: center;
      }
    }

    &__separator {
      border-right: 1px solid var(--token-border-default);
      flex: 0 0 0;
      margin-right: -1px;
    }

    &-around,
    &-between {
      margin: 0;

      .e-flex__item {
        margin: 0;
      }
    }

    &-widgets {
      margin: 0 (-$space-s) (-$space-l);

      .e-flex__item {
        margin: 0 $space-s $space-l;

        @for $i from 1 through 12 {
          &-#{$i} {
            width: calc(#{math.percentage(math.div($i, 12))} - #{$space-xl});
          }
        }
      }
    }

    &-condensed {
      margin: 0 (-$space-2xs) (-$space-s);

      &.e-flex__item {
        margin: 0 (($space-m) - ($space-2xs)) $space-l;
      }

      .e-flex__item {
        margin: 0 ($space-2xs) ($space-s);

        &.e-flex-sticky {
          margin: 0 ($space-2xs) + 1 ($space-s) + 1 ($space-2xs) + 1;
        }
      }
    }

    &-sticky {
      margin: 0 0 1px 1px;

      .e-flex__item {
        margin: 0 0 -1px -1px;

        @for $i from 1 through 12 {
          &-#{$i} {
            width: calc(#{math.percentage(math.div($i, 12))} + 1px);
          }
        }
      }

      .e-flex__separator {
        margin: 0 -1px -1px 0;
      }

      &.e-flex__item {
        margin: 0 ($space-m) + 1 ($space-m) + 1;
      }
    }

    &-around {
      justify-content: space-around;
    }

    &-between {
      justify-content: space-between;
    }

    &-center {
      justify-content: center;
    }

    &-vertical_center {
      align-items: center;
    }
  }
}
