.e {
  &-timepicker {
    &__input {
      cursor: text;
      line-height: calc(var(--token-input-default-height) - 2px);
      outline: 0;
      padding-right: $space-xl;
      user-select: none;
      width: size("base", 9);

      &:read-only:focus-within:not(.e-input-disabled) {
        @include e-input-state($style: map.get($input-styles, "default"), $state: "focus");
      }

      &.e-input-disabled {
        cursor: not-allowed;
      }

      &:focus {
        z-index: 1;
      }
    }

    &__arrow {
      align-items: center;
      border-radius: $border-radius-small;
      cursor: pointer;
      display: flex;
      height: calc(var(--token-input-default-height) / 2 - 2px);
      justify-content: center;
      line-height: size("base", 2);
      position: absolute;
      right: 1px;
      user-select: none;
      width: size("base", 4);

      &-up {
        top: 1px;
      }

      &-down {
        bottom: 1px;
      }

      e-icon {
        pointer-events: none;
      }

      .e-icon,
      &:hover .e-icon {
        color: var(--token-text-default);
      }
    }
  }

  &-timepicker-input {
    line-height: calc(var(--token-input-default-height) - 2px);

    &:read-only:focus:not(.e-input-disabled) {
      @include e-input-state($style: map.get($input-styles, "default"), $state: "focus");
    }

    &__arrow {
      height: size("base", 2);
      width: size("base", 2);

      &:hover:not(:disabled) {
        background-color: var(--token-background-strong);
        box-shadow: none;
      }
    }

    &__placeholder {
      color: var(--token-text-placeholder);
      font-style: italic;
      font-weight: normal;
    }

    &.e-input-error {
      &:read-only:focus,
      &:read-only:focus-within:not(.e-input-disabled) {
        @include e-input-state($style: map.get($input-styles, "error"), $state: "focus");
      }

      .e-icon {
        color: var(--token-error-default);
      }
    }
  }
}
