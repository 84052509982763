$styles: (
  info: (
    600,
    300,
  ),
  success: (
    500,
    200,
  ),
  warning: (
    500,
    200,
  ),
  error: (
    500,
    200,
  ),
);

.e-progress {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto size("base", 1.5) min-content;
  width: 100%;

  &::before {
    align-self: center;
    background: var(--token-progressBar-default-background);
    border-radius: 8px;
    content: "";
    grid-area: 2 / 1;
    height: 8px;
    width: 100%;
  }

  &::after {
    align-self: center;
    background: var(--token-progressBar-default-bar);
    border-radius: 100%;
    content: "";
    grid-area: 2 / 1;
    height: size("base", 0.5);
    justify-self: end;
    margin-right: $space-3xs;
    width: size("base", 0.5);
  }

  &-inverse {
    &::before {
      background: var(--token-progressBar-inverse-background);
    }

    .e-progress__bar::before {
      color: var(--token-progressBar-inverse-text);
    }
  }

  @each $style, $value in $styles {
    &:where(:has(.e-progress__bar-#{$style})) {
      &::before {
        background: var(--token-progressBar-#{$style}-background);
      }

      &::after {
        background: var(--token-progressBar-#{$style}-bar);
      }
    }
  }

  &__bar {
    display: grid;
    grid-area: 1 / 1 / 3 / 2;
    grid-template-columns: 0 1fr;
    grid-template-rows: auto size("base", 1.5);

    &::before {
      content: attr(data-e-percent);
      grid-area: 1 / 1;
    }

    &::after {
      align-self: center;
      background: var(--token-progressBar-default-bar);
      border-radius: $border-radius;
      content: "";
      grid-area: 2 / span 2;
      height: size("base", 1.5);
      min-width: size("base", 1.5);
    }

    @each $style, $value in $styles {
      &-#{$style} {
        &::after {
          background: var(--token-progressBar-#{$style}-bar);
        }
      }
    }
  }

  &__label {
    display: flex;
    grid-area: 3 / 1;
    justify-content: space-between;
  }

  &-indicator {
    display: block;

    &::before {
      content: none;
    }

    &::after {
      content: none;
    }

    .e-progress__bar {
      gap: $space-xs;
      grid-template-columns: auto min-content;

      &::before {
        grid-area: 1 / 2;
      }

      &::after {
        grid-area: 1 / 1;
      }
    }
  }
}
