.e {
  &-datetimepicker {
    background-color: var(--token-background-default);
    border: 1px solid var(--token-border-default);
    margin: -1px auto;
    z-index: z("flying");

    &__date {
      .e-calendar {
        border-left: 0;
        border-right: 0;
      }
    }

    &__time {
      display: block;
      padding: $space-2xs;
    }
  }
}
