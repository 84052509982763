@import "../input/assets";

e-daterange,
e-datetimerange {
  display: block;

  &[inline] {
    display: inline-block;
  }

  &[no-clear] {
    .e-daterange__picker {
      padding: 0 $space-s;
    }
  }
}

.e {
  &-daterange {
    background-color: var(--token-background-default);
    border-radius: $border-radius;
    top: -99999px;
    z-index: z("flying");

    &-inline {
      display: inline-block;
      min-width: size("base", 25);
    }

    &__picker {
      cursor: pointer;
      line-height: size("base", 5);
      max-width: size-unitless("base", 119) - 2 + px;
      padding-right: $space-xl;
      position: relative;
      transition: none;
      user-select: none;
      will-change: box-shadow;

      &-active {
        border: 1px solid var(--token-border-default);
        box-shadow: var(--token-shadow-medium);
        z-index: z("modal-backdrop");
      }

      &.e-input-disabled {
        cursor: not-allowed;
      }

      .e-icon {
        font-size: var(--token-icon-default-sizeSmall);
        height: var(--token-icon-default-sizeSmall);
        margin-right: $space-xs;
        margin-top: -$space-2xs;
        width: var(--token-icon-default-sizeSmall);
      }

      &.e-input-error {
        @include e-input-modifier("error");

        e-icon {
          svg {
            fill: var(--token-text-error);
          }
        }

        .e-daterange__clear {
          align-items: center;
          display: flex;
          justify-content: center;

          &::before {
            color: var(--token-text-faint);
          }
        }
      }
    }

    &__clear {
      @include search-input-cancel;

      border: 0;
      position: absolute;
      right: $space-s;
      top: $space-s;
    }

    &__value {
      margin-right: $space-xs;
    }

    &__range {
      @include typography("small");
    }

    &__actions {
      @include typography("body");

      display: inline-flex;
      flex-wrap: wrap;
      margin-right: $space-m;
      vertical-align: top;
      width: size("base", 40);
    }

    &__preset {
      border-bottom: 1px solid var(--token-border-default);
      cursor: pointer;
      height: size("base", 5);
      line-height: size("base", 5);
      padding: 0 $space-s;

      &:hover {
        background-color: var(--token-background-strong);
      }

      &:first-of-type {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }

      &:last-of-type {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }

      &-active {
        background-color: var(--token-highlight-default);
        color: var(--token-text-light);
        position: relative;

        &:hover {
          background-color: var(--token-highlight-default);
        }

        &::after {
          color: var(--token-text-light);
          content: var(--token-glyphs-checkmark);
          font-family: var(--token-icon-default-fontFamily);
          font-size: var(--token-icon-default-size);
          height: size("base", 3);
          position: absolute;
          right: $space-s;
          top: 0;
          width: size("base", 3);
        }
      }

      &-disabled {
        color: var(--token-text-faint);
        pointer-events: none;
      }
    }

    &__border {
      background-color: var(--token-background-default);
      height: 3px;
      position: absolute;
    }

    &[x-placement*="bottom"] {
      .e-daterange__border {
        top: -$space-3xs;
      }
    }

    &[x-placement*="top"] {
      .e-daterange__border {
        bottom: -$space-3xs;
      }
    }

    &.e-popover {
      .e-daterange__container {
        border: 0;
        padding: 0;
      }
    }

    &__container {
      background-color: var(--token-background-default);
      border: 1px solid var(--token-border-default);
      border-radius: $border-radius;
      margin-top: -1px;
      max-width: size("base", 119);
      padding: $space-m;
    }

    &__content {
      align-items: flex-start;
      display: flex;
    }

    &__start,
    &__end {
      @include typography("body");

      flex: 0 0 auto;
    }

    &__start {
      margin-right: $space-m;
    }

    &__input {
      margin-bottom: $space-m;
      max-width: 100%;
    }

    &__presets {
      border: 1px solid var(--token-border-default);
      border-bottom: 0;
      border-radius: $border-radius;
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      margin-right: $space-m;
      margin-top: $space-l + 4px;
      width: size("base", 40);
    }

    &__time {
      display: block;
      margin-bottom: $space-m;
    }
  }
}
