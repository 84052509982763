.nc-settings-menu-item {
  align-items: center;
  border-radius: $border-radius;
  cursor: pointer;
  display: flex;
  padding: $space-s;

  &:hover {
    background: var(--token-neutral-100);
  }

  &--active {
    background: var(--token-highlight-100);

    &:hover {
      background: var(--token-highlight-200);
    }
  }

  &__subtitle {
    @include typography("small");

    color: var(--token-neutral-500);

    .nc-settings-menu-item--active & {
      color: var(--token-highlight-500);
    }
  }

  &__icon {
    border-radius: 100%;
    margin-right: $space-s;
    padding: $space-xs;
  }

  &__title {
    font-weight: 500;

    .nc-settings-menu-item--active & {
      color: var(--token-highlight-500);
    }
  }

  &__properties {
    color: var(--token-neutral-500);

    .nc-settings-menu-item--active & {
      color: var(--token-highlight-500);
    }
  }
}
