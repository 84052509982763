@import "./style";

$variants: ("gray", "blue", "purple");
$default-rule-style: map.get($rule-styles, "variant1");

e-rule-blueprint {
  display: none;
}

.e-rule-builder {
  &__actions {
    padding-top: $space-s;
  }

  &__add_rule_placeholder {
    align-items: center;
    display: flex;
    height: size("base", 5);
    padding: 0 $space-s;

    .e-icon {
      margin-left: $space-3xs;
    }
  }

  &__add_exclusion_button {
    margin: $space-m 0;
  }

  &__exclusion_label {
    font-weight: $text-bold-weight;
    margin-left: $space-m;
    padding: $space-l 0;
    position: relative;

    &::before,
    &::after {
      background: map.get($default-rule-style, "border");
      content: "";
      height: $space-m;
      left: $space-m;
      position: absolute;
      width: 1px;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  &__rule {
    display: flex;

    &_relation {
      color: map.get($default-rule-style, "text");
      flex: 0 0 0;
      padding: 17px $space-s 0;

      &-hidden {
        visibility: hidden;
      }
    }

    &:hover .e-rule-builder__rule_wrapper {
      background: map.get($default-rule-style, "hover-background");
    }

    &_wrapper {
      border: 1px solid map.get($default-rule-style, "border");
      border-bottom: 0 solid map.get($default-rule-style, "border");
      flex: 1 1 auto;
      overflow: hidden;

      .e-rule-builder__rule-first & {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }

      .e-rule-builder__rule-last & {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        border-bottom-width: 1px;
        margin-bottom: $space-m;
      }

      .e-rule-builder__rule-error & {
        border-color: var(--token-error-default);
      }
    }

    &_header {
      align-items: center;
      background: map.get($default-rule-style, "background");
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      min-height: #{size-unitless("base", 5) + (2 * $space-xs)};
      padding: $space-xs $space-s;

      &-disabled {
        cursor: not-allowed;
      }

      .e-rule-builder__rule-error & {
        background: var(--token-error-faint);
        color: var(--token-error-default);
        cursor: default;
      }

      &_auto_label {
        color: map.get($default-rule-style, "text");
        font-weight: 500;

        .e-rule-builder__rule-error & {
          color: var(--token-error-default);
        }

        &::after {
          color: map.get($default-rule-style, "auto-label");
          content: "•";
          font-weight: 500;
          margin: 0 8px;

          .e-rule-builder__rule-error & {
            color: var(--token-error-default);
          }
        }
      }

      &_summary {
        display: flex;

        &-disabled {
          color: map.get($default-rule-style, "text");
          opacity: 0.5;
        }
      }

      &_summary_value {
        &::before {
          color: map.get($default-rule-style, "auto-label");
          content: "•";
          font-weight: 500;
          margin: 0 8px;

          .e-rule-builder__rule-error & {
            color: var(--token-error-default);
          }
        }
      }

      &_actions {
        opacity: 0;
        transition: opacity $transition-time ease-in-out;

        .e-rule-builder__rule_header:hover & {
          opacity: 1;
        }

        &-show {
          opacity: 1;
        }
      }
    }

    &_editor {
      &-hidden {
        display: none;
      }

      &_content {
        background: map.get($default-rule-style, "background");
        border-bottom: 1px solid map.get($default-rule-style, "border");
        border-top: 1px solid map.get($default-rule-style, "border");
        padding: $space-xs $space-s;
      }

      &_actions {
        background: map.get($default-rule-style, "background");
        display: flex;
        gap: $space-xs;
        padding: $space-xs $space-s;
      }
    }
  }

  &__group {
    display: flex;
    margin-bottom: $space-m;

    &-main {
      margin-bottom: 0;
    }

    &_relation {
      color: map.get($default-rule-style, "text");
      flex: 0 0 0;
      padding: 18px $space-s 0;

      &-hidden {
        visibility: hidden;
      }
    }

    &_wrapper {
      background: var(--token-box-default-background);
      border: 1px solid map.get($default-rule-style, "border");
      border-radius: $border-radius;
      flex: 1 1 auto;

      &-hover {
        background: var(--token-ruleBuilder-wrapper-backgroundHover);
      }

      @for $index from 1 through length($variants) {
        &-variant-#{$index} {
          border: 1px solid map.get($rule-styles, "variant#{$index}", "border");
        }
      }
    }

    &_header {
      align-items: center;
      background: map.get($default-rule-style, "background");
      border-bottom: 1px solid map.get($default-rule-style, "border");
      border-top-left-radius: $border-radius - 1px;
      border-top-right-radius: $border-radius - 1px;
      display: flex;
      justify-content: space-between;
      padding: $space-xs $space-s;

      &-closed {
        border-bottom: 0;
        border-bottom-left-radius: $border-radius - 1px;
        border-bottom-right-radius: $border-radius - 1px;
      }

      @for $index from 1 through length($variants) {
        .e-rule-builder__group_wrapper-variant-#{$index} > & {
          background-color: map.get($rule-styles, "variant#{$index}", "background");
          border-bottom-color: map.get($rule-styles, "variant#{$index}", "border");

          .e-btn {
            color: map.get($rule-styles, "variant#{$index}", "text");
            fill: map.get($rule-styles, "variant#{$index}", "text");

            &:focus {
              background: map.get($rule-styles, "variant#{$index}", "button-focus");
            }
          }
        }

        .e-rule-builder__group_wrapper-hover.e-rule-builder__group_wrapper-variant-#{$index} > & {
          background-color: map.get($rule-styles, "variant#{$index}", "hover-background");

          .e-btn {
            &:hover {
              background-color: map.get($rule-styles, "variant#{$index}", "button-hover");
              color: map.get($rule-styles, "variant#{$index}", "button-text-hover");
              fill: map.get($rule-styles, "variant#{$index}", "button-text-hover");
            }

            &:focus {
              background: map.get($rule-styles, "variant#{$index}", "button-hover");
            }
          }
        }
      }

      &_actions {
        opacity: 0;
        transition: opacity $transition-time;

        .e-rule-builder__group_wrapper-hover > .e-rule-builder__group_header > & {
          opacity: 1;
        }
      }
    }

    &_header_left {
      align-items: center;
      display: flex;
      gap: $space-s;
    }

    &_header_label:not(:focus)::after {
      @for $index from 1 through length($variants) {
        .e-rule-builder__group_wrapper-variant-#{$index} > .e-rule-builder__group_header & {
          color: map.get($rule-styles, "variant#{$index}", "text");
        }
      }
    }

    &_header_auto_label {
      font-weight: 500;

      @for $index from 1 through length($variants) {
        .e-rule-builder__group_wrapper-variant-#{$index} > .e-rule-builder__group_header & {
          color: map.get($rule-styles, "variant#{$index}", "text");
        }
      }
    }

    &_header_relation_icon {
      @for $index from 1 through length($variants) {
        .e-rule-builder__group_wrapper-variant-#{$index} > .e-rule-builder__group_header & {
          color: map.get($rule-styles, "variant#{$index}", "text");
        }
      }
    }

    &_content {
      &-hidden {
        display: none;
      }
    }

    &_list {
      padding: $space-m $space-s 0 $space-s;
    }

    &_actions {
      align-items: center;
      column-gap: $space-xs;
      display: flex;
      padding: $space-m $space-s;
      padding-top: 0;
    }

    &_empty_state {
      &_actions {
        align-items: center;
        display: flex;
        gap: $space-m;
        justify-content: center;
      }
    }
  }
}
