e-rulelist-group {
  > [slot="header"],
  > [slot="items"] {
    display: contents;
  }

  e-rulelist-group {
    display: contents;

    &::part(group) {
      border-bottom: 0;
      border-radius: 0;
    }

    &::part(header-wrapper) {
      border-radius: 0;
    }

    &:first-child::part(group) {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    &:first-child::part(header-wrapper) {
      border-top-left-radius: $border-radius - 1px;
      border-top-right-radius: $border-radius - 1px;
    }

    &:last-child::part(group) {
      /* stylelint-disable-next-line eds/tokens */
      border-bottom: 1px solid var(--rulelist-border, var(--token-ruleBuilder-variant1-border));
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  &::part(group) {
    background: var(--token-box-default-background);
    /* stylelint-disable-next-line eds/tokens */
    border: 1px solid var(--rulelist-border, var(--token-ruleBuilder-variant1-border));
    border-radius: $border-radius;
    display: grid;
    grid-column: 2 / 3;
  }

  &::part(relation) {
    color: var(--token-text-faint);
    display: block;
    flex: 0 0 max-content;
    padding: $space-xs $space-xs 0 0;
    text-align: right;
  }

  &::part(header-wrapper) {
    align-items: center;
    /* stylelint-disable-next-line eds/tokens */
    background: var(--rulelist-header-background, var(--token-ruleBuilder-variant1-background));
    /* stylelint-disable-next-line eds/tokens */
    border-bottom: 1px solid var(--rulelist-border, var(--token-ruleBuilder-variant1-background));
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    /* stylelint-disable-next-line eds/tokens */
    border-color: var(--rulelist-border, var(--token-ruleBuilder-variant1-background));
    border-top-left-radius: $border-radius - 1px;
    border-top-right-radius: $border-radius - 1px;
    display: flex;
    justify-content: space-between;
    padding: $space-xs $space-s;
  }

  &[closed]::part(header-wrapper) {
    border-bottom: 0;
    border-bottom-left-radius: $border-radius - 1px;
    border-bottom-right-radius: $border-radius - 1px;
  }

  &::part(header-actions-wrapper) {
    align-items: center;
    display: flex;
    gap: $space-2xs;
    min-height: var(--token-button-default-height);
  }

  &::part(header) {
    align-items: center;
    display: flex;
    gap: $space-xs;
  }

  &::part(header-actions) {
    align-items: center;
    display: flex;
    gap: $space-2xs;
  }

  &::part(togglable-container) {
    display: grid;
    grid-template-rows: 1fr;
  }

  &[closed]::part(togglable-container) {
    grid-template-rows: 0fr;
  }

  &[closed]::part(togglable-content),
  &[toggling]::part(togglable-content) {
    overflow: hidden;
  }

  &::part(togglable-content-frame) {
    display: flex;
    flex-direction: column;
    gap: $space-m;
    padding: $space-s;
  }

  &::part(items) {
    display: grid;
    grid-template-columns: min-content auto;
  }

  &[closed]::part(feedback),
  &[closed]::part(items) {
    margin-top: 1px;
  }

  &::part(footer-wrapper) {
    align-items: center;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    min-height: var(--token-button-default-height);
  }

  &::part(footer) {
    display: flex;
    grid-column: 2;
  }

  &::part(footer-actions) {
    align-items: center;
    display: flex;
    gap: $space-xs;
  }

  &::part(hidden) {
    display: none;
  }
}
