$e-device-push-assets: "https://assets.emarsys.net/ui/static/images/e-device";

.e {
  &-device-push {
    background-color: transparent;
    background-repeat: no-repeat;
    height: 100%;
    overflow: auto;
    position: relative;

    &.is-ios_phone_default {
      background-size: 375px 667px;

      &.is-lockscreen {
        background-image: url(#{$e-device-push-assets}/e-device-push-ios10-lockscreen.svg),
          url(#{$e-device-push-assets}/e-device-push-ios10-background.jpg);
      }

      &.is-alert {
        background-image: url(#{$e-device-push-assets}/e-device-push-ios10-homescreen.svg),
          url(#{$e-device-push-assets}/e-device-push-ios10-background.jpg);
      }

      &.is-noc {
        background-image: url(#{$e-device-push-assets}/e-device-push-ios10-noc.jpg);
      }

      &.is-expanded {
        background-image: url(#{$e-device-push-assets}/e-device-push-ios10-background-blurred.jpg);
        overflow: auto;
      }
    }

    &.is-android_phone_default {
      background-size: 360px 640px;

      &.is-lockscreen {
        background-image: url(#{$e-device-push-assets}/e-device-push-android-nougat-footer-lock.svg),
          url(#{$e-device-push-assets}/e-device-push-android-nougat-lockscreen.svg),
          url(#{$e-device-push-assets}/e-device-push-android-nougat-background.jpg);
      }

      &.is-alert {
        background-image: url(#{$e-device-push-assets}/e-device-push-android-nougat-footer-nav.svg),
          url(#{$e-device-push-assets}/e-device-push-android-nougat-homescreen.svg),
          url(#{$e-device-push-assets}/e-device-push-android-nougat-background.jpg);
      }

      &.is-noc,
      &.is-expanded {
        background-image: url(#{$e-device-push-assets}/e-device-push-android-nougat-footer-nav.svg),
          url(#{$e-device-push-assets}/e-device-push-android-nougat-notification-bar.svg),
          url(#{$e-device-push-assets}/e-device-push-android-nougat-homescreen.svg),
          url(#{$e-device-push-assets}/e-device-push-android-nougat-background.jpg);

        &::after {
          background-color: #000;
          bottom: 0;
          content: "";
          left: 0;
          opacity: 0.5;
          position: absolute;
          right: 0;
          top: 70px;
        }
      }

      &.is-expanded {
        background-image: url(#{$e-device-push-assets}/e-device-push-android-nougat-footer-lock.svg),
          url(#{$e-device-push-assets}/e-device-push-android-nougat-notification-bar.svg),
          url(#{$e-device-push-assets}/e-device-push-android-nougat-background.jpg);
      }
    }

    &__buttons {
      &.is-ios_phone_default {
        border-radius: 10px;
        overflow: hidden;
        position: absolute;
        top: calc(100% + 8px);
        width: 100%;

        &::before {
          background-image: url(#{$e-device-push-assets}/e-device-push-ios10-background-blurred.jpg);
          background-position: 425px 0;
          bottom: 0;
          content: "";
          filter: blur(20px);
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: -1;
        }
      }

      &.is-android_phone_default {
        display: flex;
        order: 2;
        padding: 0 14px 14px;
      }

      &.is-inbox {
        padding: 5px;

        &::before {
          display: none;
        }
      }
    }

    &__button {
      position: relative;
      z-index: 1;

      &.is-ios_phone_default {
        align-items: center;
        background-color: rgb(255 255 255 / 60%);
        border-bottom: 1px solid rgb(0 0 0 / 10%);
        display: flex;
        font-size: 13px;
        justify-content: center;
        line-height: 1.2;
        min-height: 45px;
        padding: 8px 0;
        text-align: center;

        &:first-of-type {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        &:last-of-type {
          border-bottom-color: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        .e-device-push__button_content {
          flex: 0 0 100%;
        }
      }

      &.is-android_phone_default {
        color: #1d882c;
        flex-grow: 1;
        font-size: 12px;
        min-width: 0;

        .CodeMirror {
          color: #1d882c;
        }
      }

      &.is-inbox {
        border: 1px solid #333;
        border-radius: 15px;
        margin-bottom: 5px;
      }
    }

    &__notification {
      font-size: 13px;
      position: absolute;
      top: 192px;
      z-index: 1;

      &.is-ios_phone_default {
        background-color: #abcec8;
        border-radius: 10px;
        left: 7px;
        padding-bottom: 7px;
        right: 7px;

        &.is-alert {
          top: 5px;
        }

        &.is-noc {
          top: 120px;
        }

        &.is-expanded {
          background: transparent;
          padding-bottom: 0;
          top: 156px;
        }

        &.is-inbox {
          background: transparent;
          border-radius: 0;
          left: 0;
          padding-top: 0;
          right: 0;
          top: 0;
        }

        &.is-editable {
          min-height: 111px;

          .e-device-push__headline {
            font-weight: bold;
          }

          .e-device-push__headline,
          .e-device-push__content {
            position: relative;

            &::before {
              color: rgb(0 0 0 / 30%);
              content: attr(fake-placeholder);
              font-style: italic;
              font-weight: normal;
              left: 14px;
              pointer-events: none;
              position: absolute;
              top: 0;
            }
          }
        }

        &.has-media:not(.is-expanded):not(.is-inbox) {
          min-height: 78px;
        }
      }

      &.is-android_phone_default {
        left: 0;
        right: 0;

        &.is-lockscreen {
          background-color: #f1f1f1;
        }

        &.is-expanded,
        &.is-noc,
        &.is-alert {
          background-color: var(--token-neutral-000);
        }

        &.is-alert {
          box-shadow: 0 25px 75px -30px #000;
          top: 0;
        }

        &.is-noc,
        &.is-expanded {
          top: 70px;
        }

        &.has-media:not(.is-expanded) {
          min-height: 83px;
        }

        &.is-expanded {
          display: flex;
          flex-direction: column;

          .e-device-push__media {
            order: 1;
          }
        }
      }
    }

    &__header {
      display: flex;

      &.is-ios_phone_default {
        align-items: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: #555;
        font-size: 11px;
        height: 32px;
        padding-left: 8px;
        padding-right: 14px;

        &.is-expanded {
          background: #fff;
          border-bottom: 1px solid #ccc;
          height: 44px;
          padding-left: 14px;
        }
      }

      &.is-android_phone_default {
        align-items: flex-end;
        height: 30px;
        padding: 0 14px 4px;
      }
    }

    &__icon {
      &.is-ios_phone_default {
        height: 20px;
        width: 20px;
      }

      &.is-android_phone_default {
        border-radius: 50%;
        max-height: 15px;
        position: relative;
        top: -3px;
        width: 15px;
      }
    }

    &__apptitle {
      @include text-overflow;

      &.is-ios_phone_default {
        padding-left: 10px;
        text-transform: uppercase;
      }

      &.is-android_phone_default {
        padding-left: 8px;
      }
    }

    &__timeago {
      &.is-ios_phone_default {
        margin-left: auto;
      }

      &.is-android_phone_default {
        margin-left: 5px;

        &::before {
          content: "\2022";
          margin-right: 5px;
        }
      }
    }

    &__headline {
      @include text-overflow;

      color: var(--token-neutral-999);
      display: block;
      font-size: 13px;
      font-weight: $text-bold-weight;
      letter-spacing: 0.1px;
      line-height: 16px;
      margin-left: 14px;
      margin-right: 8px;
      position: relative;

      &.is-inbox {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 8px;
      }
    }

    &__container {
      padding-top: 7px;

      &.is-expanded {
        background-color: #fff;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding-bottom: 8px;

        &.is-android_phone_default {
          margin-bottom: 8px;
        }
      }

      &.is-inbox {
        padding-top: 14px;
      }

      &.is-android_phone_default {
        &.is-expanded {
          margin-bottom: 0;
          padding-bottom: 0;
        }

        &:not(.is-expanded) {
          &.has-media {
            margin-right: 55px;
          }
        }
      }
    }

    &__content {
      color: #333;
      display: block;
      line-height: 16px;
      margin-left: 14px;
      margin-right: 8px;
      position: relative;

      &.is-android_phone_default {
        padding-bottom: 14px;
      }

      &.is-inbox {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__media {
      background-position: center;
      background-size: cover;

      &.is-expanded,
      &.is-inbox {
        padding-bottom: 42%;
      }

      &.is-ios_phone_default {
        &:not(.is-expanded):not(.is-inbox) {
          bottom: 10px;
          height: 32px;
          position: absolute;
          right: 10px;
          width: 32px;

          + .e-device-push {
            padding-bottom: 12px;
          }

          + .e-device-push__container {
            margin-right: 42px;
          }
        }
      }

      &.is-android_phone_default {
        &:not(.is-expanded) {
          height: 41px;
          position: absolute;
          right: 14px;
          top: 30px;
          width: 41px;
        }

        &.is-expanded {
          margin: 0 14px 14px;
        }
      }

      + .e-device-push {
        padding-top: 7px;
      }
    }

    &__more {
      color: #555;
      display: none;
      font-size: 11px;
      margin: 3px 8px 0 14px;

      &.is-ios_phone_default {
        &.is-lockscreen {
          display: block;
        }

        &.is-expanded {
          display: none;
        }
      }
    }

    vce-html-editor {
      display: block;
    }

    .CodeMirror,
    .e-device-push__editable {
      outline-offset: -3px;

      &:hover,
      &-focused {
        outline: 3px dashed rgba(#333, 0.8);
      }
    }

    &.highlight-editables .CodeMirror,
    &.highlight-editables .e-device-push__editable {
      outline: 3px dashed rgba(#333, 0.8);
    }
  }
}
