e-rulelist-item {
  display: contents;

  &::part(relation) {
    color: var(--token-text-faint);
    display: block;
    flex: 0 0 max-content;
    padding: $space-xs $space-xs 0 0;
    text-align: right;
  }

  &::part(item) {
    background: var(--token-ruleBuilder-variant1-background);
    border: 1px solid var(--token-ruleBuilder-variant1-border);
    border-bottom: 0;
    border-radius: 0;
    display: grid;
    grid-column: 2 / 3;
  }

  &:first-child::part(item) {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &:last-child::part(item) {
    border-bottom: 1px solid var(--token-ruleBuilder-variant1-border);
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  &::part(item-summary) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: var(--token-button-default-height);
    padding: $space-xs $space-s;
  }

  &::part(header-wrapper) {
    align-items: center;
    border-bottom: 1px solid var(--token-ruleBuilder-variant1-border);
    display: flex;
    gap: $space-m;
    justify-content: space-between;
    padding: $space-xs $space-s;
  }

  &::part(content) {
    display: block;
    padding: $space-xs $space-s;
  }

  &::part(footer-wrapper) {
    border-top: 1px solid var(--token-ruleBuilder-variant1-border);
    padding: $space-xs $space-s;
  }

  &::part(footer-actions) {
    align-items: center;
    display: flex;
    gap: $space-xs;
  }

  &::part(summary-actions) {
    align-items: center;
    display: flex;
    gap: $space-2xs;
  }

  &::part(hidden) {
    display: none;
  }
}
