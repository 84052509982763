.e {
  &-helperlink {
    @include has-inline-focus-state;

    display: inline-block;
    height: 18px;
    line-height: 18px;
    margin-left: $space-xs;

    &:hover {
      .e-icon {
        color: currentcolor;
      }
    }

    @include can-have-focus-style {
      &:focus {
        .e-icon {
          color: currentcolor;
        }
      }
    }

    &-nopadding {
      padding-left: 0;
    }

    &__icon {
      margin-top: -$space-3xs;

      .e-icon {
        color: currentcolor;
      }
    }

    &__text {
      font-size: 13px;
      margin-left: $space-3xs;
      text-decoration: underline;
    }
  }
}
