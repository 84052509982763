@import "../input/assets";

/* stylelint-disable-next-line scss/dollar-variable-colon-space-after */
$select-focus-transition:
  border $transition-time ease-in-out,
  box-shadow $transition-time ease-in-out;

e-multiselect {
  display: block;

  &[inline] {
    display: inline-block;
    vertical-align: middle;
  }

  &-wrapper {
    display: block;
  }
}

e-select-option {
  display: none;
}

.e-tagselector {
  @include e-input;
  @include e-input-style($styles: $input-styles);

  align-items: center;
  cursor: pointer;
  display: flex;
  height: auto;
  min-height: var(--token-input-default-height);
  min-width: size("base", 25);
  padding: 0 $space-4xl 0 $space-m;
  position: relative;

  &::after {
    color: var(--token-icon-default-text);
    content: var(--token-glyphs-angle-down);
    font-family: var(--token-icon-default-fontFamily);
    font-size: var(--token-icon-default-sizeSmall);
    position: absolute;
    right: 16px;
    top: 6px;
  }

  &__content {
    flex-grow: 1;
    padding: $space-3xs $space-3xs $space-2xs;
  }

  &__clear {
    @include has-focus-state($space-3xs);
    @include search-input-cancel;

    margin: 0;
    position: absolute;
    right: $space-2xl;
  }

  &-invalid {
    @include e-input-style("error", $input-styles);
  }
}
