.e {
  &-card {
    @include has-focus-state(-1px);

    background-color: var(--token-box-default-background);
    background-image: linear-gradient(
        45deg,
        var(--token-background-strong) 25%,
        transparent 25%,
        transparent 75%,
        var(--token-background-strong) 75%,
        var(--token-background-strong)
      ),
      linear-gradient(
        45deg,
        var(--token-background-strong) 25%,
        transparent 25%,
        transparent 75%,
        var(--token-background-strong) 75%,
        var(--token-background-strong)
      );
    background-position:
      0 0,
      10px 10px;
    background-size: 20px 20px;
    border: 1px solid var(--token-box-default-border);
    border-radius: $border-radius;
    box-shadow: var(--token-shadow-light);
    display: inline-block;
    margin: 0 $space-l $space-l 0;
    min-height: size("base", 22);
    overflow: hidden;
    position: relative;
    vertical-align: top;
    width: size("base", 30);

    &::before {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    &::after {
      background-color: var(--token-box-default-background);
      bottom: 0;
      box-shadow: 0 0 10px 8px var(--token-box-default-background);
      content: "";
      height: 0;
      left: 0;
      position: absolute;
      width: 100%;
    }

    &:focus {
      color: var(--token-box-default-text);
    }

    &:hover,
    &-hover,
    &:focus-within {
      .e-card__buttons,
      .e-card__action,
      .e-card__labels {
        opacity: 1;
      }

      .e-card__content-empty {
        background-color: var(--token-background-strong);

        .e-icon {
          color: var(--token-text-default);
        }
      }

      .e-card__lead {
        transform: translate(0, 0);
      }

      .e-card__title {
        max-height: 100%;
        transition: none;
      }
    }

    &-emptystate {
      background: var(--token-box-default-background);
      border: 2px dashed var(--token-box-default-border);
      box-shadow: none;
    }

    &-has_overlay {
      &:hover,
      &.e-card-hover,
      &:focus-within {
        .e-card__content {
          &::after {
            opacity: 1;
          }
        }
      }
    }

    &-has_input {
      overflow: visible;

      &::after {
        content: none;
      }

      .e-card__lead {
        box-shadow: none;
        overflow: visible;
      }
    }

    &-no_title {
      &::after {
        display: none;
      }

      .e-card__content {
        height: 100%;
      }
    }

    &-small {
      width: size("base", 23);
    }

    &-fluid {
      margin: 0;
      width: 100%;
    }

    &-auto_height {
      &::before {
        content: none;
      }

      &.e-card-emptystate {
        min-height: 0;

        .e-card__content {
          margin-bottom: 0;
        }
      }

      .e-card__content {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: size("base", 6);
        position: relative;
      }

      .e-card__preview {
        display: inline-block;
        position: static;
        transform: translate(0, 0);
      }
    }

    &-no_content {
      min-height: 0;
      transition: box-shadow $transition-time ease-in-out;
      user-select: none;

      &:hover,
      &:focus-within {
        border: 1px solid var(--token-box-default-border);
        box-shadow: var(--token-shadow-light);
      }

      &::before {
        display: none;
      }

      .e-card__lead {
        padding: $space-m;
        position: static;
        transform: translate(0, 0);
      }

      .e-card__title {
        max-height: none;
      }
    }

    &-no_hover {
      min-height: 0;

      .e-card__content {
        margin-bottom: 0;
        min-height: 0;
      }

      .e-card__lead {
        position: static;
        transform: translate(0, 0);
      }

      .e-card__title {
        max-height: none;
      }
    }

    &-no_checkerboard {
      background: var(--token-box-default-background);
    }

    &-active {
      border: 1px solid var(--token-box-highlight-background);
      box-shadow: var(--token-shadow-medium);
    }

    &__content {
      background-position: center;
      background-size: cover;
      height: calc(100% - #{size("base", 8)});
      left: 0;
      min-height: size("base", 16);
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;

      &-prevent_click::after {
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }

      .e-card-has_overlay & {
        &::after {
          background-color: color-mix(in srgb, var(--token-neutral-800) 66%, transparent);
          content: "";
          height: 100%;
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
          transition: opacity $transition-time ease-in-out;
          width: 100%;
        }
      }

      &-empty {
        align-items: center;
        background-color: var(--token-background-strong);
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: background $transition-time ease-in-out;

        .e-icon {
          color: var(--token-text-faint);
          transition: color $transition-time ease-in-out;
        }
      }
    }

    &__lead {
      @include word-wrap;

      background-color: var(--token-box-default-background);
      bottom: 0;
      box-shadow:
        0 -1px 0 0 var(--token-box-default-border),
        0 0 5px 5px var(--token-box-default-background) inset;
      display: flex;
      flex-direction: column;
      left: 0;
      max-height: 100%;
      min-height: size("base", 8);
      overflow: hidden;
      padding: $space-xs $space-m;
      position: absolute;
      transform: translate(0, 100%) translate(0, -#{size("base", 8)});
      transition: all $transition-time-long ease-out;
      width: 100%;
    }

    &__caption {
      display: flex;
      flex: 0 0 auto;
      font-size: 0;
      min-height: size("base", 6);
    }

    &__description {
      color: var(--token-text-faint);
      flex: 1 0 auto;
      padding-bottom: $space-4xl;
      padding-top: $space-s;
    }

    &__title {
      @include typography("body");

      align-self: center;
      color: var(--token-box-default-text);
      flex: 1 1 0%;
      max-height: size("base", 6);
      overflow: hidden;
      position: relative;
      transition: max-height 0s linear $transition-time-long;

      &-multiple {
        p {
          margin-bottom: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    input.e-card__input {
      // mediadb xeditable workaround
      color: var(--token-neutral-800);
      height: size("base", 8);
      left: 0;
      line-height: size("base", 8);
      margin: 0;
      padding: 0 $space-m;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &__badge {
      flex: 0 0 auto;
      padding: $space-xs 0 0 $space-m;

      .e-label {
        margin: 0;
      }
    }

    &__preview {
      border: 0;
      bottom: auto;
      left: 50%;
      margin: auto;
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      right: auto;
      top: 50%;
      transform: translate(-50%, -50%);
      vertical-align: top;

      &-stretch {
        bottom: 0;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        transform: translate(0, 0);
        width: 100%;
      }
    }

    &__buttons,
    &__labels {
      opacity: 0;
      position: absolute;
      top: $space-s;
      transition: opacity $transition-time ease-in-out;
      z-index: 1;
    }

    &__buttons {
      right: $space-s;

      .e-buttongroup {
        background: none;
      }

      .e-btn {
        @include e-btn-style("on_overlay", $btn-styles);
      }

      ~ .e-card__lead {
        max-height: calc(100% - #{size("base", 7)});
      }
    }

    &__labels {
      align-items: center;
      display: flex;
      height: var(--token-button-default-height);
      left: $space-s;
    }

    &__action {
      @include center-content(false, ".e-card__action");

      color: var(--token-text-light);
      height: calc(100% - #{size("base", 6)});
      left: 0;
      opacity: 0;
      padding: $space-4xl 0;
      position: absolute;
      text-align: center;
      top: 0;
      transition: opacity $transition-time ease-in-out;
      width: 100%;

      ~ .e-card__lead {
        max-height: calc(50% - #{size("base")});

        .e-card-small & {
          max-height: calc(50% - #{size("base", 2)});
        }
      }
    }

    &__footer {
      background-color: var(--token-box-default-background);
      bottom: 0;
      left: 0;
      padding: $space-s $space-m $space-m;
      position: absolute;
      width: 100%;

      e-dropdown {
        float: right;
      }
    }
  }
}
