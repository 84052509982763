@import "./assets";

$table-col-size: (
  "auto": 1%,
  "xxsmall": 3%,
  "xsmall": 5%,
  "small": 10%,
  "medium": 15%,
  "large": 20%,
  "xlarge": 25%,
);

[data-e-version="2"] {
  &.e {
    &-table {
      @include e-table;

      border-radius: $border-radius;

      &-overview {
        @include e-table-modifier("bordered");
        @include e-table-modifier("hover");
      }

      &-datagrid_overview {
        @include e-table-modifier("hover");

        border: 0;
        border-radius: $border-radius;
        margin: 0;

        .e-table__col-actions {
          width: 1px;
        }
      }

      &-datagrid_empty {
        border: 0;
        border-radius: $border-radius;

        tbody tr:hover td {
          background: transparent;
        }
      }

      &-hover {
        @include e-table-modifier("hover");
      }

      &-datagrid_loading {
        tbody tr:hover td {
          background: transparent;
        }
      }

      &-datagrid_hidesearch {
        border-top-width: 0;
      }

      &-datagrid_hidepagination {
        border-bottom-width: 0;
      }

      &-zebra {
        @include e-table-modifier("zebra");
      }

      &-withheader {
        @include e-table-modifier("withheader");
      }

      &-condensed {
        @include e-table-modifier("condensed");
      }

      &-bordered {
        @include e-table-modifier("bordered");
      }

      &-inner_bordered {
        @include e-table-modifier("inner_bordered");
      }

      &-inner {
        @include e-table-modifier("inner");
      }

      &-verticalcentered {
        @include e-table-modifier("verticalcentered");
      }

      &-baselinealigned {
        @include e-table-modifier("baselinealigned");
      }

      // scss-lint:disable QualifyingElement
      // e-table th is too strong
      th.e-table__sort {
        @include e-table-sort;

        &.e-table__col-right {
          padding-right: $space-s;
        }

        &-asc {
          @include e-table-sort-modifier("asc");
        }

        &-desc {
          @include e-table-sort-modifier("desc");
        }
      }

      .e-table__sort_button {
        @include has-focus-state(-$space-3xs);

        align-items: center;
        background: none;
        border: 0;
        bottom: $space-s;
        display: inline-flex;
        height: size("base", 3);
        justify-content: center;
        left: $space-2xs;
        padding: 0;
        position: absolute;
        width: size("base", 3);
      }

      .e-table__col-right .e-table__sort_button {
        bottom: 0;
        display: inline-flex;
        left: -6px;
        position: relative;
      }

      td {
        // e-table td is too strong
        &.e-table__col-breakall {
          @include break-all;
        }

        &.e-table__col-right {
          padding-left: $space-xs;
          padding-right: $space-s;

          &:last-child {
            padding-right: $space-s;
          }
        }
      }

      &-modal {
        @include e-table-modifier("modal");
      }

      &-fixed {
        @include e-table-modifier("fixed");
      }

      pre {
        margin: 0;
        white-space: pre-wrap;
      }
    }
  }

  * {
    &.e {
      &-table {
        &__col {
          &-actions {
            text-align: right;
            user-select: none;
            white-space: nowrap;
            width: size("base", 14);
          }

          &-filter {
            height: size("base", 8);
            padding: 0 $space-l;
          }

          &-nowrap {
            white-space: nowrap;
          }

          &-flexible {
            max-width: 0;
          }

          &-groupheader {
            background-color: var(--token-tableCell-default-backgroundActive);
            font-weight: 500;
          }

          &-right {
            text-align: right;
          }

          @each $class, $data in $table-col-size {
            &-#{$class} {
              width: $data;
            }
          }
        }

        &__col_alternate-content {
          color: var(--token-text-faint);
          font-size: map.get($typography, "small", "font-size");
        }

        &__row {
          td:first-child {
            position: relative;
          }

          &-alternate {
            td {
              background-color: var(--token-tableCell-alternate-background);
            }
          }

          &-active {
            td {
              background-color: var(--token-tableCell-selected-background);
              border-color: var(--token-tableCell-selected-border);
            }

            &:last-child:not(.e-datagrid__row) td {
              border-bottom: 0;
              box-shadow: 0 1px 0 0 var(--token-tableCell-selected-border);
            }

            &.e-datagrid__row:last-child td {
              border-bottom: 1px solid var(--token-tableCell-selected-border);
            }
          }

          &-checked {
            td {
              background-color: var(--token-tableCell-default-backgroundActive);
            }
          }

          &-disabled {
            td {
              opacity: 0.5;
            }
          }
        }

        &__content {
          &-without_border {
            th,
            td {
              border: 0;
            }
          }
        }

        &__settings {
          display: inline-block;
          line-height: size("base", 5);

          label {
            font-size: map.get($typography, "body", "font-size");
          }
        }

        &__action {
          @include has-focus-state(-$space-3xs);

          background: none;
          border: 0;
          cursor: pointer;
          padding: 0;

          &,
          &:visited,
          &:focus,
          &:hover {
            color: var(--token-box-default-text);
          }

          &-loading {
            display: grid;
            place-content: center;
            width: size("base", 5);
          }
        }

        &__filter {
          display: inline-block;
          margin-right: $space-s;
        }

        &__datatablefilter {
          padding-left: size("base", 34);
          position: relative;
        }

        &__advancedfilters {
          background-color: var(--token-tableCell-alternate-backgroundActive);
          height: 0;
          overflow: hidden;
          position: relative;
          top: $space-s;
          transition: height $transition-time ease;

          &-active {
            height: size("base", 8);
          }
        }

        &__thumbnail {
          background-position: center;
          background-size: cover;
          border: 1px solid var(--token-tableCell-default-border);
          height: size("base", 6);
          min-width: size("base", 6);
          position: relative;
          vertical-align: top;
          width: size("base", 6);

          &-empty {
            background-color: var(--token-neutral-000);
            background-image: linear-gradient(45deg, var(--token-neutral-200) 25%, transparent 25%),
              linear-gradient(-45deg, var(--token-neutral-200) 25%, transparent 25%),
              linear-gradient(45deg, transparent 75%, var(--token-neutral-200) 75%),
              linear-gradient(-45deg, transparent 75%, var(--token-neutral-200) 75%);
            background-position:
              0 0,
              0 12px,
              12px -12px,
              -12px 0;
            background-size: 24px 24px;
          }
        }
      }
    }
  }

  &.e-table-datagrid_hidepagination .e-table__row-active.e-datagrid__row:last-child td {
    &:first-child {
      border-bottom-left-radius: $border-radius - 1;
    }

    &:last-child {
      border-bottom-right-radius: $border-radius - 1;
    }
  }
}

.e-stretch .e-table[data-e-version="2"] {
  border-left-width: 0;
  border-radius: 0;
  border-right-width: 0;
  padding: $space-2xs;
}

.e-stretch.e-stretch-top .e-table[data-e-version="2"] {
  border-top-width: 0;
}

.e-stretch.e-stretch-bottom .e-table[data-e-version="2"] {
  border-bottom-width: 0;
}
