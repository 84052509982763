e-select {
  cursor: pointer;
  display: block;
  position: relative;

  &[size] {
    display: inline-block;
  }

  &[inline]:not([inline="false"]) {
    display: inline-block;
  }

  &:where([borderless][focused]) {
    .e-selectnew {
      @include glow(var(--token-highlight-default));

      border: 1px solid var(--token-highlight-default);
    }
  }

  &:where([clear-visible]) :where(.e-selectnew__value) {
    padding-right: $space-m;
  }
}

.e-selectnew {
  @include e-select($hasArrow: false);

  &-readonly {
    cursor: initial;
  }

  &-native {
    background: transparent;
    border-color: transparent;
    height: var(--token-input-default-height);
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__icon {
    height: 100%;
    position: absolute;
    right: $space-s;
    top: 0;

    .e-icon {
      font-size: var(--token-icon-default-sizeSmall);
      height: var(--token-icon-default-sizeSmall);
      margin: auto;
      width: var(--token-icon-default-sizeSmall);
    }
  }

  &__value,
  &__placeholder {
    @include text-overflow;
  }
}

:where(e-select[focused]:not([borderless])) .e-selectnew {
  $style: map.get($input-styles, "default");

  @include e-input-state($style: $style, $state: "focus");

  outline: 0;
}

:where(e-select[focused]:not([borderless])) .e-selectnew-native {
  background: transparent;
}

:where(e-select[focused]) .e-selectnew-error {
  $style: map.get($input-styles, "error");

  @include e-input-state($style: $style, $state: "focus");

  outline: 0;
}

:where(e-select[focused]:not([borderless])) .e-selectnew-error {
  background: transparent;
}

:where(e-select[borderless]) .e-selectnew__placeholder {
  color: var(--token-text-highlight);
}
