@import "../input/assets";

$select-text-size: map.get($typography, "body", "font-size") !default;
/* stylelint-disable-next-line scss/dollar-variable-colon-space-after */
$select-focus-transition:
  border $transition-time ease-in-out,
  box-shadow $transition-time ease-in-out !default;

@mixin e-select($hasArrow: true) {
  @include e-input;

  @if $hasArrow {
    @include e-input-style(
      $styles: $input-styles,
      $background: (
        "image": url("horizon/e-angle-down.svg?token=morningHorizon,text-default"),
        "position": calc(100% - #{$space-m}) 50%,
        "repeat": no-repeat,
        "size": size("icon", "small"),
      )
    );

    @include flipper-on("theme-eveningHorizon") {
      @include e-input-style(
        $styles: $input-styles,
        $background: (
          "image": url("horizon/e-angle-down.svg?token=eveningHorizon,text-default"),
          "position": calc(100% - #{$space-m}) 50%,
          "repeat": no-repeat,
          "size": size("icon", "small"),
        )
      );
    }
  } @else {
    @include e-input-style($styles: $input-styles);
  }

  align-items: center;
  appearance: none;
  display: flex;
  padding-right: $space-3xl;

  &-error {
    @if $hasArrow {
      @include e-input-style(
        "error",
        $background: (
          "image": url("horizon/e-angle-down.svg?token=morningHorizon,text-default"),
          "position": calc(100% - #{$space-m}) 50%,
          "repeat": no-repeat,
          "size": size("icon", "small")
        )
      );

      @include flipper-on("theme-eveningHorizon") {
        @include e-input-style(
          "error",
          $background: (
            "image": url("horizon/e-angle-down.svg?token=eveningHorizon,text-default"),
            "position": calc(100% - #{$space-m}) 50%,
            "repeat": no-repeat,
            "size": size("icon", "small")
          )
        );
      }
    } @else {
      @include e-input-style("error", $input-styles);
    }
  }

  &-borderless {
    @include has-focus-state($style: solid);

    background-color: transparent;
    border: 1px solid transparent;
    color: var(--token-highlight-default);
    font-weight: bold;

    @if $hasArrow {
      background-image: url("horizon/e-angle-down.svg?token=morningHorizon,highlight-default");

      @include flipper-on("theme-eveningHorizon") {
        background-image: url("horizon/e-angle-down.svg?token=eveningHorizon,highlight-default");
      }
    } @else {
      background-image: none;
    }

    &:where(:focus) {
      border: 1px solid transparent;
    }

    &:where(:active) {
      border: 1px solid var(--token-highlight-default);
    }
  }

  @each $class, $data in map.get($map: $sizes, $key: "input") {
    &-#{$class} {
      @include e-input-size($class);
    }
  }

  &__clear {
    @include has-focus-state($space-3xs);
    @include search-input-cancel;

    border: 0;
    color: var(--token-text-default);
    margin: 0;
    position: absolute;
    right: $space-2xl;
    top: 50%;
    transform: translateY(-50%);
  }
}

.e-select {
  @include e-select;

  &-inline {
    display: inline-block;
    width: auto;
  }

  &-autowidth {
    display: inline-block;
    min-width: map.get($sizes, "input", "large") + px;
    width: auto;
  }

  &-pager {
    margin: 0 $space-xs;
  }

  &:not(.e-select__select2) {
    padding-right: $space-3xl;
  }
}

:where(e-select) .e-selectnew {
  @include e-select($hasArrow: false);
}

:where(e-select[focused]:not([borderless])) .e-select {
  $style: map.get($input-styles, "default");

  @include e-input-state(
    $style: $style,
    $state: "focus",
    $background: (
      "image": url("horizon/e-angle-down.svg?token=morningHorizon,text-default"),
      "position": calc(100% - #{$space-m}) 50%,
      "repeat": no-repeat,
      "size": size("icon", "small"),
    )
  );

  @include flipper-on("theme-eveningHorizon") {
    @include e-input-state(
      $style: $style,
      $state: "focus",
      $background: (
        "image": url("horizon/e-angle-down.svg?token=eveningHorizon,text-default"),
        "position": calc(100% - #{$space-m}) 50%,
        "repeat": no-repeat,
        "size": size("icon", "small"),
      )
    );
  }

  outline: 0;
}

:where(e-select[focused]) .e-select-error {
  $style: map.get($input-styles, "error");

  @include e-input-state(
    $style: $style,
    $state: "focus",
    $background: (
      "image": url("horizon/e-angle-down.svg?token=morningHorizon,text-default"),
      "position": calc(100% - #{$space-m}) 50%,
      "repeat": no-repeat,
      "size": size("icon", "small"),
    )
  );

  @include flipper-on("theme-eveningHorizon") {
    @include e-input-state(
      $style: $style,
      $state: "focus",
      $background: (
        "image": url("horizon/e-angle-down.svg?token=eveningHorizon,text-default"),
        "position": calc(100% - #{$space-m}) 50%,
        "repeat": no-repeat,
        "size": size("icon", "small"),
      )
    );
  }

  outline: 0;
}

@mixin e-select-size($_size: "large") {
  display: inline-block;
  width: size("select", $_size);
}

@mixin e-select-old {
  @include typography("body");
  @include text-overflow;

  appearance: none;
  background-color: var(--token-background-default);
  background-image: url("horizon/e-angle-down.svg?token=morningHorizon,text-default");
  background-position: calc(100% - 8px) 50%;
  background-repeat: no-repeat;
  background-size: size("icon", "small");
  border: 1px solid var(--token-border-default);
  border-radius: 0;
  color: var(--token-text-default);
  display: block;
  font-size: $select-text-size;
  height: size("base", 5);
  line-height: $text-lineheight;
  padding: 0 $space-l 0 $space-s;
  transition: $select-focus-transition;
  width: 100%;

  @include flipper-on("theme-eveningHorizon") {
    background-image: url("horizon/e-angle-down.svg?token=eveningHorizon,text-default");
  }

  &:not(.e-select__select2) {
    padding-right: $space-xl;
  }

  &::-ms-expand {
    display: none;
  }
}

e-select {
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: middle;

  e-spinner {
    align-items: center;
    background: color-mix(in srgb, var(--token-overlay-default) 70%, transparent);
    border-radius: $border-radius-small;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  &:focus,
  &[opened="true"] {
    outline: none;

    select:not([disabled]) {
      border-color: var(--token-border-default);
      box-shadow: none;

      &.e-select-error {
        border: 1px solid var(--token-error-default);
        box-shadow: none;
      }
    }
  }

  .e-select {
    vertical-align: text-top;
  }

  &.e-select-old[tabindex]::after {
    background: var(--token-background-default);
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &[inline]::after {
    display: none;
  }

  &[borderless] {
    .e-select {
      border-color: transparent;
      box-shadow: none;
      cursor: pointer;
      transition: background-color $transition-time ease-in-out;

      &:active,
      &:focus {
        border-color: transparent;
        box-shadow: none;
      }
    }

    &:hover,
    &[focused] {
      .e-select {
        background-color: var(--token-neutral-100);
      }
    }

    &[focused] {
      .e-select {
        border-color: var(--token-highlight-default);
      }
    }
  }
}

.e-select2popup {
  position: absolute;
  text-align: left;
  top: -99999px;
  z-index: z("flying");

  &__content {
    background: var(--token-background-default);
    border: 1px solid var(--token-highlight-default);
    box-shadow: var(--token-shadow-medium);
    display: block;
    margin: -1px 0;
  }
}

.e-select2options {
  line-height: $text-lineheight;
  max-height: size("base", 25);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  &__option {
    @include word-wrap;

    cursor: pointer;
    padding: $space-2xs $space-xs $space-2xs;

    &-active {
      background: var(--token-highlight-400);
      color: var(--token-neutral-000);
    }

    &-selected {
      background: var(--token-highlight-default);
      color: var(--token-neutral-000);
    }

    &-disabled {
      background: transparent;
      color: var(--token-text-faint);
      cursor: default;
    }

    &-group {
      color: var(--token-text-default);
      font-weight: 500;
    }

    &-has_group {
      padding-left: $space-l;
    }
  }

  &__noresult {
    background: var(--token-neutral-200);
    padding: $space-2xs $space-xs $space-2xs;
  }
}
