@import "styles";

/* stylelint-disable-next-line scss/dollar-variable-colon-space-after */
$btn-hover-transition:
  background $transition-time ease-in-out,
  border $transition-time ease-in-out,
  box-shadow $transition-time ease-in-out,
  color $transition-time ease-in-out,
  opacity $transition-time ease-in-out !default;

@mixin e-btn-disabled {
  cursor: not-allowed !important; /* stylelint-disable-line declaration-no-important */
  opacity: 0.4;
}

@mixin e-btn {
  @include typography("body");

  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-block;
  height: var(--token-button-default-height);
  line-height: calc(var(--token-button-default-height) - 2px);
  min-width: size("base", 9);
  padding: 0 $space-m;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: $btn-hover-transition;
  vertical-align: middle;
  white-space: nowrap;

  .e-icon {
    transition: color $transition-time ease-in-out;
  }

  &:active,
  &:visited,
  &:focus,
  &:hover {
    outline: 0;
  }

  @include e-btn-style("default", $btn-styles);

  &-disabled,
  &:disabled,
  &[disabled] {
    @include e-btn-disabled;
  }
}

@mixin e-btn-style($style-name: "default", $style-list: $btn-styles) {
  $style: map.get($style-list, $style-name);

  @include has-focus-state($style: solid, $color: map.get($style, "default-focus-color"));
  @include e-btn-state($style, $state: null);

  font-weight: map.get($style, "font-weight");

  &:visited {
    @include e-btn-state($style, $state: null);
  }

  &:hover {
    @include e-btn-state($style, $state: "hover");
  }

  &.e-btn-active,
  &:active {
    @include has-focus-state($style: solid, $color: map.get($style, "active-focus-color"));

    &,
    &:visited {
      @include e-btn-state($style, $state: "active");
    }
  }

  &.e-btn-active {
    &:hover {
      @include e-btn-state($style, $state: "active-hover");
    }
  }

  &.e-btn-disabled,
  &:disabled,
  &[disabled] {
    &,
    &:visited,
    &:hover,
    &:focus,
    &:active {
      @include e-btn-state($style, $state: null, $disabled: true);
    }

    &.e-btn-active {
      &,
      &:focus,
      &:hover,
      &:visited {
        @include e-btn-state($style, $state: "active", $disabled: true);
      }
    }
  }
}

@mixin e-btn-state($style, $state: null, $disabled: false) {
  $has-state: $state != null;
  $prefix: if($has-state, #{$state + "-"}, "");

  background-color: map.get($style, #{$prefix + "background"});
  border-color: map.get($style, #{$prefix + "border-color"});
  color: map.get($style, #{$prefix + "color"});

  .e-icon {
    color: map.get($style, #{$prefix + "color"});
  }
}

@mixin e-btn-onlyicon {
  min-width: 0;
  padding: 0;
  text-align: center;
  width: size("base", 5);
}
