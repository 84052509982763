.e {
  &-charttooltip {
    color: var(--token-box-strong-text);
    margin: -$space-xs;

    &__header {
      @include typography("body");
      @include word-wrap;

      background-color: var(--token-box-alternate-background);
      line-height: size("base", 2);
      padding: $space-xs;
    }

    &__table {
      background: var(--token-box-strong-background);
      border: $space-xs solid transparent;
    }

    &__row {
      border-bottom: 1px solid var(--token-box-strong-border);
      margin-bottom: $space-2xs;
      padding-bottom: $space-2xs;
      white-space: normal;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    &__cell {
      display: table;
      table-layout: fixed;
      width: 100%;
    }

    &__name {
      @include typography("body");

      display: table-cell;
    }

    &__value {
      @include typography("body");

      display: table-cell;
      font-weight: normal;
      text-align: right;
      vertical-align: middle;
    }
  }
}
