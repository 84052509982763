e-ring {
  display: inline-block;
  position: relative;

  e-circle {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;

    &:first-child {
      position: static;
    }

    svg {
      vertical-align: top;
    }
  }
}

.e-ring {
  &__path {
    &-stroke {
      stroke: var(--token-background-default);
    }
  }
}

.e-ringchart {
  align-items: center;
  display: flex;

  &-has_footer {
    flex-direction: column;
    text-align: center;

    .e-ringchart__legend {
      padding-left: 0;
      padding-top: $space-xs;
    }
  }

  &__chart {
    flex: 0 0 auto;
    font-size: 0;
    position: relative;
  }

  &__inner {
    @include typography("body");

    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__legend {
    flex: 0 1 auto;
    padding-left: $space-s;
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__value {
    @include typography("body");

    margin-bottom: $space-xs;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:last-child {
      margin-bottom: 0;
    }

    small {
      color: var(--token-neutral-800);
      padding-left: $space-2xs;
    }
  }

  &__background,
  &__fill {
    fill: none;
  }

  &__fill {
    transform-origin: 50% 50%;
  }
}
