@import "../input/assets";

e-calendar {
  &:focus {
    outline: none;
  }
}

@mixin e-datepicker {
  background-color: var(--token-background-default);
  border: 1px solid var(--token-border-default);
  margin: 0 auto $space-m;
  position: relative;
  text-align: center;
  user-select: none;
  width: size("base", 31);

  table {
    @include typography("body");

    margin: auto;
    margin-bottom: $space-xs;
    table-layout: fixed;
    text-align: center;
    width: 100%;
  }

  thead {
    th {
      font-weight: 500;
      height: size("base", 5);
      text-transform: uppercase;
      vertical-align: middle;
    }
  }
}

@mixin e-datepicker-action($dir: false) {
  cursor: pointer;
  position: absolute;
  top: $space-2xs;

  @if $dir == "prev" {
    left: $space-2xs;
  }

  @if $dir == "next" {
    right: $space-2xs;
  }
}

@mixin e-datepicker-title {
  border-bottom: 1px solid var(--token-border-default);
  display: block;
  height: size("base", 5);
  line-height: size("base", 5);
  padding: 0 $space-xl;
  text-align: center;
  width: size("base", 31);
}

@mixin e-datepicker-day {
  cursor: pointer;
  height: size("base", 4);
  line-height: size("base", 4);
  transition: background $transition-time ease-in-out;
  width: size("base", 4);

  &:hover {
    background-color: var(--token-background-strong);
  }
}

@mixin e-datepicker-day-modifier($modifier: "default") {
  @if $modifier == "current" {
    background-color: var(--token-highlight-400);
    color: var(--token-text-light);

    &:hover {
      background-color: var(--token-background-default);
    }
  }

  @if $modifier == "alternate" {
    color: var(--token-neutral-300);
  }
}

.e {
  &-datepicker {
    @include e-datepicker;

    &-borderless {
      border: 0;
      text-align: left;
    }

    &__prev {
      @include e-datepicker-action("prev");
    }

    &__next {
      @include e-datepicker-action("next");
    }

    &__day {
      @include e-datepicker-day;

      &-alternate {
        @include e-datepicker-day-modifier("alternate");
      }

      &-current {
        @include e-datepicker-day-modifier("current");
      }
    }

    &__title {
      @include e-datepicker-title;
    }

    &-weeks {
      overflow: hidden;
      width: size("base", 32);

      .e-datepicker {
        &__day {
          height: size("base", 4);
          width: size("base", 4);
        }

        &__title {
          width: size("base", 32);
        }
      }
    }
  }
}

.e-datepicker-new {
  cursor: pointer;
  line-height: size-unitless("base", 5) - 2 + px;
  outline: 0;
  position: relative;
  user-select: none;

  .e-icon {
    font-size: var(--token-icon-default-sizeSmall);
    height: var(--token-icon-default-sizeSmall);
    margin-right: $space-xs;
    margin-top: -$space-2xs;
    width: var(--token-icon-default-sizeSmall);
  }

  &.e-input-disabled .e-datepicker-new__clear {
    display: none;
  }

  &.e-input-disabled {
    @include e-input-modifier("disabled");

    &:read-only:focus {
      border-color: var(--token-border-default);
      box-shadow: none;
    }
  }

  &:read-only:focus {
    @include e-input-modifier("focus");
  }

  &.e-input-error {
    @include e-input-modifier("error");

    e-icon {
      svg {
        fill: var(--token-text-error);
      }
    }

    .e-datepicker-new__clear {
      background: none;
      display: flex;

      &::before {
        color: var(--token-text-faint);
        content: var(--token-glyphs-close-circle);
        font-family: var(--token-icon-default-fontFamily);
        font-size: var(--token-icon-default-sizeSmall);
        line-height: var(--token-icon-default-sizeSmall);
      }
    }
  }

  &-inline {
    min-width: size("base", 25);
    width: auto;
  }

  &__close {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__clear {
    @include search-input-cancel;

    border: 0;
    position: absolute;
    right: 12px;
    top: 11px;
  }
}
