e-layout-back-button {
  @include e-btn;

  border-radius: $border-radius;
  left: 0;
  min-width: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  transition:
    box-shadow 0.3s,
    width 0.3s;
  width: size("base", 4);

  &:hover,
  &:active:hover {
    border: 1px solid var(--token-border-default);
    box-shadow: var(--token-shadow-light);
  }

  &::before,
  &::after {
    content: "";
    display: inline-block;
    width: $space-m;
  }

  &::before {
    width: $space-xl;
  }

  &:focus-within,
  a:focus > &,
  button:focus > & {
    @include can-have-focus-style {
      @include has-focus-style;
    }
  }
}

.e-layout-back-button {
  &__link {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__icon {
    left: $space-xs;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
