.e {
  &-iconmenu {
    float: right;
    font-size: 0;

    &__item {
      display: inline-block;
      font-size: 0;
      margin-left: $space-3xs;
      vertical-align: middle;

      &:hover {
        .e-iconmenu__name {
          background-color: var(--token-shellbar-actionBackgroundHover);
        }
      }

      .e-dropdown__inner {
        margin-top: 0;
      }
    }

    &__name {
      align-items: center;
      background-color: var(--token-shellbar-actionBackground);
      cursor: pointer;
      display: flex;
      height: size("shellbar", "horizonHeight");
      justify-content: center;
      position: relative;
      transition: $menu-hover-transition;
      width: size("shellbar", "horizonHeight");

      &:hover {
        transition: none;
      }

      + .e-dropdown__inner {
        &::after {
          right: $space-l;
        }
      }

      .e-icon {
        color: var(--token-neutral-500);
        font-size: var(--token-icon-default-size);
        height: var(--token-icon-default-size);
        width: var(--token-icon-default-size);
      }
    }

    &__circle {
      background-color: var(--token-neutral-500);
      border-radius: 100%;
      display: inline-block;
      line-height: 1;
      overflow: hidden;
      vertical-align: middle;

      .e-icon {
        font-size: var(--token-icon-default-size);
        height: var(--token-icon-default-size);
        width: var(--token-icon-default-size);
      }
    }

    &__profile {
      &__header {
        border-bottom: 1px solid var(--token-border-default);
        padding: $space-m;

        a {
          color: inherit;
        }
      }

      .e-icon {
        color: var(--token-neutral-500);
      }

      &__time {
        color: var(--token-text-faint);
        font-size: typo("small", "size");
        white-space: nowrap;
      }
    }
  }

  &-search {
    font-size: typo("body", "size");
    padding: $space-m;
    width: size("base", 30);
  }
}
