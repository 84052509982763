@import "../input/assets";

.e-datetime {
  position: relative;

  &-inline {
    display: inline-block;
    min-width: 200px;
    vertical-align: middle;
  }

  &__input {
    @include has-focus-state;

    align-items: center;
    display: flex;
  }

  &__footer {
    display: flex;
    gap: $space-s;
    justify-content: flex-end;
    padding-top: $space-m;
  }

  &__dropdown_icon {
    height: 100%;
    position: absolute;
    right: $space-s;
    top: 0;

    .e-icon {
      font-size: var(--token-icon-default-sizeSmall);
      height: var(--token-icon-default-sizeSmall);
      margin: auto;
      width: var(--token-icon-default-sizeSmall);
    }
  }

  &__clear {
    @include has-focus-state($space-3xs);
    @include search-input-cancel;

    border: 0;
    color: var(--token-text-default);
    margin: 0;
    position: absolute;
    right: $space-xl;
    top: 50%;
    transform: translateY(-50%);

    &-error {
      &::before {
        color: var(--token-text-faint);
      }
    }
  }

  &__presets {
    display: flex;
    flex-direction: column;
  }

  &__presets_legend {
    display: contents;
  }

  &__preset {
    border: 1px solid var(--token-border-default);
    border-radius: $border-radius;
    flex-basis: 100%;
  }

  &-picker {
    &__inputs {
      display: flex;
      gap: 8px;
    }

    &__input {
      flex: 1 1 auto;
    }

    &__hint {
      color: var(--token-text-faint);
      font-size: map.get($typography, "small", "font-size");
      margin-bottom: $space-m;
    }
  }
}

e-datetime-picker {
  min-width: auto;
}
