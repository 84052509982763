.e {
  &-panel {
    @include clearfix;

    position: relative;

    &-box {
      @include e-box;

      box-shadow: none;
      padding-bottom: $space-m;
    }

    &-col {
      // Trends popup
      border: 1px solid var(--token-border-default);
      border-right: 0;
      margin: -1px 0 0;
      padding: $space-m;

      &:first-child {
        border-left: 0;
      }
    }

    &-bordered {
      // Advanced scheduling
      border-right: 1px solid var(--token-border-default);
      padding: $space-m;

      &:last-child {
        border-right: 0;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      height: size("base", 8);
      justify-content: center;
      margin-bottom: $space-4xl;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__title {
      @include text-overflow;
      @include typography("body");

      margin-bottom: $space-xs;
      padding-right: $space-m;
      position: relative;

      &-multiline {
        overflow: visible;
        white-space: normal;
      }

      .e-btn-small {
        float: right;
        margin-left: $space-xs;
        margin-top: -$space-xs;
      }
    }

    &__icon {
      float: left;
      margin-right: $space-m;
      position: relative;

      .e-icon {
        color: var(--token-text-light);
        margin: $space-s;
      }
    }

    &__value {
      @include typography("heading3");

      position: relative;
    }

    &__postfix {
      padding-left: $space-2xs;
    }

    &__text {
      @include typography("small");

      margin-top: $space-xs;

      &-twolines {
        height: size("base", 5);
        overflow: hidden;
      }
    }

    &__tooltip {
      position: absolute;
      right: 10px;
      top: 7px;
      z-index: z("default");
    }
  }
}

.e-chart-si {
  .e-field {
    .e-panel__icon {
      margin-bottom: 0;
    }
  }

  .e-panel {
    &-box {
      border: 1px solid var(--token-border-default);
      margin-bottom: $space-l;
    }

    &__tooltip {
      position: absolute;
      right: $space-xs;
      top: $space-xs;
    }
  }
}
