// scss-lint:disable SelectorDepth

.e {
  &-daterangepicker {
    @include e-input-old;

    cursor: pointer;
    line-height: size-unitless("base", 5) - 2 + px;
    padding-right: $space-xl;
    position: relative;
    user-select: none;

    &::after {
      color: var(--token-icon-default-text);
      content: var(--token-glyphs-caret-down);
      font-family: var(--token-icon-default-fontFamily);
      font-size: var(--token-icon-default-sizeSmall);
      height: size("base", 2);
      position: absolute;
      right: $space-xs;
      top: 0;
      width: size("base", 2);
    }

    &-static {
      border-color: transparent;
      cursor: inherit;
      padding-right: inherit;
      user-select: initial;

      &::after {
        display: none;
      }
    }

    .opened & {
      border: 1px solid var(--token-border-default);
      box-shadow: var(--token-shadow-medium);

      &::before {
        background-color: var(--token-background-default);
        bottom: -$space-2xs;
        content: "";
        height: 5px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 503;
      }
    }

    &__icon {
      height: size("base", 2);
      margin-top: -$space-2xs;
      width: size("base", 2);
    }

    &__item {
      padding: 0 $space-xs;
    }

    &__container {
      @include e-box;

      background-color: var(--token-background-default);
      border: 1px solid var(--token-border-default);
      box-shadow: var(--token-shadow-medium);
      display: none;
      margin-left: -$space-xl;
      margin-top: $space-xs;
      padding-bottom: $space-m;
      position: absolute;
      width: size("base", 103);
      z-index: z("dropdown");
    }

    &__content {
      @include e-box;

      background-color: var(--token-background-default);
      display: none;
      padding-bottom: $space-m;
      width: size("base", 103);
    }

    &__ranges {
      ul {
        border: 1px solid var(--token-border-default);
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          border-bottom: 1px solid var(--token-border-default);
          cursor: pointer;
          padding: $space-xs $space-s;

          &:hover {
            background-color: var(--token-background-strong);
          }

          &.active {
            background-color: var(--token-highlight-default);
            color: var(--token-text-light);
            position: relative;

            &::after {
              color: var(--token-text-light);
              content: var(--token-glyphs-checkmark);
              font-family: var(--token-icon-default-fontFamily);
              font-size: var(--token-icon-default-size);
              height: size("base", 3);
              position: absolute;
              right: $space-s;
              top: $space-m;
              width: size("base", 3);
            }
          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }

    &__calendars {
      margin-top: $space-l;

      thead {
        tr:first-child {
          th {
            &:first-child,
            &:last-child {
              cursor: pointer;
              opacity: 0.3;

              &.prev,
              &.next {
                opacity: 1;
              }

              &::after {
                content: "";
                display: inline-block;
                height: size("base", 3);
                position: absolute;
                top: $space-3xs;
                width: size("base", 3);
              }
            }

            &:last-child {
              @include e-datepicker-action("next");

              // We've started the logging for .e-daterangepicker__calendars and .e-daterangepicker__calendar
              &::after {
                background: url("horizon/e-move-right.svg?token=morningHorizon,text-default")
                  no-repeat center;
                right: 0;

                @include flipper-on("theme-eveningHorizon") {
                  background: url("horizon/e-move-right.svg?token=eveningHorizon,text-default")
                    no-repeat center;
                }
              }
            }

            &:first-child {
              @include e-datepicker-action("prev");

              // We've started the logging for .e-daterangepicker__calendars and .e-daterangepicker__calendar
              &::after {
                background: url("horizon/e-move-left.svg?token=morningHorizon,text-default")
                  no-repeat center;
                left: 0;

                @include flipper-on("theme-eveningHorizon") {
                  background: url("horizon/e-move-left.svg?token=eveningHorizon,text-default")
                    no-repeat center;
                }
              }
            }
          }
        }
      }

      .month {
        @include e-datepicker-title;
      }

      td {
        @include e-datepicker-day;

        &:hover {
          background-color: var(--token-background-strong);
        }

        &.added-padding {
          width: 0;
        }

        &.off,
        &.disabled {
          @include e-datepicker-day-modifier("alternate");
        }

        &.in-range,
        &.start-date,
        &.end-date {
          @include e-datepicker-day-modifier("current");
        }
      }
    }

    &__calendar {
      thead {
        tr {
          &:first-child {
            th {
              &:first-child {
                opacity: 1;
                position: static;

                &::after {
                  display: none;
                }
              }

              &:last-child {
                opacity: 1;
                position: static;

                &::after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
