.e {
  &-floating-panel {
    background-color: var(--token-box-default-background);
    border: 1px solid var(--token-box-default-border);
    border-radius: $border-radius;
    bottom: $space-m;
    box-shadow: var(--token-shadow-medium);
    display: flex;
    flex-direction: column;
    left: #{size-unitless("menu", "width") + $space-m};
    overflow: auto;
    position: fixed;
    top: #{size-unitless("shellbar", "horizonHeight") + $space-m};
    width: size("base", 50);
    z-index: z("modal");

    &__align-right {
      left: auto;
      right: $space-m;
    }
  }
}
