@import "./assets-old";
@import "./assets";

.dataTables_wrapper {
  position: relative;
}

.dataTable {
  thead {
    td {
      height: size("base", 8);
    }
  }

  tfoot {
    td {
      height: size("base", 8);
    }
  }

  .right {
    text-align: right;
    user-select: none;
    white-space: nowrap;
    width: size("base", 14);
  }
}

.dataTable[data-e-version="2"] {
  .sorting {
    @include e-table-sort;
  }

  .sorting_asc {
    @include e-table-sort;
    @include e-table-sort-modifier("asc");
  }

  .sorting_desc {
    @include e-table-sort;
    @include e-table-sort-modifier("desc");
  }
}

.dataTable:not([data-e-version="2"]) {
  .sorting {
    @include e-table-sort-old;
  }

  .sorting_asc {
    @include e-table-sort-old;
    @include e-table-sort-modifier-old("asc");
  }

  .sorting_desc {
    @include e-table-sort-old;
    @include e-table-sort-modifier-old("desc");
  }
}

.dataTables_info {
  display: none;
}

.dataTables_empty {
  text-align: center;
}

.dataTables_paginate {
  bottom: 12px;
  display: flex;
  margin-right: 1px;
  position: absolute;
  right: 15px;
  text-align: right;

  > span {
    display: inline-flex;

    > span {
      @include e-pagination-item;
      @include e-pagination-item-modifier("disabled");

      margin-right: 0;
    }
  }

  .paginate_button {
    @include e-pagination-item;

    margin-right: 0;

    &.current {
      @include e-pagination-item-modifier("active");
    }

    &.disabled {
      @include e-pagination-item-modifier("disabled");
    }

    &.previous,
    &.next {
      border: transparent;
      border-radius: $border-radius;
      font-size: 0;

      &.disabled {
        &::before {
          opacity: 0.5;
        }
      }

      &::before {
        background-size: cover;
        content: "";
        height: size("base", 3);
        left: 0;
        line-height: 1;
        margin: auto;
        min-width: 0;
        position: absolute;
        right: 0;
        top: $space-xs;
        width: size("base", 3);
      }
    }

    &.previous {
      &::before {
        color: var(--token-icon-default-text);
        content: var(--token-glyphs-move-left);
        font-family: var(--token-icon-default-fontFamily);
        font-size: var(--token-icon-default-size);
      }

      &.disabled {
        &:hover {
          background-color: transparent;

          &::before {
            color: var(--token-icon-default-text);
            content: var(--token-glyphs-move-left);
            font-family: var(--token-icon-default-fontFamily);
            font-size: var(--token-icon-default-size);
          }
        }
      }

      &:hover {
        &::before {
          color: var(--token-icon-default-text);
          content: var(--token-glyphs-move-left);
          font-family: var(--token-icon-default-fontFamily);
          font-size: var(--token-icon-default-size);
        }
      }
    }

    &.next {
      border-right: 0;

      &::before {
        color: var(--token-icon-default-text);
        content: var(--token-glyphs-move-right);
        font-family: var(--token-icon-default-fontFamily);
        font-size: var(--token-icon-default-size);
      }

      &.disabled {
        &:hover {
          background-color: transparent;

          &::before {
            color: var(--token-icon-default-text);
            content: var(--token-glyphs-move-right);
            font-family: var(--token-icon-default-fontFamily);
            font-size: var(--token-icon-default-size);
          }
        }
      }

      &:hover {
        &::before {
          color: var(--token-icon-default-text);
          content: var(--token-glyphs-move-right);
          font-family: var(--token-icon-default-fontFamily);
          font-size: var(--token-icon-default-size);
        }
      }
    }
  }
}

.dataTables_length {
  bottom: 12px;
  left: 15px;
  position: absolute;

  .e-select {
    margin: 0 $space-xs;
  }
}

.dataTables_filter {
  left: 15px;
  position: absolute;
  top: 13px;
  z-index: z("tooltip");

  label {
    @extend .e-field__label;
    @extend .e-field__label-small;
    @include typography("body");

    display: block;
    margin: 0 0 $space-2xs;
  }

  input {
    @include e-input-old;
    @include e-input-size("large");

    border-radius: $border-radius;
    margin-left: $space-s;
  }
}

.table-action-icon {
  &.copy,
  &.dropdown-copy,
  &.delete,
  &.dropdown-delete {
    background: transparent;
    height: auto;
    top: 0;
    width: auto;
  }
}

@keyframes e-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
