// stylelint-disable declaration-no-important

$text-lineheight: 1.667;
$text-lineheight-condensed: 1.267;
$text-bold-weight: 500;
$typo: (
  headline: (
    "family": "Roboto",
    "weight": 300,
    "size": 30px,
  ),
  title: (
    "family": "Roboto",
    "weight": 300,
    "size": 20px,
  ),
  subheader: (
    "family": "Roboto",
    "weight": 400,
    "size": 15px,
  ),
  button: (
    "family": "Roboto",
    "weight": 400,
    "size": 14px,
  ),
  body: (
    "family": "Roboto",
    "weight": 400,
    "size": 13px,
  ),
  small: (
    "family": "Roboto",
    "weight": 400,
    "size": 11px,
  ),
);
$typography: (
  "heading1": (
    "font-size": 32px,
    "font-weight": 500,
    "line-height": 40px,
  ),
  "heading2": (
    "font-size": 24px,
    "font-weight": 500,
    "line-height": 32px,
  ),
  "heading3": (
    "font-size": 20px,
    "font-weight": 500,
    "line-height": 24px,
  ),
  "heading4": (
    "font-size": 16px,
    "font-weight": 500,
    "line-height": 24px,
  ),
  "body": (
    "font-size": 14px,
    "font-weight": normal,
    "line-height": 24px,
  ),
  "small": (
    "font-size": 12px,
    "font-weight": normal,
    "line-height": 20px,
  ),
);

@mixin typography($type, $isImportant: false) {
  @if $isImportant {
    font-family: var(--token-font-default, "Roboto") !important;
    font-size: map.get($typography, $type, "font-size") !important;
    font-weight: map.get($typography, $type, "font-weight") !important;
    line-height: map.get($typography, $type, "line-height") !important;
  } @else {
    font-family: var(--token-font-default, "Roboto");
    font-size: map.get($typography, $type, "font-size");
    font-weight: map.get($typography, $type, "font-weight");
    line-height: map.get($typography, $type, "line-height");
  }
}
