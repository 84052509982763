.e {
  &-compare {
    @include clearfix;

    margin: 0 $space-xs;
    padding-top: $space-3xl;
    position: relative;

    &-bottom {
      padding-bottom: $space-3xl;
    }

    &__line {
      background-color: var(--token-border-default);
      height: $space-2xs;

      &::before {
        background: inherit;
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
        content: "";
        height: size("base", 0.5);
        left: -7px;
        position: absolute;
        top: $space-3xl;
        width: size("base");
      }

      &::after {
        background: inherit;
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
        content: "";
        height: size("base", 0.5);
        position: absolute;
        right: -7px;
        top: $space-3xl;
        width: size("base");
      }

      &-progress {
        transform: translateY(-$space-2xs);

        &::before,
        &::after {
          top: 0;
        }
      }
    }

    &__label {
      color: var(--token-neutral-500);
      margin-top: $space-xs;

      &-left {
        float: left;
        margin-left: -$space-xs;
      }

      &-right {
        float: right;
        margin-right: -$space-xs;
      }
    }

    .e-bubble {
      margin-left: -7px;
      top: 50px;
      transform: translateY(-50%);
      z-index: 1;
    }

    .e-tt-bubble .e-tt__arrow {
      margin-left: 0;
    }

    .e-bubble-label {
      animation: none;
      background: transparent;
      border: 0;
      color: var(--token-text-default);
      margin: 0;
      opacity: 1;
      padding: 0;

      &[x-placement="top"] {
        transform: translateY(-$space-xl);
      }

      &[x-placement="bottom"] {
        transform: translateY($space-xl);

        .e-bubble__line {
          top: -32px;
        }
      }

      &-compact {
        &[x-placement="top"] {
          .e-tt__content {
            transform: translateY($space-l);
          }

          .e-bubble__line {
            top: 49px;
            transform: translateX(-1px);
          }
        }

        &[x-placement="bottom"] {
          .e-tt__content {
            transform: translateY(-$space-l);
          }

          .e-bubble__line {
            transform: translateX(1px);
          }
        }

        .e-bubble__line {
          height: size("base");
        }
      }
    }
  }
}
