.e {
  &-popover_deprecated {
    color: var(--token-box-strong-text);
    max-width: size("base", 40);
    position: absolute;
    z-index: z("dropdown");

    &-dropdown {
      min-width: size("base", 30);

      .e-popover_deprecated {
        &__content {
          background-color: var(--token-box-strong-background);
          color: var(--token-box-strong-text);
        }
      }
    }

    &__arrow {
      background-color: var(--token-box-strong-background);
      box-shadow: 1px 1px 0 0 var(--token-box-strong-border);
      height: size("base", 1);
      position: absolute;
      width: size("base", 1);
    }

    &__close {
      background-color: none;
      border: 0;
      cursor: pointer;
      font-size: 0;
      height: size("icon", "small");
      margin: $space-xs $space-2xs 0 0;
      position: absolute;
      right: $space-xs;
      top: $space-s;
      width: size("icon", "small");

      &::after {
        color: var(--token-icon-default-text);
        content: var(--token-glyphs-close);
        font-family: var(--token-icon-default-fontFamily);
        font-size: var(--token-icon-default-sizeSmall);
      }
    }

    &__content {
      @include typography("body");

      background-color: var(--token-box-strong-background);
      border: 1px solid var(--token-box-strong-border);
      border-radius: $border-radius;
      box-shadow: var(--token-shadow-medium);
      padding: $space-xs $space-xl $space-xs $space-m;
    }

    &__title {
      background-color: var(--token-box-strong-background);
      color: var(--token-box-strong-text);
      font-size: typo("subheader", "size");
      font-weight: typo("subheader", "weight");
      padding: $space-xs $space-m;
    }

    &.left {
      padding-right: $space-xs;

      .e-popover_deprecated__close {
        right: 16px;
        top: 6px;
      }

      .e-popover_deprecated__arrow {
        left: auto !important; /* stylelint-disable-line declaration-no-important */
        margin-top: -$space-xs;
        right: 5px !important; /* stylelint-disable-line declaration-no-important */
        top: 50%;
        transform: rotate(315deg);
      }
    }

    &.right {
      padding-left: $space-xs;

      .e-popover_deprecated__close {
        top: 6px;
      }

      .e-popover_deprecated__arrow {
        left: 5px !important; /* stylelint-disable-line declaration-no-important */
        margin-top: -$space-xs;
        top: 50%;
        transform: rotate(135deg);
      }
    }

    &.top {
      padding-bottom: $space-xs;

      .e-popover_deprecated__close {
        top: 6px;
      }

      .e-popover_deprecated__arrow {
        bottom: 4px;
        margin-top: -$space-xs;
        transform: rotate(45deg);
      }
    }

    &.bottom {
      padding-top: $space-xs;

      .e-popover_deprecated__arrow {
        margin-left: -$space-xs;
        top: 6px;
        transform: rotate(225deg);
      }
    }
  }
}
