e-switch {
  display: inline-block;
  line-height: normal;

  .e-switch__wrapper {
    align-items: center;
    display: inline-flex;
    position: relative;
  }

  .e-switch {
    left: 0;
    top: 0;
  }

  .e-switch,
  .e-switch__label {
    flex: 1 1 auto;
  }

  .e-switch__label {
    margin-bottom: 0;
    margin-right: $space-2xs;
  }

  &[disabled] {
    .e-switch__label {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

.e-switch {
  cursor: pointer;
  display: inline-block;
  font-size: 0;
  line-height: $text-lineheight;
  margin: 0 $space-xs 0 $space-3xs;
  position: relative;
  user-select: none;
  vertical-align: middle;

  &__input {
    opacity: 0;
    position: absolute;

    &:checked + .e-switch__toggle {
      background-color: var(--token-switch-on-background);

      &:where(.e-switch:hover *) {
        background-color: var(--token-switch-on-backgroundHover);

        &::before {
          box-shadow: 0 0 0 2px var(--token-switch-on-toggleBorderHover);
        }
      }

      &::before {
        color: var(--token-text-highlight);
        content: var(--token-glyphs-checkmark);
        font-family: var(--token-icon-default-fontFamily);
        font-size: var(--token-icon-default-sizeSmall);
        line-height: 1;
        text-align: center;
        transform: translate(8px, -50%);
      }
    }

    &:disabled + .e-switch__toggle {
      cursor: not-allowed;
      opacity: 0.5;

      ~ .e-switch__label,
      ~ .e-field__label-inline {
        opacity: 0.5;
      }

      &::after {
        cursor: not-allowed;
      }
    }

    &:focus + .e-switch__toggle {
      @include can-have-focus-style {
        @include has-focus-style(2px, solid, 2px, var(--token-outline-focus));
      }
    }

    &::before {
      border-color: transparent;
    }
  }

  &__toggle {
    background-color: var(--token-switch-off-background);
    border-radius: 50px;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    transition: background 0.2s;
    width: 32px;

    &::before {
      background-color: var(--token-switch-off-toggleBackground);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 12px;
      border-radius: 50px;
      box-shadow: 0 0 0 2px color-mix(in srgb, var(--token-neutral-000) 0%, transparent);
      color: var(--token-icon-default-text);
      content: var(--token-glyphs-minus);
      font-family: var(--token-icon-default-fontFamily);
      font-size: var(--token-icon-default-sizeSmall);
      height: 16px;
      left: $space-3xs;
      line-height: 1;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(0, -50%);
      transition:
        transform 0.2s cubic-bezier(0.5, 1.6, 0.5, 1),
        box-shadow 0.2s;
      width: 20px;
    }

    &::after {
      content: "";
      cursor: pointer;
      display: inline-block;
      height: size("base", 2);
      left: -$space-3xs;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    ~ .e-field__label-inline {
      cursor: pointer;
    }

    &:where(.e-switch:hover *) {
      background-color: var(--token-switch-off-backgroundHover);

      &::before {
        box-shadow: 0 0 0 2px var(--token-switch-off-toggleBorderHover);
      }
    }
  }

  + .e-field__label-inline,
  &__label {
    @include has-focus-state(0);

    background: none;
    border: 0;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    vertical-align: middle;

    .e-switch__input:disabled & {
      cursor: not-allowed;
    }
  }

  &-choose {
    margin: 0 $space-xs;

    .e-switch__input {
      + .e-switch__toggle {
        background-color: var(--token-switch-on-background);

        &::before {
          background-image: none;
        }

        &:where(.e-switch:hover *) {
          background-color: var(--token-switch-on-backgroundHover);

          &::before {
            box-shadow: 0 0 0 2px var(--token-switch-on-toggleBorderHover);
          }
        }
      }
    }
  }

  &-flex {
    height: 20px;
    margin-top: $space-2xs;
    padding-top: 0;
  }
}
