.e {
  &-accordion {
    border-left: 1px solid var(--token-box-default-border);
    border-right: 1px solid var(--token-box-default-border);
    border-top: 1px solid var(--token-box-default-border);

    &-stretch {
      border-left: 0;
      border-right: 0;
      margin-left: -$space-m;
      margin-right: -$space-m;
    }

    &__title {
      @include clearfix;

      border-bottom: 1px solid var(--token-box-default-border);
      cursor: pointer;
      display: block;
      line-height: size("base", 3);
      padding: $space-xs $space-m $space-xs calc(#{$space-xl} + #{size("base", 3)});
      position: relative;
      user-select: none;

      &:hover {
        background-color: var(--token-background-strong);
      }

      &::after {
        border-radius: $border-radius-small;
        color: var(--token-icon-default-text);
        content: var(--token-glyphs-angle-down);
        display: inline-block;
        font-family: var(--token-icon-default-fontFamily);
        font-size: var(--token-icon-default-size);
        height: size("base", 3);
        left: $space-m;
        position: absolute;
        top: $space-xs;
        transform: rotate(-90deg);
        transition: all 100ms;
        width: size("base", 3);
      }

      &-active {
        background-color: var(--token-background-strong);

        &::after {
          transform: rotate(0deg);
        }

        + .e-accordion__content {
          display: block;
        }
      }

      &-disabled {
        color: var(--token-text-faint);
        cursor: default;

        &:hover {
          background: transparent;
        }

        &::after {
          opacity: 0.6;
        }
      }
    }

    &__content {
      border-bottom: 1px solid var(--token-box-default-border);
      display: none;
      padding: $space-m;

      &-active {
        display: block;
      }
    }

    // scss-lint:disable QualifyingElement
    > input[type="radio"] {
      &:checked {
        + .e-accordion__title {
          cursor: default;
        }
      }
    }

    > input {
      display: none;

      &:checked {
        // nth-child: safari not repaint bug fix
        + .e-accordion__title:nth-child(n) {
          @extend .e-accordion__title-active;
        }
      }

      &:disabled {
        + .e-accordion__title {
          @extend .e-accordion__title-disabled;
        }
      }
    }

    &-sidebar {
      .e-accordion__title {
        padding: $space-m $space-m $space-m $space-4xl;
      }

      .e-accordion__title::after {
        top: $space-m;
      }

      .e-fullheight & {
        // AC
        border-top: 0;
        margin: -$space-m;
        margin-bottom: 0;

        .e-accordion__title {
          padding: $space-xs $space-m $space-xs $space-4xl;

          &::after {
            top: $space-xs;
          }
        }
      }
    }
  }
}
