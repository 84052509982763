e-colorpicker {
  display: none;
}

.e {
  &-colorpicker {
    width: size("base", 48);
    z-index: z("flying");

    &[x-placement="top-start"] {
      > .e-box {
        margin-bottom: -1px;
      }
    }

    &[x-placement="bottom-start"] {
      > .e-box {
        margin-top: -1px;
      }
    }

    &__top {
      align-items: center;
      display: flex;
      gap: $space-s;
      justify-content: space-between;
      margin-bottom: $space-m;
    }

    &__separator {
      background-color: var(--token-border-strong);
      height: size("base", 5);
      width: 1px;
    }

    &__predefined {
      display: flex;
      flex: 1 0 auto;
      justify-content: space-between;
      outline: 0;
    }

    &__bottom {
      display: flex;
      gap: $space-s;
      margin-bottom: $space-m;
    }

    &__recent {
      display: flex;
      flex: 1 1 auto;
      justify-content: space-between;
      outline: 0;
    }

    &__predefined,
    &__recent {
      @include has-focus-state;

      align-items: center;
      border-radius: $border-radius;
      height: size("base", 5);
      padding-left: $space-s;
      padding-right: $space-s;

      &:where(:focus) .e-colorpicker__color-active {
        @include has-secondary-focus-style($offset: 0);
      }
    }

    &__color {
      align-items: center;
      border: 2px solid var(--token-border-strong);
      border-radius: 100%;
      cursor: pointer;
      display: flex;
      height: size("base", 3);
      justify-content: center;
      position: relative;
      width: size("base", 3);

      &-selected::before {
        background-color: color-mix(in srgb, var(--token-neutral-999) 40%, transparent);
        border-radius: 100%;
        content: "";
        display: block;
        height: size("base", 2);
        left: $space-3xs;
        position: absolute;
        top: $space-3xs;
        width: size("base", 2);
      }

      &-empty {
        cursor: default;
        position: relative;

        &::before {
          background-color: var(--token-border-strong);
          content: "";
          height: 2px;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translate(-1px, -1px) rotate(-45deg);
          width: 22px;
        }
      }
    }
  }
}
