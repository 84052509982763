@import "../box/styles";

.e-popup-panel {
  background-color: var(--token-box-strong-background);
  border: solid 1px var(--token-box-strong-border);
  border-radius: $border-radius;
  box-shadow: var(--token-shadow-medium);
  display: flex;
  flex-direction: column;
  outline: 0;
  padding: $space-m;
  position: absolute;
  width: auto;

  &-no_padding {
    padding: 0;
  }

  @each $type, $data in $box-styles {
    &-#{$type} {
      background-color: map.get($data, "background");
      border-color: map.get($data, "border");
    }
  }

  &__header {
    border-bottom: solid var(--token-box-strong-border) 1px;
    margin: -#{$space-m} -#{$space-m} #{$space-m};
    padding: $space-m;

    @each $type, $data in $box-styles {
      :where(.e-popup-panel-#{$type}) & {
        border-color: map.get($data, "border");
      }
    }
  }

  &__scrollarea {
    flex-grow: 1;
    margin: -#{$space-m};
    overflow-y: auto;
    padding: #{$space-m};

    &:first-child,
    .e-popup-panel__arrow + & {
      margin-top: 0;
      padding-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__footer {
    border-top: solid var(--token-box-strong-border) 1px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    margin: #{$space-m} -#{$space-m} -#{$space-m};
    padding: $space-xs $space-m;

    @each $type, $data in $box-styles {
      :where(.e-popup-panel-#{$type}) & {
        border-color: map.get($data, "border");
      }
    }
  }

  &__footer_content {
    align-items: center;
    display: flex;
    gap: $space-xs;
    grid-column: 1;

    &-error {
      color: var(--token-text-error);
    }
  }

  &__actions,
  &__actions-wide {
    display: flex;
    flex-direction: row-reverse;
    gap: $space-xs;
    grid-column: 2;
    min-height: var(--token-button-default-height);
    width: 100%;
  }

  &__action {
    width: fit-content;
  }

  &__arrow {
    display: inline-block;
    height: size("base", 1);
    position: relative;
    width: size("base", 1);

    &::before {
      background: var(--token-box-strong-background);
      border-left: 1px solid var(--token-box-strong-border);
      border-top: 1px solid var(--token-box-strong-border);
      content: "";
      height: size("base", 1);
      left: 0;
      position: absolute;
      top: 0;
      width: size("base", 1);

      @each $type, $data in $box-styles {
        :where(.e-popup-panel-#{$type}) & {
          background-color: map.get($data, "background");
          border-left-color: map.get($data, "border");
          border-top-color: map.get($data, "border");
        }
      }
    }

    .e-popup-panel[data-popper-placement^="top"] > & {
      bottom: -#{size-unitless("base", 0.5) + 1}px;

      &::before {
        transform: rotate(225deg);
      }
    }

    .e-popup-panel[data-popper-placement^="bottom"] > & {
      top: -#{size-unitless("base", 0.5) + 1}px;

      &::before {
        transform: rotate(45deg);
      }
    }

    .e-popup-panel[data-popper-placement^="left"] > & {
      right: -#{size-unitless("base", 0.5) + 1}px;

      &::before {
        transform: rotate(135deg);
      }
    }

    .e-popup-panel[data-popper-placement^="right"] > & {
      left: -#{size-unitless("base", 0.5) + 1}px;

      &::before {
        transform: rotate(315deg);
      }
    }
  }
}
