.e {
  &-toolbox {
    + textarea {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &__content {
      @include clearfix;

      background-color: var(--token-background-strong);
      border-left: 1px solid var(--token-border-default);
      border-right: 1px solid var(--token-border-default);
      border-top: 1px solid var(--token-border-default);
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      line-height: var(--token-button-default-height);
      overflow: auto;
      padding: 0 $space-s;
    }

    &__actions {
      float: left;
      margin-left: -$space-s;
      margin-right: -$space-s;

      .e-select {
        border-bottom: 0;
        border-top: 0;
        float: left;
      }

      .e-btn {
        @extend .e-btn-borderless;
      }

      .e-input {
        background: transparent;
        border: 0;
      }
    }
  }
}
