$pagination-opacity-disabled: 0.5 !default;
$pagination-opacity-svg: 1 !default;
$pagination-opacity-svg-hover: 1 !default;

@mixin e-pagination-item {
  @include typography("body");
  @include has-focus-state;

  background-color: var(--token-button-toolbar-background);
  border: 1px solid transparent;
  border-radius: $border-radius;
  color: var(--token-button-toolbar-text);
  cursor: pointer;
  flex: 0 0 auto;
  font-weight: 500;
  height: size("base", 5);
  line-height: size-unitless("base", 5) - 2px;
  margin-right: -2px;
  min-width: size("base", 5);
  padding: 0 $space-xs;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: $btn-hover-transition;
  user-select: none;

  &:visited {
    color: var(--token-button-toolbar-text);
  }

  &:hover {
    @include glow(var(--token-neutral-default));

    background-color: var(--token-button-toolbar-backgroundHover);
    color: var(--token-button-toolbar-text);

    svg {
      fill: var(--token-button-toolbar-text);
      opacity: $pagination-opacity-svg-hover;
    }
  }
}

@mixin e-pagination-item-modifier($modifier: "default") {
  @if $modifier == "active" {
    background-color: var(--token-button-toolbar-backgroundActive);
    border: 1px solid var(--token-button-toolbar-borderActive);
    cursor: default;

    &:hover {
      @include glow(var(--token-highlight-default));
    }
  }

  @if $modifier == "disabled" {
    cursor: not-allowed;
    opacity: $pagination-opacity-disabled;

    &:hover {
      @include glow("none");

      background-color: var(--token-button-toolbar-background);
      color: var(--token-button-toolbar-text);
    }
  }
}

.e-pagination {
  display: flex;
  margin-right: 1px;

  &-loading {
    pointer-events: none;
  }

  &__item:where(:hover) {
    z-index: 1;
  }
}

:where(:not(e-pagination) > .e-pagination) .e-pagination__item {
  @include e-pagination-item;

  margin-right: 0;

  .e-icon,
  &:hover .e-icon {
    color: var(--token-button-toolbar-text);
  }

  &-prev,
  &-next {
    > .e-icon,
    e-icon {
      color: var(--token-button-toolbar-text);
      font-size: var(--token-icon-default-sizeSmall);
      height: var(--token-icon-default-sizeSmall);
      opacity: $pagination-opacity-svg;
      width: var(--token-icon-default-sizeSmall);
    }

    e-icon {
      height: auto;
      width: auto;

      .e-icon {
        color: var(--token-button-toolbar-text);
        font-size: var(--token-icon-default-sizeSmall);
        height: var(--token-icon-default-sizeSmall);
        width: var(--token-icon-default-sizeSmall);
      }
    }
  }

  &:active,
  &-active {
    @include e-pagination-item-modifier("active");
  }

  &-disabled {
    @include e-pagination-item-modifier("disabled");

    &:hover {
      .e-icon {
        opacity: $pagination-opacity-svg;
      }
    }
  }
}
