.e {
  &-inputgroup {
    display: flex;
    gap: $space-xs;

    &-inline {
      display: inline-flex;
    }

    &__postfix {
      align-items: center;
      color: var(--token-text-default);
      display: flex;
      justify-content: center;
      padding: 0 $space-xs;
      white-space: nowrap;
    }

    &__item {
      min-width: auto;

      &-fluid {
        flex: 1 1 auto;
      }
    }
  }
}

.e-input.e-inputgroup__item:where(:not(.e-inputgroup__item-first)):focus,
e-select.e-inputgroup__item:where(:not(.e-inputgroup__item-first)):focus-within {
  box-shadow: -1px 0 0 0 var(--token-highlight-default);
}
