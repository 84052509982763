.e {
  &-header {
    position: relative;

    &-small {
      h1 {
        @include word-wrap;

        max-width: 70%;
        word-break: break-all;
      }
    }

    &-flex {
      display: flex;
      flex-shrink: 0;
      justify-content: space-between;
      margin: $space-xs 0 $space-m;

      h1,
      h2,
      h3 {
        flex: 1 1 100%;
        line-height: $text-lineheight-condensed;
        margin-bottom: 0;
        max-width: 100%;
      }

      .e-header {
        &__button {
          align-items: center;
          display: flex;
          flex-basis: auto;
          flex-grow: 0;
          flex-shrink: 0;
          margin-left: auto;
          margin-top: 0;
          position: static;
        }
      }
    }

    &-box {
      align-items: center;
      border-bottom: 1px solid var(--token-box-default-border);
      height: size("base", 8);
      margin: (-$space-m) (-$space-m) $space-m;
      padding: $space-m;
    }

    &__title {
      line-height: size("base", 5);
    }

    &__parentlink {
      color: var(--token-text-default);
      cursor: pointer;
      line-height: size("base", 5);
      padding-right: 10px;

      .e-icon {
        color: var(--token-text-default);
      }
    }

    &__back,
    &__button {
      padding-left: $space-m;
      position: absolute;
      right: 0;
      text-decoration: none;
      top: 50%;
    }

    &__back {
      margin-top: 0;
      transform: translateY(-50%);
    }

    &__button {
      margin-top: #{-#{math.div(size-unitless("base", 5), 2)}}px;
    }
  }
}
