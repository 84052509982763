@import "./assets";

e-tab {
  display: none;
}

.e-tab-bar {
  background-color: var(--token-tab-default-background);
  border-radius: $border-radius - 1px;
  display: flex;
  max-height: 100%;

  &-fullheight {
    height: 100%;
  }

  &__wrapper {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    width: 100%;

    :where(.e-tab-bar-vertical) & {
      flex-direction: row;
    }
  }

  &__header {
    border-bottom: 1px solid var(--token-box-default-border);
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;

    :where(.e-tab-bar-vertical) & {
      border-bottom: 0;
      border-right: 1px solid var(--token-box-default-border);
      flex-direction: column;
      width: size("base", 8);
    }

    &-fluid {
      width: auto;
    }
  }

  &__tab {
    color: var(--token-tab-default-text);
    font-weight: 500;
    margin-bottom: -1px;
    padding: $space-xs;
    position: relative;

    &:where(:not(.e-tab-bar__tab-disabled)):hover {
      cursor: pointer;
    }

    &::after {
      @include tab-bar-indicator;

      :where(.e-tab-bar:not(.e-tab-bar-vertical)) & {
        @include tab-bar-indicator-horizontal;
      }

      :where(.e-tab-bar-vertical) & {
        @include tab-bar-indicator-vertical;
      }
    }

    &-selected {
      color: var(--token-tab-selected-text);

      :where(.e-tab-bar-vertical) & {
        &::after {
          width: 3px;
        }
      }

      :where(.e-tab-bar:not(.e-tab-bar-vertical)) & {
        &::after {
          height: 3px;
        }
      }
    }

    &-disabled {
      opacity: 0.4;
    }

    :where(.e-tab-bar-vertical) & {
      margin-bottom: 0;
      margin-right: -1px;
    }
  }

  &__tab_content {
    @include tab-bar-tab-content;

    align-items: center;
    display: flex;
    gap: $space-xs;

    :where(.e-tab-bar__tab:not(.e-tab-bar__tab-selected):not(.e-tab-bar__tab-disabled)):hover & {
      @include tab-bar-tab-content-hover;
    }

    &-disabled {
      cursor: not-allowed;
    }
  }

  &__content {
    flex: 1 1 auto;
    overflow: auto;

    &-spacing_cozy {
      padding: $space-l;
    }
  }

  &__badge {
    align-items: center;
    aspect-ratio: 1;
    background-color: var(--token-label-error-background);
    border-radius: 100%;
    color: var(--token-label-error-text);
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    line-height: 1;
    margin-right: - size("base", 2);
    transform: translate(-$space-xs, -$space-s);
    width: size("base", 2);

    &-icon {
      margin: 0;
      position: absolute;
      right: 0;
      transform: translate(-$space-2xs, -$space-s);
    }
  }
}
