.e-datetime-calendar {
  &__navigation {
    border: 1px solid var(--token-border-default);
    border-bottom: 0;
    border-radius: $border-radius $border-radius 0 0;
    display: flex;
    gap: $space-2xs;
    padding: $space-2xs;
  }

  &__footer {
    border: 1px solid var(--token-border-default);
    border-radius: 0 0 $border-radius $border-radius;
    border-top: 0;
    padding: $space-xs $space-xs;
  }

  &__prev_month,
  &__next_month {
    flex: 0 0 auto;
  }

  &__selects {
    display: flex;
    flex: 1 1 auto;
    gap: $space-2xs;
    justify-content: center;
  }

  &__select {
    flex: 0 0 auto;
  }

  &__table_wrapper {
    @include has-focus-state(-$space-3xs);

    border: 1px solid var(--token-border-default);
    border-radius: 0 0 $border-radius $border-radius;
    display: flex;
    justify-content: center;
    margin-bottom: $space-2xs;
    padding: 0 $space-xs $space-xs;
    user-select: none;

    &:focus {
      outline: 0;
    }

    &-has-footer {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-bottom: 0;
    }

    tr {
      border: 2px solid transparent;
    }
  }

  &__weekdays {
    height: size("base", 5);
  }

  &__weekday {
    font-weight: normal;
  }

  &__day_cell {
    cursor: pointer;

    &:hover {
      .e-datetime-calendar__day {
        &:where(:not(.e-datetime-calendar__day-inactive)) {
          background-color: var(--token-background-faint);
          border-radius: $border-radius;
        }

        &-disabled {
          background-color: transparent;
        }

        &-range_start,
        &-range_end {
          background-color: var(--token-highlight-100);
        }

        &-range_start {
          border-bottom-right-radius: 0;
          border-right: 0;
          border-top-right-radius: 0;
        }

        &-range_end {
          border-bottom-left-radius: 0;
          border-left: 0;
          border-top-left-radius: 0;
        }

        &:where(.e-datetime-calendar__day-range_start.e-datetime-calendar__day-range_end) {
          border: 1px solid var(--token-highlight-default);
          border-radius: $border-radius;
        }

        &-selected {
          &:where(:not(.e-datetime-calendar__day-inactive)) {
            color: var(--token-text-light);
          }

          &:where(
              :not(.e-datetime-calendar__day-range_start, .e-datetime-calendar__day-range_end)
            ) {
            background-color: var(--token-highlight-default);
          }

          &:where(:not(.e-datetime-calendar__day-inactive))::before {
            background-color: var(--token-highlight-default);
          }

          :where(.e-datetime-calendar__day_label) {
            outline-color: var(--token-outline-focusAlternative);
          }
        }
      }
    }

    &-empty,
    &-disabled {
      cursor: default;
      pointer-events: none;

      .e-datetime-calendar__day {
        color: var(--token-text-faint);
      }
    }

    &-inactive {
      cursor: not-allowed;
    }
  }

  &__day {
    align-items: center;
    border-radius: 0;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr;
    height: size("base", 5);
    justify-content: center;
    justify-items: center;
    padding: 1px;
    pointer-events: none;
    position: relative;
    text-align: center;
    width: 45px;

    &:empty {
      &:hover,
      &::before {
        background-color: transparent;
      }
    }

    &-today {
      :where(.e-datetime-calendar__day_label) {
        border-radius: $border-radius;
        outline: solid 2px var(--token-pink-600);
        outline-offset: -2px;
      }
    }

    .e-datetime-calendar__table_wrapper:focus &-active {
      @include has-secondary-focus-style;
    }

    .e-datetime-calendar__table_wrapper:focus &-active.e-datetime-calendar__day-selected {
      @include has-secondary-focus-style(-6px);
    }

    &-inside_range,
    &-range_start,
    &-range_end {
      background-color: var(--token-highlight-100);
      border-bottom: 1px solid var(--token-highlight-default);
      border-top: 1px solid var(--token-highlight-default);
      color: var(--token-text-default);
      padding-bottom: 0;
      padding-top: 0;
    }

    &-range_end {
      border-bottom-right-radius: $border-radius;
      border-right: 1px solid var(--token-highlight-default);
      border-top-right-radius: $border-radius;
      padding-right: 0;
    }

    &-range_start {
      border-bottom-left-radius: $border-radius;
      border-left: 1px solid var(--token-highlight-default);
      border-top-left-radius: $border-radius;
      padding-left: 0;
    }

    &-range_start::before {
      border-bottom-left-radius: 100%;
      border-top-left-radius: 100%;
    }

    &-range_end::before {
      border-bottom-right-radius: 100%;
      border-top-right-radius: 100%;
    }

    &-hovered_range {
      &::before {
        background-color: var(--token-background-faint);
      }
    }

    &-selected {
      background-color: var(--token-highlight-100);
      border: 0;
      border-radius: $border-radius;
      color: var(--token-highlight-default);
      font-weight: 700;
      padding: 0;

      &::before {
        background-color: var(--token-background-default);
        border: 1px solid var(--token-highlight-default);
        border-radius: $border-radius;
        content: "";
        display: block;
        grid-area: 1 / 1;
        height: 100%;
        width: 100%;
      }

      &:where(.e-datetime-calendar__day-inside_range),
      &:where(.e-datetime-calendar__day-range_start),
      &:where(.e-datetime-calendar__day-range_end) {
        border-bottom: 1px solid var(--token-highlight-default);
        border-radius: 0;
        border-top: 1px solid var(--token-highlight-default);

        &::before {
          background-color: var(--token-background-default);
          border: 2px solid var(--token-highlight-default);
          border-radius: $border-radius;
          content: "";
          display: block;
          grid-area: 1 / 1;
          height: calc(100% + 2px);
          width: 100%;
        }
      }

      &:where(.e-datetime-calendar__day-range_start) {
        border-bottom-left-radius: $border-radius;
        border-left: 0;
        border-top-left-radius: $border-radius;
      }

      &:where(.e-datetime-calendar__day-range_end) {
        border-bottom-right-radius: $border-radius;
        border-right: 0;
        border-top-right-radius: $border-radius;
      }
    }

    &-start_of_month:where(
        .e-datetime-calendar__day-inside_range,
        .e-datetime-calendar__day-range_end:not(.e-datetime-calendar__day-range_start)
      )::after,
    &-end_of_month:where(
        .e-datetime-calendar__day-inside_range,
        .e-datetime-calendar__day-range_start:not(.e-datetime-calendar__day-range_end)
      )::after {
      background-color: var(--token-highlight-100);
      border-bottom: 1px solid var(--token-highlight-default);
      border-top: 1px solid var(--token-highlight-default);
      content: "";
      height: calc(100% + 2px);
      left: -(size("base", 3));
      mask-image: linear-gradient(90deg, transparent, #fff);
      position: absolute;
      top: -1px;
      width: size("base", 3);
    }

    &-end_of_month:where(
        .e-datetime-calendar__day-inside_range,
        .e-datetime-calendar__day-range_start:not(.e-datetime-calendar__day-range_end)
      )::after {
      left: auto;
      mask-image: linear-gradient(90deg, #fff, transparent);
      right: -(size("base", 3));
    }

    &_label {
      align-items: center;
      border: 1px solid transparent;
      display: flex;
      grid-area: 1 / 1;
      height: $space-xl;
      justify-content: center;
      width: $space-xl;
    }
  }
}
