@import "../input/assets";

@mixin select-design() {
  background-color: var(--token-background-default);
  border: 1px solid var(--token-border-default);
  height: size("base", 30);
  width: 100%;
}

.e {
  &-multiselect {
    border-radius: $border-radius;
    display: table;
    width: 100%;

    &__item,
    &__buttons {
      display: table-cell;
      vertical-align: middle;
    }

    &__item {
      width: 45%;

      select {
        @include select-design;

        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }

      option {
        padding: $space-2xs $space-s;
      }

      &__title {
        background-color: var(--token-background-strong);
        border: 1px solid var(--token-border-default);
        border-bottom: 0;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        padding: $space-s $space-m;
      }
    }

    &__emptystate {
      @include select-design;

      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      color: var(--token-text-faint);
      font-style: italic;
      padding: $space-2xs $space-s;
      user-select: none;
    }

    &__buttons {
      width: 10%;

      .e-btn {
        display: block;
        margin: 0 auto $space-m;
      }
    }

    @each $class, $data in map-get($map: $sizes, $key: "input") {
      &-#{$class} {
        @include e-input-size($class);
      }
    }
  }
}
