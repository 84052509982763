.e-checklist {
  &__item {
    border-radius: $border-radius;
    color: var(--token-text-default);
    cursor: pointer;
    display: flex;
    padding: $space-l;

    &:hover {
      background-color: var(--token-background-strong);
      color: var(--token-text-default);

      .e-checklist__status {
        border-color: var(--token-border-default);

        &-checked {
          border-color: var(--token-success-default);
        }
      }
    }

    &:visited,
    &:focus {
      color: var(--token-text-default);
    }
  }

  &__status {
    align-items: center;
    border: 3px solid var(--token-border-default);
    border-radius: 50%;
    display: flex;
    flex: 0 0 auto;
    height: size("base", 5);
    justify-content: center;
    margin-top: $space-2xs;
    width: size("base", 5);

    &-checked {
      border-color: var(--token-success-default);

      &::after {
        color: var(--token-success-default);
        content: var(--token-glyphs-checkmark);
        font-family: var(--token-icon-default-fontFamily);
        font-size: var(--token-icon-default-size);
      }
    }
  }

  &__container {
    padding-left: $space-l;
  }

  &__title {
    @include typography("body");

    font-weight: 500;
  }

  &__content {
    color: var(--token-neutral-500);
    margin-top: $space-xs;
  }
}
