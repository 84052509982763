.e {
  &-emptystate {
    align-items: center;
    color: var(--token-text-faint);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-width: 100%;
    padding: $space-m;
    text-align: center;

    &-top {
      justify-content: flex-start;
      padding-top: $space-2xl;
    }

    &-absolute {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    &-horizontal {
      flex-direction: row;
      text-align: left;

      .e-emptystate__content {
        align-items: flex-start;
      }

      .e-emptystate__icon,
      .e-emptystate__image {
        margin-bottom: 0;
        margin-right: $space-l;
      }
    }

    $states: (
      error: "error",
      warning: "warning",
    );

    @each $state, $color in $states {
      &-#{$state} {
        .e-emptystate__icon {
          background-color: var(--token-#{$color}-200);
          border: 0;

          .e-icon {
            color: var(--token-#{$color}-default);
          }
        }

        .e-emptystate__title {
          color: var(--token-#{$color}-default);
        }

        .e-emptystate__button.e-btn-primary,
        .e-emptystate__button.e-btn-secondary {
          background-color: var(--token-#{$color}-default);

          &:hover {
            background-color: var(--token-#{$color}-600);
          }
        }
      }
    }

    &__content {
      align-items: center;
      display: flex;
      flex: 0 1 auto;
      flex-direction: column;
      justify-content: center;
      max-width: size("base", 40);
      width: 100%;

      *:last-child {
        margin-bottom: 0;
      }

      > img {
        // inbox preview shame
        margin-bottom: $space-l;
      }
    }

    &__image {
      border-radius: $border-radius;
      flex: 0 0 auto;
      margin-bottom: $space-l;
    }

    &__icon {
      align-items: center;
      background-color: var(--token-neutral-200);
      border-radius: 50%;
      display: flex;
      flex: 0 0 auto;
      height: size("base", 12);
      justify-content: center;
      margin-bottom: $space-l;
      width: size("base", 12);

      .e-icon {
        color: var(--token-neutral-500);
        font-size: var(--token-icon-default-sizeLarge);
        height: var(--token-icon-default-sizeLarge);
        width: var(--token-icon-default-sizeLarge);
      }

      &-small {
        border-width: 2px;
        height: size("base", 6);
        width: size("base", 6);

        .e-icon {
          font-size: var(--token-icon-default-size);
          height: var(--token-icon-default-size);
          width: var(--token-icon-default-size);
        }
      }
    }

    &__title {
      @include typography("heading3");

      color: var(--token-text-faint);
      display: block;
      line-height: $text-lineheight-condensed;
      margin-bottom: $space-s;
      max-width: 100%;
    }

    &__lead {
      color: var(--token-text-faint);
      display: block;
      margin-bottom: $space-s;
      max-width: 100%;
    }

    &__button {
      margin-top: $space-s;
    }

    &__helperlink {
      margin-top: $space-m;
    }
  }
}
