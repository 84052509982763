/* Need for IE 6 */
@mixin screen($size, $dir: up, $sizes: $screen-sizes) {
  @if $dir == down {
    @media all and (max-width: #{map.get($sizes, $size) - 1}) {
      @content;
    }
  } @else {
    @media all and (min-width: #{map.get($sizes, $size)}) {
      @content;
    }
  }
}

@mixin clearfix {
  &::before,
  &::after {
    content: " "; // 1
    display: table; // 2
  }

  &::after {
    clear: both;
  }
}

// Clearfix
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
// Source: http://nicolasgallagher.com/micro-clearfix-hack/

@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin word-wrap {
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
}

@mixin break-all {
  overflow-wrap: break-word;
  word-break: break-all;
  word-wrap: break-word;
}

@mixin center-content($horizontal: true, $content: "&__content") {
  font-size: 0;

  @if $horizontal {
    text-align: center;
  }

  &::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  #{$content} {
    @include typography("body");

    display: inline-block;
    vertical-align: middle;

    @if $horizontal {
      text-align: left;
    }
  }
}

@mixin flipper-on($flipper) {
  @if & {
    @each $part in & {
      $newSelector: ":where(html.FLIPPER-#{$flipper}) #{$part}";

      @if str-index(#{$part}, ":not(.FLIPPER-") {
        $newSelector: str-replace(#{$part}, ":not(.FLIPPER-", ".FLIPPER-#{$flipper}:not(.FLIPPER-");
      } @else if str-index(#{$part}, "html.FLIPPER-") {
        $newSelector: str-replace(#{$part}, "html.FLIPPER-", "html.FLIPPER-#{$flipper}.FLIPPER-");
      } @else if str-index(#{$part}, "html.e-keyboard-navigation") {
        $newSelector: str-replace(
          #{$part},
          "html.e-keyboard-navigation",
          "html.e-keyboard-navigation.FLIPPER-#{$flipper}"
        );
      }

      @at-root #{$newSelector} {
        @content;
      }
    }
  } @else {
    :where(html.FLIPPER-#{$flipper}) {
      @content;
    }
  }
}

@mixin flipper-off($flipper) {
  @if & {
    @each $part in & {
      $newSelector: ":where(html:not(.FLIPPER-#{$flipper})) #{$part}";

      @if str-index(#{$part}, ":not(.FLIPPER-") {
        $newSelector: str-replace(#{$part}, ":not(.FLIPPER-", ":not(.FLIPPER-#{$flipper}.FLIPPER-");
      } @else if str-index(#{$part}, ".FLIPPER-") {
        $newSelector: str-replace(#{$part}, ".FLIPPER-", ":not(.FLIPPER-#{$flipper}).FLIPPER-");
      } @else if str-index(#{$part}, "html.e-keyboard-navigation") {
        $newSelector: str-replace(
          #{$part},
          "html.e-keyboard-navigation",
          "html.e-keyboard-navigation:not(.FLIPPER-#{$flipper})"
        );
      }

      @at-root #{$newSelector} {
        @content;
      }
    }
  } @else {
    :where(html:not(.FLIPPER-#{$flipper})) {
      @content;
    }
  }
}

@mixin flipper-on-import-file($flipper) {
  :where(html.FLIPPER-#{$flipper}) {
    @content;
  }
}

@mixin flipper-off-import-file($flipper) {
  :where(html:not(.FLIPPER-#{$flipper})) {
    @content;
  }
}

@mixin glow($token: var(--token-neutral-default)) {
  @if $token == "none" {
    box-shadow: none;
  } @else {
    box-shadow: 0 0 4px color-mix(in srgb, $token 66%, transparent);
  }
}
