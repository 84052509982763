e-chart-loader {
  color: var(--token-neutral-500);
  display: block;
  position: relative;
}

.e-chart-loader {
  &__animation {
    animation: e-chart-loader-background 9s infinite linear;
    backface-visibility: hidden;
    background-image: url("horizon/chart-loader.svg"); // TODO: remove from icons
    background-repeat: repeat-x;
    background-size: 400px;
    height: calc(100% - 2px);
    left: 2px;
    position: absolute;
    top: 0;
    vertical-align: top;
    width: calc(100% - 2px);

    &-small {
      animation: e-chart-loader-background-small 9s infinite linear;
      background-size: 100px;
    }
  }

  &__svg {
    vertical-align: top;
  }

  &__grid {
    stroke: var(--token-neutral-100);
  }

  &__axes {
    stroke: var(--token-neutral-500);
  }
}

@keyframes e-chart-loader-background {
  0% {
    background-position: 0 center;
  }

  100% {
    background-position: -400px center;
  }
}

@keyframes e-chart-loader-background-small {
  0% {
    background-position: 0 center;
  }

  100% {
    background-position: -100px center;
  }
}
