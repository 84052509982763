@import "../box/styles";

e-widget {
  background-color: var(--token-box-default-background);
  border: 1px solid var(--token-box-default-border);
  border-radius: $border-radius;
  box-shadow: var(--token-shadow-light);
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  &[fixed] {
    box-shadow: none;
  }
}

.e-widget {
  &-equal_height {
    height: 100%;
  }

  &__header {
    align-items: flex-start;
    border-bottom: 1px solid var(--token-box-default-border);
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    min-height: calc(var(--token-button-default-height) + (2 * $space-2xs));
  }

  &__title {
    @include typography("heading4");

    align-items: center;
    display: flex;
    flex: 1 1 auto;
    margin: $space-2xs $space-2xs $space-2xs $space-m;
    min-height: var(--token-button-default-height);
  }

  &__header_actions {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
  }

  &__header_action {
    margin: $space-2xs $space-2xs $space-2xs 0;
  }

  &__content {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: space-between;
  }

  &__footer_actions {
    align-items: center;
    border-top: 1px solid var(--token-box-default-border);
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: $space-2xs;
  }

  &__footer_action {
    margin: $space-2xs 0 0 $space-2xs;
  }

  &__collapse_icon {
    .e-icon {
      margin: -$space-3xs (-$space-2xs) 0 $space-xs;
    }
  }

  @each $type, $data in $box-styles {
    &-#{$type} {
      border-color: map.get($data, "border");

      .e-widget__header {
        background-color: map.get($data, "background");
        border-color: map.get($data, "border");
        color: map.get($data, "color");
      }
    }
  }
}
