$small-circle-radius: 40;
$medium-circle-radius: 42;
$large-circle-radius: 45;

@function get-dash-value($radius, $percentage) {
  $circumference: 2 * 3.1415927 * $radius;
  $percentage-as-decimal: math.div($percentage, 100%);

  @return $circumference * $percentage-as-decimal;
}

.e-spinner {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;

  &-table {
    margin: -3px $space-2xs 0;
  }

  &-fullscreen {
    left: 50%;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__text {
    margin-left: $space-s;
  }
}

.e-spinner-circle {
  fill: transparent;
  stroke-linecap: round;

  &-small,
  &-table {
    stroke-width: 14px;
  }

  &-medium {
    stroke-width: 12px;
  }

  &-large {
    stroke-width: 10px;
  }
}

.e-spinner-svg {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: spinner-svg;
  animation-timing-function: linear;
  flex: 1 0 auto;
  position: relative;
  stroke: var(--token-highlight-default);

  &-small,
  &-table {
    height: size("icon", "small");
    width: size("icon", "small");
  }

  &-medium {
    height: size("icon", "medium");
    width: size("icon", "medium");
  }

  &-large {
    height: size("icon", "large");
    width: size("icon", "large");
  }

  &__circle_base {
    stroke: var(--token-background-faint);
  }

  &__circle_01 {
    &-small {
      stroke-dasharray: get-dash-value($small-circle-radius, 98%);
      stroke-dashoffset: get-dash-value($small-circle-radius, 96%);
    }

    &-medium {
      stroke-dasharray: get-dash-value($medium-circle-radius, 99%);
      stroke-dashoffset: get-dash-value($medium-circle-radius, 96%);
    }

    &-large {
      stroke-dasharray: get-dash-value($large-circle-radius, 100%);
      stroke-dashoffset: get-dash-value($large-circle-radius, 96%);
    }
  }

  &__circle_02 {
    transform: rotate(35deg);
    transform-origin: 50% 50%;

    &-small {
      stroke-dasharray: get-dash-value($small-circle-radius, 98%);
      stroke-dashoffset: get-dash-value($small-circle-radius, 91%);
    }

    &-medium {
      stroke-dasharray: get-dash-value($medium-circle-radius, 99%);
      stroke-dashoffset: get-dash-value($medium-circle-radius, 91%);
    }

    &-large {
      stroke-dasharray: get-dash-value($large-circle-radius, 100%);
      stroke-dashoffset: get-dash-value($large-circle-radius, 91%);
    }
  }

  &__circle_03 {
    animation-duration: 3s;
    animation-fill-mode: none;
    animation-iteration-count: infinite;
    animation-name: circle-animation-large;
    animation-timing-function: ease-in-out;
    transform: rotateY(180deg);
    transform-origin: 50% 50%;

    &-small {
      animation-name: circle-animation-small;
      stroke-dasharray: get-dash-value($small-circle-radius, 97%);
    }

    &-medium {
      animation-name: circle-animation-medium;
      stroke-dasharray: get-dash-value($medium-circle-radius, 100%);
    }

    &-large {
      animation-name: circle-animation-large;
      stroke-dasharray: get-dash-value($large-circle-radius, 100%);
    }
  }
}

@keyframes spinner-svg {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes circle-animation-small {
  0% {
    stroke-dashoffset: get-dash-value($small-circle-radius, 19.5%);
  }

  50% {
    stroke-dashoffset: get-dash-value($small-circle-radius, 77.8%);
  }

  100% {
    stroke-dashoffset: get-dash-value($small-circle-radius, 19.5%);
  }
}

@keyframes circle-animation-medium {
  0% {
    stroke-dashoffset: get-dash-value($medium-circle-radius, 19.5%);
  }

  50% {
    stroke-dashoffset: get-dash-value($medium-circle-radius, 77.8%);
  }

  100% {
    stroke-dashoffset: get-dash-value($medium-circle-radius, 19.5%);
  }
}

@keyframes circle-animation-large {
  0% {
    stroke-dashoffset: get-dash-value($large-circle-radius, 19.5%);
  }

  50% {
    stroke-dashoffset: get-dash-value($large-circle-radius, 77.8%);
  }

  100% {
    stroke-dashoffset: get-dash-value($large-circle-radius, 19.5%);
  }
}
