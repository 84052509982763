@import "../tab-bar/assets";

$tab-max-tabs: 20 !default;

@mixin e-tabs-title-disabled {
  color: var(--token-tab-default-text);
  cursor: not-allowed;
  opacity: 0.5;
}

@mixin e-tabs-separator {
  @include tab-bar-tab-content;

  border-radius: $border-radius;
  display: inline-block;
  margin: 0;
  text-decoration: none;
}

.e {
  &-tabs {
    background-color: var(--token-tab-default-background);
    border-radius: $border-radius;
    font-size: 0;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;

    &::before {
      background-color: var(--token-box-default-border);
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
      top: 63px;
    }

    &-dialogheader {
      line-height: $text-lineheight;

      .e-tabs__title:first-child,
      & > input:first-child + .e-tabs__title {
        margin-left: $space-m;
      }
    }

    &-fullheight {
      height: 100%;

      .e-tabs__panel {
        height: calc(100% - #{size("base", 6)});
      }

      .e-tabs__panel__content {
        height: 100%;
        overflow: auto;
        padding-bottom: 0;

        &::after {
          content: "";
          display: block;
          height: $space-l;
        }

        &-no_after::after {
          content: none;
        }
      }
    }

    &__title {
      @include typography("body");

      align-items: center;
      border: 0;
      color: var(--token-tab-default-text);
      cursor: pointer;
      display: inline-flex;
      font-size: map.get($typography, "body", "font-size");
      font-weight: 500;
      margin: 0;
      min-height: size("base", 6);
      outline: 0;
      padding: $space-xs;
      position: relative;

      a {
        color: inherit;
      }

      &:where(
          :not(.e-tabs__title-active):not(.e-tabs__title-disabled):not(
              .e-tabs > input:disabled + .e-tabs__title
            ):not(.e-tabs > input:checked + .e-tabs__title)
        ):hover {
        .e-tabs__separator {
          @include tab-bar-tab-content-hover;
        }
      }

      &::after {
        @include tab-bar-indicator;
        @include tab-bar-indicator-horizontal;
      }

      &-active {
        color: var(--token-tab-selected-text);

        &::after {
          height: 3px;
        }

        .e-tabs__badge {
          opacity: 1;
        }
      }

      &-disabled {
        @include e-tabs-title-disabled;
      }
    }

    &__separator,
    &__separator:active,
    &__separator:visited {
      @include e-tabs-separator;
    }

    &__badge {
      @include typography("small");

      opacity: 0.8;
      padding-left: $space-m;

      e-icon {
        margin-top: -#{$space-3xs};
        vertical-align: middle;
      }
    }

    &__panel {
      &__content {
        background-color: var(--token-tab-default-background);
        border: 0;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        display: none;
        font-size: map.get($typography, "body", "font-size");
        padding: $space-l;

        &-no_padding {
          padding: 0;
        }

        &-active {
          display: block;
        }
      }
    }

    > .e-btn {
      margin-right: $space-m;
    }

    > input {
      display: none;

      &:checked + .e-tabs__title {
        color: var(--token-tab-selected-text);

        &::after {
          height: 3px;
        }
      }

      &:disabled + .e-tabs__title {
        @include e-tabs-title-disabled;
      }
    }

    @for $i from 1 through $tab-max-tabs {
      &
        > input:checked:nth-of-type(#{$i})
        ~ .e-tabs__panel
        > .e-tabs__panel__content:nth-child(#{$i}) {
        display: block;
      }
    }
  }
}

a.e-tabs__title-active:hover {
  color: var(--token-tab-selected-text);
}
