/* stylelint-disable declaration-no-important */
@import "../palette/palette";

@mixin generate-named-colors($type, $namedColors, $modifiers) {
  @each $colorName, $colorDefinition in $namedColors {
    @if type-of($colorDefinition) == "map" {
      @if $colorName == $type {
        @each $shade, $_ in $colorDefinition {
          @if $shade == "default" {
            & {
              @each $property in $modifiers {
                /* stylelint-disable-next-line eds/tokens */
                #{$property}: var(--token-#{$colorName}-#{$shade}) !important;
              }
            }
          } @else {
            &-#{$shade} {
              @each $property in $modifiers {
                /* stylelint-disable-next-line eds/tokens */
                #{$property}: var(--token-#{$colorName}-#{$shade}) !important;
              }
            }
          }
        }
      } @else {
        &-#{$colorName} {
          @each $property in $modifiers {
            #{$property}: var(--token-#{$colorName}-default) !important;
          }

          @each $shade, $_ in $colorDefinition {
            &-#{$shade} {
              @each $property in $modifiers {
                /* stylelint-disable-next-line eds/tokens */
                #{$property}: var(--token-#{$colorName}-#{$shade}) !important;
              }
            }
          }
        }
      }
    } @else {
      &-#{$colorName} {
        @each $property in $modifiers {
          #{$property}: var(--token-#{$colorName}-default) !important;
        }
      }
    }
  }
}

.e {
  &-hidden {
    display: none !important;
  }

  &-boxpadding {
    padding: $space-m;
  }

  &-gridcol {
    padding-left: $space-m;
    padding-right: $space-m;

    &-condensed {
      padding-left: $space-2xs;
      padding-right: $space-2xs;
    }

    &-separated {
      border-right: 1px solid var(--token-border-default);
    }

    &-first {
      padding-left: 0;
    }

    &-last {
      border-right: 0;
      padding-right: 0;
    }
  }

  &-nomargin {
    margin-left: 0 !important;
    margin-right: 0 !important;

    &-bottom {
      margin-bottom: 0 !important;
    }
  }

  &-scrollable {
    height: 100%;
    position: relative;
    width: 100%;

    &__content {
      display: block;
      height: 100%;
      overflow: auto;
      position: absolute;
      width: 100%;
    }
  }

  &-iframecontainer {
    font-size: 0;

    > iframe {
      vertical-align: top;
    }
  }

  &-clickable {
    cursor: pointer !important;
  }

  &-grabbable {
    cursor: move !important;
    cursor: grab !important;

    &:active {
      cursor: grabbing !important;
    }
  }

  &-hoverable:not(:hover) {
    .e-hoverable__display {
      display: none;
    }

    .e-hoverable__visible {
      visibility: hidden;
    }
  }

  &-svgclickfix {
    cursor: pointer;
    position: relative;

    > svg {
      pointer-events: none;
    }

    &::after {
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &-stretch {
    height: 100%;
    margin-left: -$space-m;
    margin-right: -$space-m;
    position: relative;

    &-small {
      margin-left: -$space-xs;
      margin-right: -$space-xs;
    }

    .e-col-nopadding & {
      margin: 0;
    }

    &-top {
      margin-top: -$space-m;
    }

    &-bottom {
      margin-bottom: -$space-xl;

      .e-box-chart & {
        margin-bottom: -$space-2xl;
      }
    }
  }

  &-notransition {
    transition: none !important;
  }

  &-test {
    &-notransition * {
      transition: none !important;
    }

    &-noopacity * {
      opacity: 1 !important;
    }
  }

  &-zebrastripe {
    > *:nth-child(odd) {
      background-color: var(--token-neutral-200);
    }
  }

  &-screenheight {
    bottom: 0;
    height: 100%;
    left: size("menu", "width");
    position: absolute;
    right: 0;
    top: size("shellbar", "horizonHeight");
    width: calc(100% - #{size("menu", "width")});

    &-has_steps {
      bottom: size("steps", "height");
      height: calc(100% - #{size("steps", "height")});
    }
  }

  &-popperfix {
    top: -99999px;

    &-flying {
      z-index: z("flying");
    }
  }

  &-border {
    &-all {
      border: 1px solid var(--token-border-default);
    }

    &-bottom {
      border-bottom: 1px solid var(--token-border-default);
    }

    &-top {
      border-top: 1px solid var(--token-border-default);
    }

    &-left {
      border-left: 1px solid var(--token-border-default);
    }

    &-right {
      border-right: 1px solid var(--token-border-default);
    }

    &-none {
      border: 0;
    }
  }
}

.text-align {
  &-left {
    text-align: left !important;
  }

  &-center {
    text-align: center !important;
  }

  &-right {
    text-align: right !important;
  }
}

.text-lineheight {
  line-height: map.get(
    $typography,
    "small",
    "line-height"
  ); // TODO: check RTI node with 'body' line-height
}

.text-underline {
  text-decoration: underline !important;
}

.clearfix {
  @include clearfix;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.text-color {
  @each $colorName, $colorDefinition in $palette {
    &-#{$colorName} {
      color: var(--token-#{$colorName}-500) !important;
      fill: var(--token-#{$colorName}-500) !important;

      @each $shade, $_ in $colorDefinition {
        &-#{$shade} {
          /* stylelint-disable-next-line eds/tokens */
          color: var(--token-#{$colorName}-#{$shade}) !important;
          /* stylelint-disable-next-line eds/tokens */
          fill: var(--token-#{$colorName}-#{$shade}) !important;
        }
      }
    }
  }

  @include generate-named-colors("text", $namedColors, "color" "fill");

  &-shade {
    color: var(--token-text-faint) !important;
    fill: var(--token-text-faint) !important;
  }

  &-white {
    color: var(--token-text-light) !important;
    fill: var(--token-text-light) !important;
  }

  &-inherit {
    color: currentcolor !important;
    fill: currentcolor !important;
  }

  &-title {
    color: var(--token-neutral-800) !important;
    fill: var(--token-neutral-800) !important;
  }

  @each $class, $data in $palette_reporting {
    &-#{$class} {
      color: #{$data} !important;
      fill: #{$data} !important;
    }
  }

  @each $class, $data in $palette_socialads {
    &-#{$class} {
      color: #{$data} !important;
      fill: #{$data} !important;
    }
  }

  @each $class, $data in $palette_smartinsight {
    &-#{$class} {
      color: #{$data} !important;
      fill: #{$data} !important;
    }
  }

  &-disabled {
    opacity: 0.5 !important;
  }

  &-help {
    color: var(--token-neutral-300) !important;
    fill: var(--token-neutral-300) !important;
  }
}

.background-color {
  @each $colorName, $colorDefinition in $palette {
    &-#{$colorName} {
      background-color: var(--token-#{$colorName}-500) !important;

      @each $shade, $_ in $colorDefinition {
        &-#{$shade} {
          /* stylelint-disable-next-line eds/tokens */
          background-color: var(--token-#{$colorName}-#{$shade}) !important;
        }
      }
    }
  }

  @include generate-named-colors("background", $namedColors, "background-color");

  &-title {
    background-color: var(--token-neutral-800) !important;
  }

  @each $class, $data in $palette_reporting {
    &-#{$class} {
      background-color: #{$data} !important;
    }
  }

  @each $class, $data in $palette_socialads {
    &-#{$class} {
      background-color: #{$data} !important;
    }
  }

  @each $class, $data in $palette_smartinsight {
    &-#{$class} {
      background-color: #{$data} !important;
    }
  }
}

.button-height {
  line-height: #{size-unitless("base", 5) - 2}px !important;

  &-borderless {
    line-height: size("base", 5) !important;
  }

  &-small {
    line-height: size-unitless("base", 3) - 2px;
  }
}

.text-size-headline {
  font-size: map.get($typography, "heading1", "font-size") !important;
  font-weight: map.get($typography, "heading1", "font-weight") !important;
}

.text-size-title {
  font-size: map.get($typography, "heading2", "font-size") !important;
  font-weight: map.get($typography, "heading2", "font-weight") !important;
}

.text-size-subheader {
  font-size: map.get($typography, "heading3", "font-size") !important;
  font-weight: map.get($typography, "heading3", "font-weight") !important;
}

.text-size-button,
.text-size-body {
  font-size: map.get($typography, "body", "font-size") !important;
  font-weight: map.get($typography, "body", "font-weight") !important;
}

.text-size-small {
  font-size: map.get($typography, "small", "font-size") !important;
  font-weight: map.get($typography, "small", "font-weight") !important;
}

.text-overflow {
  @include text-overflow;

  display: inline-block;
  max-width: 100%;
  vertical-align: text-bottom;
  width: auto;
}

.word-wrap {
  @include word-wrap;
}

.break-all {
  @include break-all;
}

.dark-link {
  color: var(--token-neutral-800) !important;
  text-decoration: none;

  svg {
    fill: var(--token-neutral-800) !important;
  }

  &:hover,
  &:active {
    color: var(--token-highlight-700) !important;

    svg {
      fill: var(--token-highlight-700) !important;
    }
  }
}

.light-link {
  color: var(--token-neutral-000) !important;

  svg {
    fill: var(--token-neutral-000) !important;
  }

  &:hover,
  &:active {
    color: var(--token-neutral-300) !important;

    svg {
      fill: var(--token-neutral-300) !important;
    }
  }
}

.font-weight-thin {
  font-weight: normal !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-family-mono {
  font-family: var(--token-font-monospace, monospace) !important;
}

.noselect {
  user-select: none !important;
}

$space-positions: (
  "vertical": (
    "bottom",
    "top",
  ),
  "horizontal": (
    "left",
    "right",
  ),
  "top": (
    "top",
  ),
  "right": (
    "right",
  ),
  "bottom": (
    "bottom",
  ),
  "left": (
    "left",
  ),
);

@each $property in (margin, padding) {
  @each $size, $value in $space-sizes {
    .e-#{$property}-#{$size} {
      #{$property}: $value !important;
    }
  }

  @each $position-name, $position-values in $space-positions {
    @each $size, $value in $space-sizes {
      .e-#{$property}-#{$position-name}-#{$size} {
        @each $position-value in $position-values {
          #{$property}-#{$position-value}: $value !important;
        }
      }
    }
  }
}

.e-vertical {
  &-top {
    vertical-align: top;
  }

  &-middle {
    vertical-align: middle;
  }

  &-bottom {
    vertical-align: bottom;
  }
}

.e-border-radius {
  border-radius: $border-radius;

  &-top {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &-right {
    border-bottom-right-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &-bottom {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  &-left {
    border-bottom-left-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }

  &-top-right {
    border-top-right-radius: $border-radius;
  }

  &-bottom-right {
    border-bottom-right-radius: $border-radius;
  }

  &-bottom-left {
    border-bottom-left-radius: $border-radius;
  }

  &-top-left {
    border-top-left-radius: $border-radius;
  }
}

/* stylelint-enable declaration-no-important */
