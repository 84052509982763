@mixin e-table() {
  background-color: var(--token-tableCell-default-background);
  border-collapse: separate;
  margin: 0;
  width: 100%;

  td,
  th {
    border-bottom: 1px solid var(--token-tableCell-default-border);
    padding: $space-s $space-xs $space-s $space-s;

    &:first-child {
      padding-left: $space-m;
    }

    &:last-child {
      padding-right: $space-m;
    }
  }

  td {
    @include word-wrap;

    vertical-align: top;
  }

  th {
    font-weight: 500;
    text-align: left;
    vertical-align: bottom;

    &:focus {
      outline: none;
    }

    &.e-table__col-right {
      padding-left: $space-xs;
      padding-right: $space-s;
      text-align: right;

      &:last-child {
        padding-right: $space-s;
      }
    }
  }

  tbody {
    tr:first-child {
      &:not(.e-datagrid__row) td:first-child {
        border-top-left-radius: $border-radius - 1;
      }

      &:not(.e-datagrid__row) td:last-child {
        border-top-right-radius: $border-radius - 1;
      }
    }

    tr:last-child {
      th,
      td {
        border-bottom: 0;
      }

      &:not(.e-datagrid__row) td:first-child {
        border-bottom-left-radius: $border-radius - 1;
      }

      &:not(.e-datagrid__row) td:last-child {
        border-bottom-right-radius: $border-radius - 1;
      }
    }
  }

  tfoot {
    th,
    td {
      border-bottom: 0;
      border-top: 1px solid var(--token-tableCell-default-border);
    }
  }
}

@mixin e-table-modifier($modifier) {
  @if $modifier == "hover" {
    tbody {
      tr {
        transition: background $transition-time ease-in-out;

        &:hover td {
          background-color: var(--token-tableCell-default-backgroundHover);
        }

        &.e-table__row-active:hover td {
          background-color: var(--token-tableCell-selected-backgroundHover);
        }
      }
    }
  }

  @if $modifier == "condensed" {
    thead {
      th,
      td {
        padding: $space-xs $space-s;
      }

      th.e-table__sort {
        &::before {
          bottom: 10px;
        }
      }
    }

    tbody {
      th,
      td {
        padding: $space-xs $space-s;
        vertical-align: middle;
      }
    }
  }

  @if $modifier == "zebra" {
    tbody {
      tr:nth-child(odd) {
        background-color: var(--token-tableCell-alternate-background);
      }
    }
  }

  @if $modifier == "bordered" {
    border: 1px solid var(--token-tableCell-default-border);
  }

  @if $modifier == "inner_bordered" {
    thead {
      td,
      th {
        border: 0;
        border-bottom: 1px solid var(--token-tableCell-default-border);
        border-right: 1px solid var(--token-tableCell-default-border);

        &:last-child {
          border-right: 0;
        }
      }
    }

    tbody {
      td {
        border: 0;
        border-bottom: 1px solid var(--token-tableCell-default-border);
        border-right: 1px solid var(--token-tableCell-default-border);

        &:last-child {
          border-right: 0;
        }
      }

      tr:last-child td {
        border-bottom: 0;
      }
    }
  }

  @if $modifier == "withheader" {
    thead {
      th {
        background-color: var(--token-tableCell-default-backgroundActive);
      }
    }
  }

  @if $modifier == "modal" {
    border: 0;

    tr:last-child td {
      border-bottom: 0;
    }
  }

  @if $modifier == "verticalcentered" {
    th,
    td {
      vertical-align: middle;
    }
  }

  @if $modifier == "baselinealigned" {
    thead,
    tbody {
      th,
      td {
        vertical-align: baseline;
      }
    }
  }

  @if $modifier == "inner" {
    border: 0;

    td {
      border: 0;
      padding: $space-3xs 0;
      vertical-align: bottom;
    }
  }

  @if $modifier == "fixed" {
    table-layout: fixed;
  }
}

@mixin e-table-sort() {
  cursor: pointer;
  padding-left: $space-xl;
  position: relative;

  a {
    color: var(--token-box-default-text);
  }

  &::before {
    bottom: $space-m;
    color: var(--token-icon-default-text);
    content: var(--token-glyphs-caret-vertical);
    font-family: var(--token-icon-default-fontFamily);
    font-size: var(--token-icon-default-sizeSmall);
    height: size("icon", "small");
    left: $space-xs;
    position: absolute;
    width: size("icon", "small");
  }

  &.e-table__col-right {
    padding-left: $space-xs;

    &::before {
      bottom: -$space-2xs;
      display: inline-block;
      left: -6px;
      position: relative;
    }
  }

  &:first-child {
    padding-left: $space-xl;

    &:not(.e-table__col-right)::before {
      left: $space-xs;
    }
  }
}

@mixin e-table-sort-modifier($modifier: "asc") {
  @if $modifier == "asc" {
    &::before {
      content: var(--token-glyphs-caret-up);
    }
  }

  @if $modifier == "desc" {
    &::before {
      content: var(--token-glyphs-caret-down);
    }
  }
}
