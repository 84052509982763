e-colorinput {
  display: block;
  position: relative;

  &::after {
    color: var(--token-neutral-500);
    content: "#";
    left: $space-2xl;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &[inline]:not([inline="false"]) {
    display: inline-block;

    .e-colorinput__input {
      width: size("base", 15);
    }
  }
}

.e {
  &-colorinput {
    &__input {
      padding-left: $space-3xl;

      &:where(:read-only) {
        $style: map.get($input-styles, "default");

        @include e-input-state($style);

        cursor: pointer;

        &:where(:hover) {
          @include e-input-state($style, "hover");
        }

        &:where(:focus) {
          @include e-input-state($style, "focus");
        }
      }
    }

    &__display {
      border: 2px solid var(--token-border-strong);
      border-radius: 100%;
      height: size("base", 3);
      left: $space-xs;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: size("base", 3);

      &-invalid {
        background-color: var(--token-background-default);

        &::before {
          background: var(--token-border-strong);
          content: "";
          height: 2px;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translate(-1px, -1px) rotate(-45deg);
          width: 22px;
        }
      }

      &:where(:focus-within) {
        @include has-focus-style($offset: 0, $color: var(--token-highlight-default), $style: solid);
      }
    }

    &__native {
      border: 0;
      border-radius: 100%;
      cursor: pointer;
      height: calc(#{size("base", 3)} - 4px);
      inset: 0;
      padding: 0;
      position: absolute;
      width: calc(#{size("base", 3)} - 4px);

      &::-webkit-color-swatch-wrapper {
        padding: 0;
      }

      &::-webkit-color-swatch {
        border: 0;
        border-radius: 100%;
      }

      &::-moz-color-swatch {
        border: 0;
        border-radius: 100%;
      }

      &-invalid {
        opacity: 0;
      }
    }
  }
}
