e-slider {
  display: block;

  &[range] {
    .e-slider-bubble__handle:not(.e-slider-bubble__handle-active):not(
        .e-slider-bubble__handle-hover
      )
      ~ .e-slider-bubble__bubble {
      display: none;
    }

    .e-slider__line::before {
      background-color: var(--token-slider-marker);
    }
  }
}

.e-slider {
  margin: 0 $space-m;
  padding-bottom: 10px;
  padding-top: 66px;
  position: relative;

  &-values {
    display: flex;
    padding-top: 0;
  }

  &-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &__line {
    background-color: var(--token-slider-background);
    border-radius: $border-radius;
    color: var(--token-slider-bar);
    height: size("base", 0.5);
    position: relative;
    top: 0;

    &::before,
    &::after {
      bottom: -#{$space-3xs};
      content: "";
      height: size("base");
      position: absolute;
      width: size("base");
    }

    &::before {
      background-color: currentcolor;
      border-radius: 100%;
      left: -#{$space-m};
    }

    &::after {
      background-color: var(--token-slider-marker);
      border-radius: 100%;
      right: -#{$space-m};
    }
  }

  &__fill {
    background-color: currentcolor;
    border-radius: $border-radius;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }

  &__steps {
    height: calc(100% + 4px);
    left: 0;
    position: absolute;
    top: -2px;
    width: 100%;
  }

  &__step {
    background: var(--token-slider-step);
    height: 100%;
    position: absolute;
    top: 0;
    width: 1px;
  }

  &__label {
    @include typography("small");

    bottom: $space-xl;
    color: var(--token-slider-text);
    position: absolute;

    &-min {
      left: -$space-xs;
    }

    &-max {
      right: -$space-xs;
    }
  }

  &__value {
    @include typography("small");

    border-right: 1px solid var(--token-slider-step);
    color: var(--token-slider-text);
    flex: 1 1 100%;
    font-size: typo("small", "size");
    font-weight: typo("small", "weight");
    padding: $space-xs;
    text-align: right;

    &-min,
    &-max {
      border-right-color: transparent;
    }

    &-min {
      left: -$space-l;
      position: absolute;
      text-align: left;
    }

    &-max {
      position: relative;
      right: -$space-l;
    }
  }
}

.e-slider-bubble {
  bottom: 0;
  position: absolute;

  &:focus {
    outline: 0;
  }

  &__handle {
    @include glow(var(--token-slider-handleActive));

    align-items: center;
    background: var(--token-slider-handleBackground);
    border-radius: $border-radius;
    cursor: pointer;
    display: flex;
    height: size("base", 3);
    justify-content: center;
    width: size("base", 4);

    &-disabled {
      cursor: not-allowed;
    }

    &-hover:where(:not(.e-slider-bubble__handle-active)) {
      border: 1px solid var(--token-slider-handleActive);
    }

    &-active {
      @include has-focus-style(0, solid, 2px, var(--token-slider-handleActive));

      box-shadow: none;
    }
  }

  &__bubble {
    background-color: var(--token-slider-bubble);
    border-radius: $border-radius;
    box-shadow: var(--token-shadow-medium);
    min-width: 32px;

    &-hide {
      display: none;
    }
  }

  &__arrow {
    border: 8px solid transparent;
    border-top: 8px solid var(--token-slider-bubble);
    height: 0;
    width: 0;
  }

  &__content {
    color: var(--token-slider-bubbleText);
    padding: $space-xs $space-m;
    text-align: center;
  }
}
