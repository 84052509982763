// stylelint-disable declaration-no-important
$space-none: 0;
$space-3xs: 2px;
$space-2xs: 4px;
$space-xs: 8px;
$space-s: 12px;
$space-m: 16px;
$space-l: 24px;
$space-xl: 32px;
$space-2xl: 40px;
$space-3xl: 48px;
$space-4xl: 56px;
$space-sizes: (
  "none": $space-none,
  "3xs": $space-3xs,
  "2xs": $space-2xs,
  "xs": $space-xs,
  "s": $space-s,
  "m": $space-m,
  "l": $space-l,
  "xl": $space-xl,
  "2xl": $space-2xl,
  "3xl": $space-3xl,
  "4xl": $space-4xl,
);
