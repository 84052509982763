.e {
  &-footer {
    @include container-fixed;
    @include center-content(false);

    border-top: 1px solid color-mix(in srgb, var(--token-neutral-000) 15%, transparent);
    box-shadow: 0 -1px 0 0 color-mix(in srgb, var(--token-neutral-999) 25%, transparent);
    color: color-mix(in srgb, var(--token-neutral-000) 35%, transparent);
    height: 60px;
    max-width: map.get($screen-sizes, "large");
    min-width: map.get($screen-sizes, "small");

    &__content {
      @include typography("small");
    }
  }
}
