$node_style: (
  default: (
    var(--token-neutral-800),
    color-mix(in srgb, var(--token-neutral-800) 40%, transparent),
    var(--token-neutral-600),
  ),
  entry: (
    var(--token-green-500),
    color-mix(in srgb, var(--token-green-500) 40%, transparent),
    var(--token-green-300),
  ),
  channel: (
    var(--token-highlight-default),
    color-mix(in srgb, var(--token-highlight-default) 40%, transparent),
    var(--token-highlight-300),
  ),
  response: (
    var(--token-error-default),
    color-mix(in srgb, var(--token-error-default) 40%, transparent),
    var(--token-error-300),
  ),
);

.e {
  &-nodeicon {
    border-radius: $border-radius-small;
    color: var(--token-neutral-000);
    fill: var(--token-neutral-000);
    height: size("icon", "large");
    padding: $space-xs;
    pointer-events: none;
    width: size("icon", "large");

    &-small {
      height: size("icon", "medium");
      margin-right: $space-xs;
      padding: $space-3xs;
      vertical-align: top;
      width: size("icon", "medium");
    }

    @each $class, $data in $node_style {
      @if $class == "default" {
        background-color: list.nth($data, 1);

        &.e-nodeicon-disabled {
          background-color: list.nth($data, 3);
        }
      } @else {
        &-#{$class} {
          background-color: list.nth($data, 1);

          &.e-nodeicon-disabled {
            background-color: list.nth($data, 3);
          }

          .e-node-report & {
            background-color: list.nth($data, 2);
          }
        }
      }
    }
  }
}

@each $class, $data in $node_style {
  @if $class == "default" {
    .e-node-report .e-nodeicon {
      background-color: list.nth($data, 2);
    }
  } @else {
    .e-node-report .e-nodeicon-#{$class} {
      background-color: list.nth($data, 2);
    }
  }
}
