.e {
  &-overlay {
    background-color: color-mix(in srgb, var(--token-overlay-dark) 60%, transparent);
    border-radius: $border-radius;
    inset: 0;
    position: fixed;
    z-index: z("modal-backdrop");

    &-animation {
      transition: opacity 0.3s ease-out;
      visibility: visible;

      &_hide {
        opacity: 0;
        transition:
          opacity 0.3s ease-out,
          visibility 0s 0.3s linear;
        visibility: hidden;
      }
    }

    &-local {
      position: absolute;
      z-index: z("modal-backdrop") - 1;
    }

    &-centered {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &-inverse {
      background-color: color-mix(in srgb, var(--token-overlay-default) 80%, transparent);
    }

    &-transparent {
      background: transparent;
    }

    &-below_topnav {
      z-index: z("topnav") - 1;
    }

    &__center {
      display: flex;
      flex-direction: column;
      gap: $space-m;
    }

    &__content {
      display: inline-block;
    }
  }
}
