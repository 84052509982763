$font-path: "https://assets.emarsys.net/ui/static/fonts" !default;

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src:
    local("Roboto Light"),
    local("Roboto-Light"),
    url("#{$font-path}/roboto-v15-cyrillic-ext_latin_cyrillic_latin-ext-300.woff2") format("woff2"),
    url("#{$font-path}/roboto-v15-cyrillic-ext_latin_cyrillic_latin-ext-300.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src:
    local("Roboto"),
    local("Roboto-Regular"),
    url("#{$font-path}/roboto-v19-cyrillic_latin_cyrillic-ext_latin-ext-regular.woff2")
      format("woff2"),
    url("#{$font-path}/roboto-v19-cyrillic_latin_cyrillic-ext_latin-ext-regular.woff")
      format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  src:
    local("Roboto Italic"),
    local("Roboto-Italic"),
    url("#{$font-path}/roboto-v19-cyrillic_latin_cyrillic-ext_latin-ext-italic.woff2")
      format("woff2"),
    url("#{$font-path}/roboto-v19-cyrillic_latin_cyrillic-ext_latin-ext-italic.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src:
    local("Roboto Medium"),
    local("Roboto-Medium"),
    url("#{$font-path}/roboto-v19-cyrillic_latin_cyrillic-ext_latin-ext-500.woff2") format("woff2"),
    url("#{$font-path}/roboto-v19-cyrillic_latin_cyrillic-ext_latin-ext-500.woff") format("woff");
}

@font-face {
  font-family: "72";
  font-style: normal;
  font-weight: 300;
  src:
    local("72 Light"),
    local("72-Light"),
    url("#{$font-path}/72-Light.woff2") format("woff2"),
    url("#{$font-path}/72-Light.woff") format("woff");
}

@font-face {
  font-family: "72";
  font-style: normal;
  font-weight: 400;
  src:
    local("72 Regular"),
    local("72-Regular"),
    url("#{$font-path}/72-Regular.woff2") format("woff2"),
    url("#{$font-path}/72-Regular.woff") format("woff");
}

@font-face {
  font-family: "72";
  font-style: italic;
  font-weight: 400;
  src:
    local("72 Italic"),
    local("72-Italic"),
    url("#{$font-path}/72-Italic.woff2") format("woff2"),
    url("#{$font-path}/72-Italic.woff") format("woff");
}

@font-face {
  font-family: "72";
  font-style: normal;
  font-weight: 500;
  src:
    local("72 Bold"),
    local("72-Bold"),
    url("#{$font-path}/72-Bold.woff2") format("woff2"),
    url("#{$font-path}/72-Bold.woff") format("woff");
}

@font-face {
  font-family: "72";
  font-style: normal;
  font-weight: 700;
  src:
    local("72 Bold"),
    local("72-Bold"),
    url("#{$font-path}/72-Bold.woff2") format("woff2"),
    url("#{$font-path}/72-Bold.woff") format("woff");
}

@font-face {
  font-family: "72";
  font-style: normal;
  font-weight: 900;
  src:
    local("72 Black"),
    local("72-Black"),
    url("#{$font-path}/72-Black.woff2") format("woff2"),
    url("#{$font-path}/72-Black.woff") format("woff");
}

@font-face {
  font-family: "72 Mono";
  font-style: normal;
  font-weight: 400;
  src:
    local("72 Mono"),
    local("72-Mono"),
    url("#{$font-path}/72Mono-Regular.woff2") format("woff2"),
    url("#{$font-path}/72Mono-Regular.woff") format("woff");
}

@font-face {
  font-family: "72 Mono";
  font-style: normal;
  font-weight: 500;
  src:
    local("72 Mono Bold"),
    local("72-Mono-Bold"),
    url("#{$font-path}/72Mono-Bold.woff2") format("woff2"),
    url("#{$font-path}/72Mono-Bold.woff") format("woff");
}

@font-face {
  font-family: "72 Mono";
  font-style: normal;
  font-weight: 700;
  src:
    local("72 Mono Bold"),
    local("72-Mono-Bold"),
    url("#{$font-path}/72Mono-Bold.woff2") format("woff2"),
    url("#{$font-path}/72Mono-Bold.woff") format("woff");
}
