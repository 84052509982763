@import "../alert/styles";

.e {
  &-notice {
    @include typography("body");

    background: var(--token-box-default-background);
    border: 1px solid var(--token-box-default-border);
    border-radius: $border-radius;
    display: block;
    margin-bottom: $space-m;
    padding: $space-m $space-m $space-m $space-4xl;
    position: relative;
    text-align: left;

    &::before {
      bottom: -1px;
      color: var(--token-icon-default-text);
      content: var(--token-glyphs-info-circle);
      font-family: var(--token-icon-default-fontFamily);
      font-size: var(--token-icon-default-size);
      height: size("base", 3);
      left: $space-m;
      position: absolute;
      top: $space-m;
      width: size("base", 3);
    }

    &:empty {
      display: none;
    }

    @each $class, $data in $alert-style-token {
      $class-name: if($class == "default", "", "-#{$class}");

      &#{$class-name} {
        background-color: map.get($data, "background");
        border-color: map.get($data, "border");
        color: map.get($data, "color");
        fill: map.get($data, "color");

        .e-notice__close .e-icon {
          color: map.get($data, "color");
        }

        .e-notice__title {
          color: map.get($data, "color");
        }

        &::before {
          color: map.get($data, "fill");
          content: map.get($data, "glyphs");
        }
      }
    }

    .e-notice__icon {
      display: none;
    }

    &-withicon,
    &-withbigicon {
      padding-left: $space-4xl;
    }

    &-withclose {
      padding-right: $space-3xl;
    }

    &__close {
      cursor: pointer;
      position: absolute;
      right: $space-xs;
      top: $space-xs;
    }

    &-sticky {
      border-radius: 0;
      margin: 0 0 -1px;

      &:first-of-type {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;

        &::before {
          border-top-left-radius: $border-radius;
        }
      }

      &:last-of-type {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;

        &::before {
          border-bottom-left-radius: $border-radius;
        }
      }
    }

    &-highlight {
      background: var(--token-box-default-background);
    }

    &-fixed {
      border: 1px solid var(--token-box-default-border);
      box-shadow: var(--token-shadow-strong);
      left: 0;
      margin: auto;
      position: fixed;
      right: 0;
      top: size-unitless("shellbar", "horizonHeight") + $space-s;
      width: size("base", 75);
      z-index: z("fixed-element");
    }

    &-toast {
      border: 1px solid var(--token-box-default-border);
      bottom: $space-m;
      box-shadow: var(--token-shadow-strong);
      min-height: size("base", 11);
      position: fixed;
      right: $space-m;
      transition: all $transition-time cubic-bezier(0.25, 0.46, 0.45, 0.94);
      width: size("base", 50);
      z-index: z("modal");
    }

    &__title {
      @include typography("heading3");

      display: block;
      margin-bottom: $space-m;
      padding-top: 0;

      &-large {
        font-size: typo("title", "size");
        font-weight: normal;
        margin-top: $space-2xs;
      }

      time {
        color: var(--token-text-faint);
        display: block;
        font-size: typo("small", "size");
        font-weight: normal;
        margin-top: 0;
      }
    }

    &__footer {
      display: block;
      margin-top: $space-xs;
    }
  }
}
