.e {
  &-separator {
    background-color: var(--token-border-default);
    border: 0;
    height: 1px;
    margin: 0 0 $space-m;

    &-fullwidth {
      margin: 0 (-$space-s) $space-xl;
      width: auto;
    }

    &-condensed {
      margin-bottom: $space-s;
      margin-top: $space-s;
    }
  }
}
