$menu-hover-transition: all $transition-time ease-in-out !default;

.e {
  &-menu {
    background-color: var(--token-shellbar-actionBackground);
    float: left;

    @include screen("topnav", "down") {
      background-color: var(--token-shellbar-actionBackground);
      display: none;
      left: 0;
      position: absolute;
      top: size("shellbar", "horizonHeight");
      width: 100%;
      z-index: z("dropdown");
    }

    &__drop {
      display: none;
      margin-left: calc(var(--token-button-default-height) * -1);
      padding: 0 var(--token-button-default-height) var(--token-button-default-height);
      position: absolute;
      z-index: z("dropdown");
    }

    &__list {
      float: left;
      font-size: 0;
      list-style-type: none;
      margin: 0;
      padding: 0;

      &-child {
        background-color: var(--token-box-strong-background);
        border: 1px solid var(--token-box-strong-border);
        border-radius: $border-radius;
        box-shadow: none;
        list-style-type: none;
        margin: 0;
        max-height: calc(100vh - #{size("shellbar", "horizonHeight")});
        min-width: size("base", 28);
        overflow: auto;
        padding: 0;
        white-space: nowrap;

        &__item {
          display: block;
        }
      }
    }

    &__item {
      color: var(--token-shellbar-actionText);
      display: inline-block;
      font-weight: $text-bold-weight;
      position: relative;
      transition: $menu-hover-transition;

      &:hover {
        background-color: var(--token-shellbar-actionBackgroundHover);
        transition: none;

        &::after {
          display: none;
        }

        .e-menu__drop {
          display: block;
        }
      }

      &-active {
        background-color: var(--token-shellbar-actionBackgroundHover);
        color: var(--token-shellbar-actionText);
      }

      &-child {
        display: block;
        z-index: z("dropdown");

        &:nth-last-child {
          .e-menu__name-child {
            border-bottom: 0;
            box-shadow: none;
          }
        }
      }
    }

    &__name {
      color: inherit;
      display: inline-block;
      font-size: map.get($typography, "body", "font-size");
      line-height: size("shellbar", "horizonHeight");
      padding: 0 $space-m;
      text-decoration: none;
      transition: none;

      &:visited,
      &:hover,
      &:focus {
        color: inherit;
        text-decoration: none;
      }

      &:hover:focus {
        text-decoration: none;
      }

      &-child {
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
        font-size: map.get($typography, "body", "font-size");
        line-height: size("base", 5);
        transition: $menu-hover-transition;
        width: 100%;

        &:hover {
          background-color: var(--token-background-strong);
          transition: none;
        }
      }
    }

    &-old {
      .e-menu {
        &__name {
          padding: 0 $space-l;

          &-child {
            padding: 0 $space-l;
          }
        }
      }
    }
  }
}

// scss-lint:disable QualifyingElement
span.e-menu__name {
  cursor: default;
}
