@import "./style";

$label-selector: string.unquote(
  "+ .e-radio__label, + .e-radio__button + .e-radio__label, + label, + .e-radio__button + label"
);
$radio-selector: string.unquote("+ .e-radio__button, + .e-radio__label, + label");
$default-radio-style: map.get($radio-styles, "default");
$error-radio-style: map.get($radio-styles, "error");

e-radio {
  display: inline-block;
  line-height: normal;
}

.e-radio {
  display: inline;
  opacity: 0;
  position: absolute;

  e-radio & {
    left: 0;
    top: 0;

    &__wrapper {
      display: inline-flex;
      position: relative;
    }

    &:disabled + .e-radio__button {
      opacity: 0.4;
    }

    &,
    #{$label-selector},
    #{$radio-selector} {
      flex: 1 1 auto;
    }
  }

  #{$label-selector} {
    @include typography("body");

    color: map.get($default-radio-style, "color");
    cursor: pointer;
    display: inline-block;
    margin-right: $space-s;
    position: relative;
    user-select: none;
  }

  #{$radio-selector} {
    cursor: pointer;
    display: inline-block;
    min-height: size("base", 2);
    padding-left: size-unitless("base", 2) + $space-xs;
    position: relative;

    &::before {
      background-color: map.get($default-radio-style, "background");
      border: 1px solid map.get($default-radio-style, "border-color");
      border-radius: 18px;
      content: "";
      display: inline-block;
      height: size("base", 2);
      left: 0;
      margin-right: $space-xs;
      position: absolute;
      top: $space-2xs;
      transition: all $transition-time ease-in-out;
      width: size("base", 2);
    }

    &:hover::before {
      @include glow(map.get($default-radio-style, "hover-glow"));

      background-color: map.get($default-radio-style, "hover-background");
    }
  }

  &:focus {
    #{$label-selector} {
      color: map.get($default-radio-style, "focus-color");
    }

    #{$radio-selector} {
      &::before {
        border-color: map.get($default-radio-style, "focus-border-color");

        @include can-have-focus-style {
          @include has-focus-style(2px, solid, 2px, var(--token-outline-focus));
        }
      }
    }
  }

  &:checked {
    #{$radio-selector} {
      &::after {
        background-color: map.get($default-radio-style, "marker");
        border-radius: 10px;
        content: "";
        height: size("base");
        left: $space-2xs;
        line-height: 1;
        position: absolute;
        top: $space-2xs + $space-2xs;
        width: size("base");
      }

      &:hover::before {
        @include glow(map.get($default-radio-style, "hover-glow"));

        background-color: map.get($default-radio-style, "hover-background");
      }
    }
  }

  &:disabled {
    #{$label-selector} {
      cursor: not-allowed;
      opacity: 0.4;
    }

    #{$radio-selector} {
      cursor: not-allowed;

      &::after {
        opacity: 1;
      }

      &::before {
        background-color: map.get($default-radio-style, "background");
        opacity: 1;
      }

      &:hover::before {
        @include glow("none");

        background-color: map.get($default-radio-style, "background");
      }
    }
  }

  @each $type, $data in $radio-styles {
    @if $type != "default" {
      &-#{$type} {
        #{$label-selector} {
          color: map.get($data, "color");
        }

        #{$radio-selector} {
          &::before {
            background-color: map.get($data, "background");
            border-color: map.get($data, "border-color");
          }

          &:hover::before {
            @include glow(map.get($data, "hover-glow"));

            background-color: map.get($data, "hover-background");
          }
        }

        &:focus {
          #{$label-selector} {
            color: map.get($data, "focus-color");
          }

          #{$radio-selector} {
            &::before {
              border-color: map.get($data, "focus-border-color");
            }
          }
        }

        &:checked {
          #{$radio-selector} {
            &::after {
              background-color: map.get($data, "marker");
            }

            &:hover::before {
              @include glow(map.get($data, "hover-glow"));

              background-color: map.get($data, "hover-background");
            }
          }
        }

        &:disabled {
          #{$radio-selector} {
            &::before {
              background-color: map.get($default-radio-style, "background");
            }

            &:hover::before {
              @include glow("none");

              background-color: map.get($default-radio-style, "background");
            }
          }
        }
      }
    }
  }

  &.e-input-error {
    #{$label-selector} {
      color: map.get($error-radio-style, "color");
    }

    #{$radio-selector} {
      &::before {
        background-color: map.get($error-radio-style, "color");
        border: 1px solid map.get($error-radio-style, "border-color");
      }

      &:hover::before {
        @include glow(map.get($error-radio-style, "hover-glow"));

        background-color: map.get($error-radio-style, "hover-background");
      }
    }

    &:checked {
      #{$radio-selector} {
        &::before {
          background-color: map.get($error-radio-style, "background");
        }

        &::after {
          background-color: map.get($error-radio-style, "marker");
        }

        &:hover::before {
          background-color: map.get($error-radio-style, "hover-background");
        }
      }
    }
  }

  &-block {
    #{$label-selector} {
      display: inline-block;
    }

    &:checked {
      #{$radio-selector} {
        &::after {
          top: $space-xs;
        }
      }
    }
  }

  &-onlyradio {
    #{$radio-selector} {
      margin-right: 0;
      padding-left: size-unitless("base", 2) + $space-xs;
    }
  }
}
