.e {
  &-inplaceeditor {
    @include has-focus-state;

    &:empty::after {
      color: var(--token-text-placeholder);
      content: attr(placeholder);
      font-style: italic;
    }

    @include word-wrap;

    border: 1px solid transparent;
    border-radius: $border-radius;
    display: inline-block;
    margin: -$space-2xs;
    outline-offset: -1px;
    padding: $space-2xs;

    &:empty::before {
      content: "\200b";
    }

    &:hover {
      border-color: var(--token-highlight-default);
      outline: none;
    }

    &:focus {
      background: var(--token-background-default);
      border-color: var(--token-highlight-default);
      outline: none;
    }

    &-disabled {
      cursor: not-allowed;

      &:hover {
        border-color: var(--token-border-default);
        outline: none;
      }
    }

    &-error {
      @include e-input-modifier("error");

      color: var(--token-text-default);

      &:hover {
        border-color: var(--token-error-default);
      }
    }

    &__buttons {
      display: inline-block;
      line-height: 1;
      margin: 0 $space-s;
      outline: 0;
      position: relative;
      top: -1px;
      vertical-align: middle;

      .e-btn {
        margin: 0 $space-2xs;
        vertical-align: top;
      }
    }
  }
}
