$canvas-content-box-offset: 259px !default;
$canvas-content-box-offset-iframe: 95px !default;
$canvas-sidebar-box-offset: 208px !default;
$canvas-ac-box-nodebar-width: 250px !default;
$canvas-vcms-box-width: 700px !default;
$canvas-sidebar-box-offset-socialads: 145px !default;

.e {
  &-canvas {
    @include clearfix;

    background: var(--token-neutral-000);
    border-color: var(--token-border-default);

    &__content {
      height: calc(100vh - #{$canvas-content-box-offset});

      &-iframe {
        height: calc(100vh - #{$canvas-content-box-offset-iframe});
      }

      &-half {
        float: left;
        width: 50%;
      }
    }

    &__sidebar {
      height: calc(100vh - #{$canvas-sidebar-box-offset});
      position: relative;
      width: 100%;

      &-socialads {
        height: calc(100vh - #{$canvas-sidebar-box-offset-socialads});
      }

      &__content {
        display: block;
        height: 100%;
        overflow: auto;
        position: absolute;
        width: 100%;
      }
    }

    &__preview {
      bottom: 0;
      left: $canvas-vcms-box-width;
      position: absolute;
      right: 0;
      top: 0;
    }

    &__cms {
      bottom: 0;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: $canvas-vcms-box-width;
    }
  }
}
