@import "styles";

@mixin e-input {
  @include typography("body");

  background-position-y: 2px;
  background-repeat: no-repeat;
  border-radius: $border-radius-small;
  display: inline-block;
  height: var(--token-input-default-height);
  padding: 0 $space-s;
  position: relative;
  text-overflow: ellipsis;
  vertical-align: middle;
  width: 100%;

  &::placeholder,
  &__placeholder {
    font-style: italic;
  }

  &::-webkit-search-cancel-button {
    @include search-input-cancel-legacy;
  }
}

@mixin e-input-search($styles: $input-styles) {
  $style: map.get($styles, "default");

  @include typography("body");

  background-color: map.get($style, "background-color");
  background-image: url("horizon/search.svg?token=morningHorizon,text-default");
  background-position: calc(100% - #{$space-m}) center;
  background-repeat: no-repeat;
  background-size: 16px;
  border: map.get($style, "border");
  border-radius: size("base", 5);
  display: inline-block;
  height: var(--token-input-default-height);
  padding: 0 $space-2xl 0 $space-m;
  text-overflow: ellipsis;
  vertical-align: middle;
  width: 100%;

  @include flipper-on("theme-eveningHorizon") {
    background-image: url("horizon/search.svg?token=eveningHorizon,text-default");
  }

  &:hover:where(:not(:disabled, :read-only)),
  &:focus {
    background-color: map.get($style, "focus-background-color");
  }

  &:focus {
    outline: map.get($style, "focus-outline");
    outline-offset: -2px;
  }

  &:disabled {
    opacity: 0.4;
  }

  &::placeholder {
    color: var(--token-text-placeholder);
    font-style: italic;
    font-weight: normal;
    width: 100%;
  }

  &::-webkit-search-cancel-button {
    @include search-input-cancel-legacy;

    margin-left: $space-xs;
    margin-right: 0;
  }
}

@mixin e-input-state($style, $state: null, $interactivity: null, $background: null) {
  $has-state: $state != null;
  $prefix: if($has-state, #{$state + "-"}, "");
  $background-image: map.get($style, #{$prefix + "background-image"});
  $background-position: center calc(100% + 1px);

  background-color: map.get($style, #{$prefix + "background-color"});
  background-position: $background-position;
  background-repeat: repeat-x;
  background-size: 100% 2px;
  border: map.get($style, #{$prefix + "border"});
  border-bottom: map.get($style, #{$prefix + "border-bottom"});
  outline-offset: -2px;

  @if $state == "focus" {
    outline: map.get($style, #{$prefix + "outline"});
  }

  @if $interactivity == "readonly" {
    background-color: map.get($style, #{$prefix + "readonly-background-color"});

    $background-image: repeating-linear-gradient(
      90deg,
      map.get($style, #{"readonly-border-color"}) 0,
      map.get($style, #{"readonly-border-color"}) 8px,
      transparent 8px,
      transparent 12px
    );

    border: 1px solid transparent;
  }

  @if not $background {
    background-image: $background-image;
  } @else {
    background-image: map.get($background, "image"), $background-image;

    @if map.get($background, "position") {
      background-position: map.get($background, "position"), $background-position;
    }

    @if map.get($background, "repeat") {
      background-repeat: map.get($background, "repeat"), repeat-x;
    }

    @if map.get($background, "size") {
      background-size:
        map.get($background, "size"),
        100% 2px;
    }
  }

  @if $interactivity == "disabled" {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

@mixin e-input-style($style-name: "default", $styles: $input-styles, $background: null) {
  $style: map.get($styles, $style-name);

  @include e-input-state($style: $style, $background: $background);

  color: map.get($style, "text");

  &::placeholder,
  &__placeholder {
    color: var(--token-text-placeholder);
    font-style: italic;
    font-weight: normal;
  }

  &:where(:hover) {
    @include e-input-state($style: $style, $state: "hover", $background: $background);
  }

  &:where(:focus),
  &-focus {
    @include e-input-state($style: $style, $state: "focus", $background: $background);
  }

  &:where([readonly]),
  &-readonly {
    @include e-input-state($style: $style, $interactivity: "readonly", $background: $background);
  }

  &:where(:disabled),
  &-disabled {
    @include e-input-state($style: $style, $interactivity: "disabled", $background: $background);

    cursor: not-allowed;
  }
}

@mixin e-input-size($_size: "large") {
  width: size("input", $_size);
}

@mixin e-input-old {
  @include typography("body");
  @include has-focus-state;

  background: var(--token-background-default);
  border: 1px solid var(--token-border-default);
  color: var(--token-text-default);
  display: inline-block;
  height: size("base", 5);
  padding: 0 $space-s;
  position: relative;
  text-overflow: ellipsis;
  transition:
    border $transition-time ease-in-out,
    box-shadow $transition-time ease-in-out;
  vertical-align: middle;
  width: 100%;

  &::placeholder {
    color: var(--token-text-placeholder);
    font-style: italic;
  }

  &:focus {
    @include e-input-modifier("focus");
  }

  &:disabled {
    @include e-input-modifier("disabled");
  }

  &:read-only {
    &:focus {
      border-color: var(--token-border-default);
    }
  }
}

@mixin e-input-modifier($modifier: "default") {
  @if $modifier == "error" {
    background-color: var(--token-error-100);
    border: 1px solid var(--token-error-default);
    color: var(--token-error-default);

    &::placeholder {
      color: var(--token-text-placeholder);
    }

    &:focus,
    &:read-only:focus {
      border-color: var(--token-error-default);
    }
  }

  @if $modifier == "disabled" {
    background: var(--token-neutral-200)
      url("horizon/ban.svg?token=morningHorizon,neutral-default")
      no-repeat
      center
      right
      7.5px;
    background-size: size("icon", "small");
    color: var(--token-text-faint);
    cursor: not-allowed;
    padding-right: $space-l;

    @include flipper-on("theme-eveningHorizon") {
      background: var(--token-neutral-200)
        url("horizon/ban.svg?token=eveningHorizon,neutral-default") no-repeat center right 7.5px;
      background-size: size("icon", "small");
    }

    &:focus {
      border-color: var(--token-border-default);
    }
  }

  @if $modifier == "code" {
    background: var(--token-neutral-200);

    &:focus {
      border-color: var(--token-border-default);
    }
  }

  @if $modifier == "textarea" {
    height: size("base", 12);
    line-height: inherit;
    padding-bottom: $space-xs;
    padding-top: $space-xs;
    resize: none;
  }

  @if $modifier == "autoheight" {
    height: auto;
  }

  @if $modifier == "focus" {
    border-color: var(--token-highlight-default);
  }
}

@mixin search-input-cancel {
  appearance: none;
  background-color: transparent;
  background-size: cover;
  border: 0;
  color: transparent;
  cursor: pointer;
  height: size("icon", "small");
  margin-left: $space-2xs;
  margin-right: -$space-2xs;
  position: relative;
  width: size("icon", "small");

  &::before {
    color: var(--token-text-faint);
    content: var(--token-glyphs-close-circle);
    font-family: var(--token-icon-default-fontFamily);
    font-size: var(--token-icon-default-sizeSmall);
    left: 0;
    line-height: 1;
    position: absolute;
    top: 0;
  }
}

@mixin search-input-cancel-legacy {
  appearance: none;
  background-color: transparent;
  background-image: url("horizon/e-times-circle.svg?token=morningHorizon,text-faint");
  background-size: cover;
  border: 0;
  color: transparent;
  cursor: pointer;
  height: size("icon", "small");
  margin-left: $space-2xs;
  margin-right: -$space-2xs;
  position: relative;
  width: size("icon", "small");

  @include flipper-on("theme-eveningHorizon") {
    background-image: url("horizon/e-times-circle.svg?token=eveningHorizon,text-faint");
  }
}
