.e {
  &-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &__item {
      padding-bottom: $space-xs;

      &__title {
        @include typography("heading3");

        display: block;
      }

      &-node {
        cursor: pointer;
        margin: 0 (-$space-s) $space-3xs;
        padding: $space-2xs $space-2xs $space-2xs $space-xl;

        &:hover {
          background-color: var(--token-background-strong);
        }

        &-disabled {
          opacity: 0.5;
          pointer-events: none;

          &:hover {
            background-color: transparent;
          }
        }
      }

      &-has_icon {
        margin-bottom: $space-m;
        min-height: size("base", 5);
        padding-left: $space-4xl;
        position: relative;

        e-icon,
        .e-icon {
          color: var(--token-text-faint);
          font-size: var(--token-icon-default-sizeLarge);
          height: var(--token-icon-default-sizeLarge);
          left: 0;
          position: absolute;
          top: 0;
          width: var(--token-icon-default-sizeLarge);
        }
      }
    }

    &-indented {
      padding-left: $space-l;
    }

    &-bulleted {
      list-style-type: disc;
      padding-left: $space-l;
    }

    &-numbered {
      counter-reset: e-counter;
      padding-left: $space-xl;

      .e-list__item {
        position: relative;

        &::before {
          background-color: var(--token-green-500);
          border-radius: $border-radius;
          color: var(--token-text-light);
          content: counter(e-counter);
          counter-increment: e-counter;
          left: -$space-l - 4;
          padding: 0 $space-xs;
          position: absolute;
          top: 0;
        }
      }

      &-gray {
        .e-list__item {
          &::before {
            background-color: var(--token-neutral-400);
            border-radius: 100%;
          }
        }
      }
    }

    &-checked {
      list-style: none;
      padding-left: $space-l;

      .e-list__item {
        position: relative;

        &::before {
          color: var(--token-text-default);
          content: var(--token-glyphs-checkmark);
          font-family: var(--token-icon-default-fontFamily);
          font-size: var(--token-icon-default-sizeSmall);
          height: size("base", 2);
          left: -($space-l - 4);
          position: absolute;
          top: 0;
          width: size("base", 2);
        }
      }
    }
  }
}
