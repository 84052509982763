@import "../../style/components/input/assets";

.e-suggest-multiple-input {
  $style: map.get($input-styles, "default");

  cursor: text;
  display: grid;
  min-height: 36px;

  &__background {
    @include e-input;
    @include e-input-style($styles: $input-styles);

    grid-area: 1 / 1;
    height: auto;
    padding-block: $space-2xs;
  }

  &:where(:hover) .e-suggest-multiple-input__background {
    &:not(.e-suggest-multiple-input__background-disabled) {
      @include e-input-state($style, "hover");
    }
  }

  &:where(:focus-within) .e-suggest-multiple-input__background {
    &:not(.e-suggest-multiple-input__background-disabled) {
      @include e-input-state($style, "focus");
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: $space-2xs;
    grid-area: 1 / 1;
    padding: 6px $space-s $space-2xs;
    position: relative;
  }

  &__suggest {
    display: inline-flex;
    flex: 1 1 auto;

    @at-root :where(&) .e-suggest-input__wrapper {
      display: flex;
      flex: 1 1 auto;
    }

    input {
      background: none;
      border: 0;
      flex: 1 1 auto;
      height: 24px;
      padding: 0;

      &:disabled {
        opacity: 0.5;
      }
    }
  }
}
