.e-tooltip-content {
  &-focus {
    @include has-focus-style(2px);
  }
}

.e-tooltip-popup {
  max-width: 320px;
  padding-bottom: $space-xs;
  padding-top: $space-xs;
}
