$steps-maxsteps: 7 !default;

.e {
  &-steps {
    @include clearfix;

    align-items: flex-start;
    background-color: transparent;
    bottom: $space-s;
    display: flex;
    font-weight: $text-bold-weight;
    height: size("base", 8);
    justify-content: center;
    left: 0;
    margin: 0 0 0 size("menu", "width");
    pointer-events: none;
    position: fixed;
    width: calc(100% - #{size("menu", "width")});
    z-index: z("fixed-element");

    .e-layout-without_navigation & {
      margin: 0;
      width: 100%;
    }

    &-in {
      animation: steps-in 0.3s;
      animation-delay: 1.5s;
      animation-fill-mode: forwards;
      bottom: -#{size-unitless("steps", "height") + $space-s};
    }

    &__progress {
      align-items: center;
      background-color: var(--token-box-default-background);
      border: 1px solid var(--token-box-default-border);
      border-radius: $border-radius;
      box-shadow: var(--token-shadow-light);
      counter-reset: progressStep;
      display: flex;
      flex: 0 1 auto;
      font-size: 0;
      height: size("base", 8);
      justify-content: center;
      margin: 0;
      overflow: hidden;
      padding: $space-xs (size-unitless("base", 6) + $space-2xs);
      pointer-events: all;

      @include screen("topnav", "down") {
        padding: $space-xs (size-unitless("base", 6) + $space-2xs);
      }

      @include screen("small", "down") {
        padding: 0 $space-xs;
      }
    }

    &__item,
    &__item:visited,
    &__item:hover {
      color: var(--token-text-light);
    }

    &__item {
      @include typography("body");

      align-items: center;
      background-color: var(--token-box-default-background);
      border: 1px solid transparent;
      border-radius: $border-radius;
      color: var(--token-box-default-text);
      cursor: pointer;
      display: flex;
      flex: 0 1 auto;
      font-weight: 500;
      height: size("base", 6);
      justify-content: center;
      line-height: $space-m;
      margin-right: $space-2xl;
      padding: 0 $space-m 0 $space-xs;
      position: relative;
      transition:
        background 0.3s,
        border 0.3s;
      vertical-align: middle;
      white-space: nowrap;

      @include screen("topnav", "down") {
        padding: 0 $space-xs;

        &:first-child,
        &:last-child {
          padding: 0 $space-xs;
        }

        &::before {
          content: none;
        }
      }

      @include screen("small", "down") {
        white-space: normal;
      }

      &-action {
        @include has-focus-state;

        cursor: pointer;

        &:hover {
          background-color: var(--token-neutral-700);
          color: var(--token-text-light);
        }
      }

      &:visited {
        color: var(--token-box-default-text);
      }

      &:first-child {
        &::before {
          height: size("base", 5);
          left: $space-xs;
          width: size("base", 5);
        }
      }

      &:last-child {
        margin: 0;

        &::after {
          display: none;
        }
      }

      &:hover {
        background-color: var(--token-background-faint);
        color: var(--token-box-default-text);

        &::before {
          background-color: var(--token-background-strong);
        }
      }

      @for $i from 1 through $steps-maxsteps {
        &:nth-child(#{$i}) {
          z-index: $steps-maxsteps - $i + 1;
        }
      }

      &::before {
        align-items: center;
        background-color: var(--token-background-faint);
        border-radius: 50%;
        color: var(--token-box-default-text);
        content: counter(progressStep);
        counter-increment: progressStep;
        display: flex;
        flex: 0 0 auto;
        height: size("base", 5);
        justify-content: center;
        left: $space-xl;
        line-height: size("base", 3);
        margin-right: $space-xs;
        position: static;
        top: $space-s;
        transition:
          background 0.3s,
          border 0.3s;
        width: size("base", 5);

        @include screen("small", "down") {
          display: none;
        }
      }

      &::after {
        background-color: var(--token-neutral-300);
        border: 0;
        content: "";
        height: 1px;
        left: calc(100% + 1px);
        position: absolute;
        top: 50%;
        width: size("base", 5);
        z-index: -1;
      }

      &-current {
        background-color: var(--token-highlight-200);
        color: var(--token-box-default-text);
        cursor: default;

        &::before {
          background-color: var(--token-highlight-300);
          color: var(--token-box-default-text);
        }

        &:hover,
        &:visited {
          background-color: var(--token-highlight-200);
          color: var(--token-box-default-text);

          &::before {
            background-color: var(--token-highlight-300);
            color: var(--token-box-default-text);
          }
        }
      }

      &-disabled {
        background-color: var(--token-box-default-background);
        color: var(--token-text-faint);
        cursor: default;

        &::before {
          background-color: var(--token-background-faint);
          color: var(--token-text-faint);
        }

        &:hover,
        &:visited {
          background-color: var(--token-box-default-background);
          color: var(--token-text-faint);

          &::before {
            background-color: var(--token-background-faint);
            color: var(--token-text-faint);
          }
        }
      }
    }

    &__badge {
      align-items: center;
      background-color: var(--token-label-error-background);
      border-radius: 50px;
      color: var(--token-label-error-text);
      display: inline-flex;
      font-size: 12px;
      font-weight: bold;
      height: size("base", 2);
      justify-content: center;
      margin: 0 $space-3xs;
      min-width: size("base", 2);
      padding: $space-xs #{$space-2xs + 1};
      pointer-events: none;
      position: relative;
      top: -#{$space-xs};
      width: auto;
    }

    &__nav,
    &__nav:visited {
      color: var(--token-text-default);
    }

    &__nav {
      @include typography("small");

      align-items: center;
      background: transparent;
      border: 0;
      border-radius: $border-radius;
      box-shadow: none;
      cursor: pointer;
      display: flex;
      flex: 0 0 auto;
      font-weight: 500;
      height: size("base", 6);
      justify-content: center;
      margin-top: $space-xs;
      padding: 0;
      pointer-events: all;
      transition: background 0.3s;
      user-select: none;
      width: size("base", 4);
      z-index: $steps-maxsteps + 1;

      @include screen("topnav", "down") {
        position: static;
        width: 50px;

        span {
          display: none;
        }
      }

      @include screen("small", "down") {
        display: none;
      }

      .e-icon {
        color: var(--token-box-default-text);
        font-size: var(--token-icon-default-sizeSmall);
        height: var(--token-icon-default-sizeSmall);
        margin: 0;
        width: var(--token-icon-default-sizeSmall);
      }

      e-icon .e-icon {
        margin-top: 0;
      }

      &-prev {
        @include has-focus-state;

        margin-right: - size-unitless("base", 5) - $space-2xs;

        // scss-lint:disable DeclarationOrder
        @include screen("topnav", "down") {
          left: 0;

          .e-icon {
            margin-left: -$space-2xs;
            margin-right: -$space-2xs;
          }
        }
      }

      &-next {
        @include has-focus-state;

        margin-left: - size-unitless("base", 5) - $space-2xs;
        order: 1;

        // scss-lint:disable DeclarationOrder
        @include screen("topnav", "down") {
          right: 0;

          .e-icon {
            margin-left: -$space-2xs;
            margin-right: -$space-2xs;
          }
        }
      }

      &:hover {
        background-color: var(--token-background-faint);
        color: var(--token-box-default-text);
      }

      &-disabled,
      &-disabled:visited {
        color: var(--token-text-faint);
        cursor: default;
      }

      &-disabled {
        cursor: default;

        .e-icon {
          color: var(--token-text-faint);
        }

        &:hover {
          background: transparent;
          color: var(--token-text-faint);

          .e-icon {
            color: var(--token-text-faint);
          }
        }
      }

      span {
        display: none;
      }
    }
  }
}

@keyframes steps-in {
  to {
    bottom: $space-s;
  }
}
