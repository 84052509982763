@mixin e-table-sort-old() {
  cursor: pointer;

  a {
    color: var(--token-text-default);
  }

  &::after {
    color: var(--token-icon-default-text);
    content: var(--token-glyphs-caret-vertical);
    display: inline-block;
    font-family: var(--token-icon-default-fontFamily);
    font-size: var(--token-icon-default-sizeSmall);
    height: size("icon", "small");
    left: $space-2xs;
    position: relative;
    top: $space-3xs;
    vertical-align: top;
    width: size("icon", "small");
  }
}

@mixin e-table-sort-modifier-old($modifier: "asc") {
  @if $modifier == "asc" {
    &::after {
      color: var(--token-icon-default-text);
      content: var(--token-glyphs-caret-up);
      display: inline-block;
      font-family: var(--token-icon-default-fontFamily);
      font-size: var(--token-icon-default-sizeSmall);
      height: size("base", 2);
      width: size("base", 2);
    }
  }

  @if $modifier == "desc" {
    &::after {
      color: var(--token-icon-default-text);
      content: var(--token-glyphs-caret-down);
      display: inline-block;
      font-family: var(--token-icon-default-fontFamily);
      font-size: var(--token-icon-default-sizeSmall);
      height: size("base", 2);
      width: size("base", 2);
    }
  }
}
