.e {
  &-fieldset {
    border: 1px solid var(--token-border-default);
    border-radius: $border-radius;
    margin-bottom: $space-s;
    padding: 0;

    &-chart {
      border: 0;
      margin-bottom: 0;
    }

    &-legend-top {
      position: absolute;
      top: 0;
      width: 100%;
    }

    &-legend-bottom {
      border-top: 1px solid var(--token-border-default);
      margin-bottom: -1px;
    }

    &-bottom {
      border-top: 1px solid var(--token-border-default);
      bottom: -1px;
      position: absolute;
      width: 100%;
    }

    &-sidebar {
      border-color: var(--token-border-default);
      border-left: 0;
      height: size("base", 30);
      margin-bottom: 0;
      overflow: auto;

      .e-fieldset__item {
        border-left: 1px solid var(--token-border-default);
        padding-right: $space-l;
        position: static;
        white-space: nowrap;

        &-chart-active {
          position: relative;
        }

        &:last-child {
          margin-bottom: -1px;
        }
      }
    }

    &__item {
      cursor: pointer;
      padding: $space-s $space-s 0;
      position: relative;

      &-header {
        border-bottom: 1px solid var(--token-border-default);
        margin-bottom: -1px;
      }

      &:last-child {
        padding-bottom: $space-s;
      }

      &:first-of-type {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;

        .e-fieldset__item__indicator {
          border-top-right-radius: $border-radius;
        }
      }

      &:last-of-type {
        border: none;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;

        .e-fieldset__item__indicator {
          border-bottom-right-radius: $border-radius;
        }
      }

      &-chart {
        background: var(--token-background-strong);
        border-bottom: 1px solid var(--token-border-default);
        padding: $space-xs;
        position: relative;

        &:last-child {
          padding-bottom: $space-xs;
        }

        &-active {
          background-color: var(--token-background-default);
          margin-left: 0;
          padding-left: $space-xs + 1; // -1 margin eq
        }
      }

      &__indicator {
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: #{size-unitless("base", 0.5)}px;
      }

      .e-checkbox + .e-legend {
        display: inline-block;

        &::before,
        &::after {
          top: 50%;
          transform: translateY(-50%);
        }

        .e-legend__value {
          margin-bottom: $space-2xs;
        }
      }
    }
  }
}
