.ui-tooltip {
  background: var(--token-box-strong-background);
  border: 1px solid var(--token-box-strong-border);
  border-radius: $border-radius;
  box-shadow: var(--token-shadow-medium);
  max-width: size("base", 38);
  padding: $space-xs $space-m;
  position: absolute;
  z-index: z("tooltip");
}
