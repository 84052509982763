@keyframes skeleton-opacity {
  0% {
    background-color: color-mix(in srgb, var(--token-background-strong) 60%, transparent);
  }

  100% {
    background-color: var(--token-background-strong);
  }
}

.e-skeleton {
  animation-delay: 0s;
  animation-direction: alternate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: skeleton-opacity;
  animation-timing-function: steps(8, end);
  border-radius: 8px;
  display: inline-block;
  height: size("base", 1.5);
  max-width: size("base", 30);
  min-width: size("base", 8);
  vertical-align: middle;
  width: 30%;

  &-button {
    margin: 16px;
  }

  &-xs {
    min-width: auto;
    width: size("base", 1.5);
  }

  &-s {
    min-width: auto;
    width: size("base", 2);
  }

  &-m {
    min-width: auto;
    width: size("base", 4);
  }

  &-l {
    min-width: auto;
    width: size("base", 12);
  }
}
