// stylelint-disable declaration-no-important

.e {
  &-select {
    &__select2 {
      z-index: z("default");

      &-relative {
        overflow: visible;

        .select2-choices {
          background: transparent;
        }

        .select2-drop {
          margin-left: -1px;
          width: calc(100% + 2px);

          .select2-result-single {
            padding: 1px 0 0;
          }
        }
      }

      &.select2-container,
      &.select2-container-multi {
        @include e-input-style($styles: $input-styles);

        display: inline-flex;

        &.e-select-borderless {
          background-image: none;
        }

        &.e-select-error {
          @include e-input-style("error", $styles: $input-styles);
        }

        &::after {
          color: var(--token-icon-default-text);
          content: var(--token-glyphs-angle-down);
          font-family: var(--token-icon-default-fontFamily);
          font-size: var(--token-icon-default-sizeSmall);
          font-weight: normal;
          pointer-events: none;
          position: absolute;
          right: 16px;
          top: 6px;
        }

        &.e-select-borderless::after {
          color: var(--token-highlight-default);
        }

        &-active {
          border-color: var(--token-neutral-default);

          &.e-select-error {
            border-color: var(--token-error-default);
          }

          &.e-select-borderless {
            border-color: var(--token-highlight-default);
          }
        }

        &-disabled {
          background-color: var(--token-neutral-200);
          cursor: not-allowed;

          .select2-chosen {
            color: var(--token-text-faint);
          }
        }

        .select2-choices {
          border: 0 !important;
          flex: 1 1 100%;
          line-height: var(--token-button-default-height);
        }

        .select2-choice {
          background: transparent !important;
          background-image: none !important;
          border: 0 !important;
          border-radius: 0 !important;
          box-shadow: none !important;
          color: var(--token-text-default) !important;
          flex: 1 1 100%;
          height: size-unitless("base", 5) - 2px !important;
          line-height: size-unitless("base", 5) - 2px !important;
          padding: 0 $space-m !important;
          text-decoration: none !important;

          &.select2-default {
            color: var(--token-neutral-300);
            font-style: italic;
          }

          > .select2-chosen {
            margin-right: $space-m;
          }

          .select2-arrow {
            background: none !important;
            border: 0;

            b {
              background: transparent !important;
            }
          }

          &:active,
          &:focus,
          &:hover {
            color: var(--token-text-default) !important;
          }
        }
      }

      &.e-select-borderless > .select2-choice {
        color: var(--token-highlight-default) !important;
      }

      &.select2-container-multi {
        height: auto;
        position: relative;
        white-space: normal;

        .select2-choices {
          line-height: #{size-unitless("base", 5) - size-unitless("base", 1)}px;

          .select2-search-choice {
            @include e-label;

            border: 0;
            box-shadow: none;
            height: auto;
            margin: $space-2xs 0 0 $space-2xs !important;
            padding-right: $space-xl !important;
            text-align: left !important;
            white-space: normal !important;

            > div {
              @include word-wrap;
            }
          }

          .select2-search-choice-focus {
            background-color: var(--token-error-faint);

            &:hover {
              background-color: var(--token-error-faint) !important;
            }
          }

          .select2-search-choice-close {
            @include e-label-close;

            background: url("horizon/close.svg?token=morningHorizon,text-default") 4px center / 16px
              16px no-repeat !important;
            height: 100%;
            left: auto;
            right: $space-xs;
            top: 0;
            width: size("base", 3);

            @include flipper-on("theme-eveningHorizon") {
              background: url("horizon/close.svg?token=eveningHorizon,text-default") 4px center /
                16px 16px no-repeat !important;
            }
          }
        }
      }

      .ui-select-offscreen {
        border: 0 !important;
        clip: rect(0 0 0 0) !important;
        height: 1px !important;
        left: 0 !important;
        margin: 0 !important;
        outline: 0 !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        top: 0 !important;
        width: 1px !important;
      }
    }
  }
}

.select2-drop .select2-search {
  margin-bottom: $space-s;
  padding: 0 !important;

  input {
    // scss-lint:disable ImportantRule
    @include e-input-old;

    background-image: url("horizon/search.svg?token=morningHorizon,text-default") !important;
    background-position: $space-xs center !important;
    background-repeat: no-repeat;
    background-size: size("base", 3) !important;
    border-radius: $border-radius;
    height: size("base", 5) !important;
    padding-left: $space-2xl !important;

    @include flipper-on("theme-eveningHorizon") {
      background-image: url("horizon/search.svg?token=eveningHorizon,text-default") !important;
    }
  }
}

.select2-drop .select2-results {
  // scss-lint:disable ImportantRule,ColorVariable
  margin: 0 !important;
  padding: 0 !important;

  .select2-highlighted {
    background: var(--token-neutral-100) !important;
    color: var(--token-text-default);
  }

  .select2-disabled {
    background: var(--token-neutral-100);
    background-image: linear-gradient(
      color-mix(var(--token-neutral-000) 50%, transparent),
      color-mix(var(--token-neutral-000) 50%, transparent)
    ) !important;
    color: var(--token-text-faint) !important;

    .select2-result-label {
      cursor: default !important;
    }
  }

  .select2-result-label {
    min-height: size("base", 4);
    padding: $space-xs $space-xs $space-xs $space-2xl;
  }

  .select2-selected-option {
    background-color: var(--token-highlight-200) !important;
    background-image: url("horizon/check.svg?token=morningHorizon,highlight-default") !important;
    background-position: $space-xs center !important;
    background-repeat: no-repeat !important;
    background-size: size("base", 3) !important;
    color: var(--token-highlight-default) !important;

    @include flipper-on("theme-eveningHorizon") {
      background-image: url("horizon/check.svg?token=eveningHorizon,highlight-default") !important;
    }

    &.select2-highlighted {
      background-color: var(--token-highlight-300) !important;
      background-size: size("base", 3) !important;
    }
  }

  li {
    border-radius: $border-radius;
    max-width: none;

    &.select2-no-results {
      align-items: center;
      background-color: var(--token-box-strong-background);
      color: var(--token-text-default);
      display: flex;
      min-height: size("base", 4);
      padding: $space-xs $space-xs $space-xs $space-s;
    }
  }
}

.select2-result-label {
  @include word-wrap;
}

.select2-choices {
  background-image: none !important;
  line-height: size("base", 5);
}

.select2-drop {
  // scss-lint:disable ImportantRule
  background-color: var(--token-box-strong-background);
  border-radius: $border-radius !important;
  color: var(--token-text-default);
  margin-top: $space-s !important;
  padding: $space-m;

  &-active {
    border: 1px solid var(--token-border-default) !important;
    box-shadow: var(--token-shadow-medium) !important;

    &.select2-drop-above {
      border: 1px solid var(--token-border-default) !important;
      box-shadow: var(--token-shadow-medium) !important;
      margin-top: -$space-s !important;

      &::after {
        border: 0;
        border-bottom: 1px solid var(--token-border-default);
        border-right: 1px solid var(--token-border-default);
        bottom: -#{$space-xs - 2px};
        top: auto;
      }
    }
  }

  &::after {
    background-color: var(--token-background-default);
    border-left: 1px solid var(--token-border-default);
    border-top: 1px solid var(--token-border-default);
    box-shadow: none;
    content: "";
    height: size("base", 1.25);
    left: 50%;
    position: absolute;
    top: -#{$space-xs - 2px};
    transform: translateX(-50%) rotate(45deg);
    width: size("base", 1.25);
  }
}

div.e-select__select2 {
  padding: 0;
}
