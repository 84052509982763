$e-device-sms-assets: "https://assets.emarsys.net/ui/static/images/e-device";

.e {
  &-device-sms {
    background-color: transparent;
    background-image: url(#{$e-device-sms-assets}/e-device-sms-ios10.svg);
    background-repeat: no-repeat;
    height: 100%;
    position: relative;

    &__content {
      margin: 16px;
      max-width: 100%;
      position: absolute;
      top: 65px;
    }

    &__fromname {
      background: transparent;
      border: 3px solid transparent;
      font-size: 15px;
      margin: 0 60px;
      padding: 0;
      position: absolute;
      text-align: center;
      top: 32px;
      width: calc(100% - 120px);

      &:hover,
      &-editable {
        border: 3px dashed var(--token-neutral-500);
      }

      &:disabled {
        border-color: transparent;
      }

      &::placeholder {
        color: var(--token-text-placeholder);
        font-style: italic;
      }
    }

    &__message {
      background-color: #e5e5ea;
      border-radius: 17px;
      font-size: 17px;
      line-height: 20px;
      margin-right: 80px;
      padding: 12px;

      &:hover,
      &-editable {
        outline: 3px dashed var(--token-neutral-500);
      }

      &-error {
        &,
        &:hover {
          outline: 3px dashed var(--token-error-default);
        }
      }

      &-disabled {
        &,
        &:hover {
          outline-color: transparent;
        }
      }
    }

    &__counter {
      margin-top: 5px;
    }
  }
}
