*,
*::before,
*::after {
  box-sizing: border-box;
}

*::selection {
  background-color: var(--token-highlight-300);
}

html,
body {
  @include typography("body");

  background-color: var(--token-background-faint);
  color: var(--token-text-default);
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

hr {
  background-color: var(--token-separator-line);
  border: 0;
  height: 1px;
}

// scss-lint:disable QualifyingElement
input[type="text"] {
  &::-ms-clear {
    display: none;
  }
}

h1,
%h1 {
  @include typography("heading1");

  color: var(--token-text-default);
  margin: 0 0 $space-xs;
}

h2,
%h2 {
  @include typography("heading2");

  margin-bottom: $space-m;
  margin-top: 0;
}

h3,
%h3 {
  @include typography("heading3");

  margin-bottom: $space-m;
  margin-top: 0;
}

h4,
%h4 {
  @include typography("heading4");

  margin-bottom: $space-m;
}

h1,
h2,
h3 {
  a,
  a:active,
  a:visited,
  a:hover {
    color: inherit;
  }
}

p {
  margin-top: 0;
}

b,
strong {
  font-weight: 500;

  font & {
    font-weight: 500;
  }

  [contenteditable="true"] & {
    // <Chrome45 bug
    -webkit-font-smoothing: initial;
    font-weight: 500;
  }
}

a {
  @include has-focus-state($space-3xs);

  color: var(--token-link-default-text);

  &,
  &:visited {
    text-decoration: none;
  }

  &:visited {
    color: var(--token-link-visited-text);
  }

  &:active {
    color: var(--token-link-default-textActive);
    text-decoration: none;

    &:where(:visited) {
      color: var(--token-link-visited-textActive);
    }
  }

  &:hover,
  &:focus {
    color: var(--token-link-default-textHover);
    outline: none;
    text-decoration: none;

    &:where(:visited) {
      color: var(--token-link-visited-textHover);
    }
  }

  &,
  &:hover,
  &:focus,
  &:visited {
    &:where(:not([class^="e-"]):not([class*=" e-"])) {
      @include has-inline-focus-state;

      text-decoration: underline;
    }
  }
}

img {
  max-width: 100%;
}

// Address inconsistent and variable font size in all browsers.

small {
  @include typography("small");

  h1 & {
    @include typography("body");

    color: var(--token-text-default);
    margin-left: $space-2xs;
  }
}

// Address styling not present in IE 8/9.

mark {
  background-color: var(--token-yellow-300);
  color: var(--token-text-default);
}

// Prevent `sub` and `sup` affecting `line-height` in all browsers.

sub,
sup {
  @include typography("small");

  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

// Address margin not present in IE 8/9 and Safari.

figure {
  margin: 1em 40px;
}

// Define consistent border, margin, and padding.

fieldset {
  border: 0;
  margin: 0;
  padding: $space-s 0;
}

ul {
  margin: $space-m 0;
}

// 1. Correct `color` not being inherited in IE 8/9/10/11.
// 2. Remove padding so people aren't caught out if they zero out fieldsets.

legend {
  border: 0; // 1
  padding: 0; // 2
}

iframe {
  border: 0;
  max-width: 100%;
}

// on loginpage, the fingerprint object made gap and scrollbar
// scss-lint:disable IdSelector
#fingerprintjs2 {
  bottom: 0;
  position: absolute;
}

// These elements have monospace fonts by default
pre,
code,
kbd,
samp {
  font-family: var(--token-font-monospace, monospace);
}
