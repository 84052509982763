e-separator {
  &::part(wrapper) {
    align-items: center;
    display: flex;
    min-height: size("base", 3);
    min-width: size("base", 3);
  }

  &::part(wrapper-horizontal) {
    flex-direction: row;
    gap: $space-xs;
  }

  &::part(wrapper-vertical) {
    display: inline-flex;
    flex-direction: column;
  }

  &::part(wrapper-fullheight) {
    height: 100%;
  }

  &::part(content) {
    color: var(--token-text-faint);
    display: block;
    font-weight: $text-bold-weight;
    min-height: size("base", 3);
  }

  &::part(content-hidden) {
    display: none;
  }

  &::part(line) {
    background: var(--token-border-strong);
    flex: 1 1 auto;
  }

  &::part(line-horizontal) {
    height: 2px;
    min-width: 25%;
  }

  &::part(line-vertical) {
    height: size("base", 3);
    width: 2px;
  }

  &::part(line-color-faint) {
    background-color: var(--token-border-default);
  }
}
