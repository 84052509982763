e-tooltip {
  display: inline-flex;
  vertical-align: middle;

  &-wrapper {
    display: none;
  }

  &[block] {
    display: flex;
  }

  &[valign="baseline"] {
    vertical-align: baseline;
  }

  &[type="helper"] {
    margin-left: $space-2xs;
    margin-top: -$space-3xs;

    .e-icon-helper {
      margin: 0;
    }

    .e-svgclickfix {
      font-size: 0;
    }

    e-tooltip-wrapper {
      display: inline-flex;
    }
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

.e {
  &-tt {
    @include typography("body");

    background-color: var(--token-box-strong-background);
    border: 1px solid var(--token-box-strong-border);
    border-radius: $border-radius;
    box-shadow: var(--token-shadow-medium);
    color: var(--token-box-strong-text);
    max-width: 320px;
    opacity: 0;
    padding: $space-xs $space-m;
    text-align: left;
    top: -99999px;
    transform: translate3d(0, 0, 0);
    white-space: normal;
    word-wrap: break-word;
    z-index: z("flying");

    &-fixedwidth {
      white-space: normal;
    }

    &-bubble {
      background-clip: initial;
      background-color: inherit;
      border-color: transparent;
      border-radius: $border-radius-small;
      box-shadow: none;
      color: var(--token-text-light);
      max-width: 100%;
      padding: 0 $space-m;
      z-index: z("default");

      .e-tt__content {
        @include text-overflow;
      }

      .e-tt__arrow {
        background: transparent;
        border: 6px solid transparent;
        border-top: 6px solid currentcolor;
        box-shadow: none;
        height: 0;
        transform: none;
        width: 0;
      }
    }

    &-inverse {
      background-color: var(--token-neutral-800);
      border-color: var(--token-neutral-700);
      box-shadow: none;
      color: var(--token-gray-000);

      .e-tt__arrow {
        background-color: var(--token-neutral-800);
        box-shadow: 1px 1px 0 0 var(--token-neutral-800);
      }
    }

    &-has_action {
      &::before {
        background-color: var(--token-box-strong-background);
        content: "";
        opacity: 0;
        position: absolute;
      }
    }

    &-permission {
      border-radius: 0;
      padding-left: $space-xl;

      &::after {
        background-color: var(--token-error-default);
        bottom: -1px;
        content: "";
        left: -1px;
        position: absolute;
        top: -1px;
        width: size("base", 0.5);
      }
    }

    &__icon {
      left: 10px;
      position: absolute;
      top: $space-s;
    }

    &__arrow {
      background-color: var(--token-box-strong-background);
      box-shadow: 1px 1px 0 0 var(--token-box-strong-border);
      height: size("base", 1);
      position: absolute;
      transform: rotate(45deg);
      width: size("base", 1);
    }

    &[x-placement^="top"],
    &[x-placement^="bottom"] {
      &::before {
        height: 10px;
        left: -1px;
        right: -1px;
      }
    }

    &[x-placement^="left"],
    &[x-placement^="right"] {
      &::before {
        bottom: -1px;
        top: -1px;
        width: size("base", 1);
      }
    }

    &[x-placement^="top"] {
      animation: tooltip-top $transition-time normal forwards ease-in-out;
      margin-bottom: 10px;

      &::before {
        top: 100%;
      }

      .e-tt__arrow {
        bottom: -3px;
        margin-left: -2px;
      }
    }

    &-bubble[x-placement^="top"] {
      .e-tt__arrow {
        bottom: -12px;
      }
    }

    &[x-placement^="bottom"] {
      animation: tooltip-bottom $transition-time normal forwards ease-in-out;
      margin-top: 10px;

      &::before {
        top: -10px;
      }

      .e-tt__arrow {
        margin-left: -2px;
        top: -3px;
        transform: rotate(-135deg);
      }
    }

    &-bubble[x-placement^="bottom"] {
      .e-tt__arrow {
        top: -12px;
        transform: rotate(180deg);
      }
    }

    &[x-placement^="right"] {
      animation: tooltip-right $transition-time normal forwards ease-in-out;
      margin-left: 12px;

      &::before {
        left: -10px;
      }

      .e-tt__arrow {
        left: -3px;
        margin-top: -2px;
        transform: rotate(135deg);
      }
    }

    &[x-placement^="left"] {
      animation: tooltip-left $transition-time normal forwards ease-in-out;
      margin-right: 12px;

      &::before {
        left: 100%;
      }

      .e-tt__arrow {
        margin-top: -2px;
        right: -3px;
        transform: rotate(-45deg);
      }
    }
  }
}

@keyframes tooltip-top {
  to {
    opacity: 1;
    transform: translate3d(0, 3px, 0);
  }
}

@keyframes tooltip-bottom {
  to {
    opacity: 1;
    transform: translate3d(0, -3px, 0);
  }
}

@keyframes tooltip-right {
  to {
    opacity: 1;
    transform: translate3d(-3px, 0, 0);
  }
}

@keyframes tooltip-left {
  to {
    opacity: 1;
    transform: translate3d(3px, 0, 0);
  }
}
