e-dialog-action {
  @include has-focus-state;

  border: 1px solid var(--token-border-default);
  border-bottom-width: 0;
  display: flex;
  flex-wrap: wrap;
  padding: $space-m;
  position: relative;
  width: 100%;

  &:first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &:last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  &:hover:where(:not([disabled])) {
    background-color: var(--token-background-strong);
    cursor: pointer;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &-title,
  &-content,
  &-footer,
  &-media {
    display: block;
    flex-basis: 100%;
  }

  &-container {
    flex-basis: 0;
    flex-grow: 1;
  }

  &-title {
    @include typography("body");

    font-weight: 500;
  }

  &-content,
  &-footer {
    margin-top: $space-2xs;
  }

  &-footer {
    position: relative;
    z-index: 2;
  }

  &-media {
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    order: -1;
    padding-right: $space-m;
  }

  e-tooltip {
    position: relative;
    z-index: 2;
  }

  > e-tooltip,
  > a {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  &:last-of-type {
    border-bottom-width: 1px;
  }
}
