.e {
  &-topnav {
    @include clearfix;

    background-color: var(--token-shellbar-background);
    position: relative;

    @include screen("topnav", "down") {
      padding-left: $space-4xl;
    }

    &__container {
      margin: auto;
      max-width: size("screens", "large");

      @include screen("large") {
        padding-right: $space-m;
      }

      &-fullwidth {
        max-width: 100%;
      }
    }

    &__mobilemenu {
      display: none;

      &:checked {
        + .e-menu {
          display: block;
        }
      }
    }

    &-fixed {
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: z("topnav");
    }

    &__logo {
      float: left;
      line-height: size("shellbar", "horizonHeight");
      margin: 0 $space-m;
      overflow: hidden;
      position: relative;

      @include screen("topnav", "down") {
        margin: 0;
      }

      @include screen("medium", "down") {
        width: 20px;
      }

      svg {
        fill: var(--token-neutral-500);
        height: 30px;
        position: relative;
        top: 1px;
        vertical-align: middle;
        width: 115px;
      }

      &-b2c {
        svg {
          fill: none;
          top: -$space-3xs;
          width: size("base", 17);
        }
      }
    }

    &__vertical {
      color: #c1d3e6;
      font-size: 11px;
      font-weight: 400;
      left: 28px;
      letter-spacing: 0.4px;
      line-height: 1;
      overflow: hidden;
      position: absolute;
      text-transform: uppercase;
      top: 38px;

      + svg {
        top: -6px;
      }

      @include screen("medium", "down") {
        width: 0;

        + svg {
          top: -2px;
        }
      }
    }

    &-old {
      .e-topnav {
        &__logo {
          @include screen("medium", "down") {
            width: auto;
          }
        }
      }
    }
  }
}

// Fixed header helper container
.fixedheader-container {
  padding-top: size("shellbar", "horizonHeight");

  &-image {
    background-position: 0 size("shellbar", "horizonHeight");
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    height: 100%;
  }

  &-withfooter {
    margin-bottom: -60px;
    min-height: 100%;
  }
}

.fixedbreadcrumb {
  padding-top: size("shellbar", "horizonHeight") + 30;
}
