e-icon {
  display: inline-flex;
  position: relative;
  vertical-align: middle;

  &::after {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .e-icon {
    align-items: center;
    color: var(--token-icon-default-text);
    display: inline-flex;
    font-weight: normal;
    justify-content: center;
    pointer-events: none;

    &-helper {
      color: var(--token-text-faint);
    }

    &-label {
      color: var(--token-text-faint);
    }
  }
}

:where(foreignObject) e-icon {
  display: contents;
  position: fixed;
}

.e-icon-wrapper {
  line-height: 0;
  margin: auto;
}

.e {
  &-icon {
    color: var(--token-icon-default-text);
    font-family: var(--token-icon-default-fontFamily);
    font-size: var(--token-icon-default-size);
    height: size("icon", "medium");
    vertical-align: middle;
    width: size("icon", "medium");

    &-button {
      height: size("icon", "small");
      width: size("icon", "small");
    }

    &-dropdown,
    &-table,
    e-icon[size="small"] &-small,
    &-small {
      font-size: var(--token-icon-default-sizeSmall);
      height: size("icon", "small");
      vertical-align: middle;
      width: size("icon", "small");
    }

    e-icon[size="large"] &-large,
    &-large {
      font-size: var(--token-icon-default-sizeLarge);
      height: size("icon", "large");
      width: size("icon", "large");
    }

    e-icon[size="default"] &-default,
    &-default {
      font-size: var(--token-icon-default-size);
      height: size("icon", "medium");
      width: size("icon", "medium");
    }

    &-middle {
      font-size: var(--token-icon-default-sizeLarge);
      height: size("icon", "large");
      width: size("icon", "large");
    }

    &-white {
      color: var(--token-icon-default-text);
    }

    &-rotate-left {
      transform: rotate(-90deg);
    }

    &-rotate-right {
      transform: rotate(90deg);
    }

    &-label {
      font-size: var(--token-icon-default-sizeSmall);
      height: var(--token-icon-default-sizeSmall);
      margin-left: $space-2xs;
      margin-top: -$space-2xs;
      vertical-align: middle;
      width: var(--token-icon-default-sizeSmall);
    }

    &-helper {
      font-size: var(--token-icon-default-sizeSmall);
      height: var(--token-icon-default-sizeSmall);
      margin-left: $space-2xs;
      margin-top: -$space-3xs;
      vertical-align: middle;
      width: var(--token-icon-default-sizeSmall);
    }

    &-table {
      cursor: pointer;
      margin: (-$space-2xs) $space-2xs 0;
    }

    &-inline {
      margin: 0 $space-2xs -1px 0;
      vertical-align: -27%; // TODO: fix webcomponent align (replace to margin?)

      &.e-icon-small {
        margin: (-$space-2xs) $space-2xs -1px 0;
        vertical-align: middle;
      }
    }

    &-disabled {
      cursor: default;
      opacity: 0.5;
    }
  }
}
