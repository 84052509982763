// OLD
@import "./assets-old";

table:not([data-e-version="2"]) {
  &.e {
    &-table {
      background: var(--token-box-default-background);
      border: 1px solid var(--token-box-default-border);
      margin: 0 0 $space-s;
      max-width: 100%;
      width: 100%;

      tbody {
        // scss-lint:disable SelectorDepth
        tr {
          transition: background $transition-time ease-in-out;

          &:hover td {
            background: var(--token-tableCell-default-backgroundHover);
            background-clip: padding-box;
          }
        }

        th,
        td {
          background: var(--token-tableCell-default-background);
          padding: $space-s $space-xs;
          vertical-align: top;
        }

        td {
          background-clip: padding-box;
          border-bottom: 1px solid var(--token-tableCell-default-border);
        }

        tr:last-child td {
          border-bottom-color: var(--token-tableCell-default-border);
        }

        .e-table__title {
          @include typography("body");

          padding: $space-l 0 0;
        }
      }

      th {
        border-bottom: 1px solid var(--token-tableCell-default-border);
        border-top: 1px solid var(--token-tableCell-default-border);
        font-weight: 500;
        padding: $space-s $space-xs;
        text-align: left;
        vertical-align: bottom;

        // SI settings
        &.e-table__col-right {
          text-align: right;
        }

        &:focus {
          outline: none;
        }
      }

      tfoot td {
        padding: $space-s $space-xs;
      }

      &-simple {
        tbody {
          tr {
            &:hover td {
              background: transparent;
            }
          }
        }
      }

      &-inner {
        background: transparent;
        border: 0;

        tbody {
          td {
            background: transparent;
            border: 0;
            padding: $space-3xs 0;
            vertical-align: bottom;
          }
        }
      }

      &-summary {
        background: transparent;

        tbody {
          tr {
            &:hover td {
              background: transparent;
            }
          }

          td {
            background: transparent;
            border: 0;
            padding: $space-xs $space-s;
          }
        }

        tfoot {
          td {
            border-top: 1px solid var(--token-tableCell-default-border);
          }
        }
      }

      &-borderless {
        border: 0;

        tbody {
          td {
            border: 0;
          }
        }
      }

      &-bordered {
        thead {
          td,
          th {
            border: 1px solid var(--token-tableCell-default-border);
          }
        }

        tbody {
          td {
            border: 1px solid var(--token-tableCell-default-border);
          }
        }
      }

      &-centered {
        tbody {
          th,
          td {
            vertical-align: middle;
          }
        }
      }

      &-centeredtext {
        thead {
          th {
            text-align: center;
            vertical-align: middle;
          }
        }

        tbody {
          th,
          td {
            text-align: center;
            vertical-align: middle;
          }
        }
      }

      &-truncated {
        table-layout: fixed;
        vertical-align: middle;
        white-space: nowrap;
        width: 100%;
      }

      &-fixed {
        table-layout: fixed;
        width: 100%;
      }

      // scss-lint:disable SelectorDepth
      &-accordion {
        > tbody {
          > tr {
            > td {
              padding: 0;

              .e-accordion {
                border: 0;
              }

              .e-accordion__title {
                margin: -1px;
              }

              .e-accordion__content {
                background: var(--token-background-default);
                border: 0;
              }
            }
          }
        }
      }

      &-modal {
        border: 0;
        margin-bottom: -1px;

        tr:last-child td {
          border-bottom: 0;
        }
      }

      &.e-table-compact {
        tbody {
          th,
          td {
            padding: $space-3xs $space-s;
            vertical-align: middle;
          }
        }
      }

      &.e-table-condensed {
        thead {
          th,
          td {
            padding: $space-2xs $space-s;
          }
        }

        tbody {
          th,
          td {
            padding: $space-2xs $space-s;
            vertical-align: middle;
          }
        }
      }

      &.e-table-nopadding {
        thead {
          th {
            padding: $space-3xs 0;
          }
        }

        tbody {
          td {
            padding: $space-3xs 0;
            vertical-align: middle;
          }
        }
      }

      // scss-lint:disable QualifyingElement
      td.e-table,
      th.e-table {
        &__col {
          &-groupheader {
            background: var(--token-tableCell-alternate-background);
            border-bottom-color: var(--token-tableCell-alternate-border);
            font-weight: 500;
            padding: $space-m $space-s;
            text-align: center;

            &:hover {
              background: var(--token-tableCell-alternate-background);
            }

            &-leftaligned {
              @include typography("small");

              background: transparent;
              text-align: left;

              &:hover {
                background: transparent;
              }
            }
          }
        }
      }
    }
  }

  * {
    &.e {
      &-table {
        &__col {
          &-right {
            text-align: right;
          }

          &-actions {
            text-align: right;
            user-select: none;
            white-space: nowrap;
            width: size("base", 14);
          }

          &-filter {
            background: var(--token-tableCell-default-background);
            height: size("base", 8);
            padding: 0 $space-s;
          }

          &-bottom {
            vertical-align: bottom;
          }

          &-small {
            white-space: nowrap;
            width: size("base", 15);
          }

          &-truncated {
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &__sort {
          @include e-table-sort-old;

          &-asc {
            @include e-table-sort-modifier-old("asc");
          }

          &-desc {
            @include e-table-sort-modifier-old("desc");
          }
        }

        // Segments overview
        &__button {
          float: right;
          margin-top: 6px;
        }

        &__settings {
          float: right;
          margin-right: -$space-xs;
          padding-bottom: $space-l;
          padding-top: $space-l;
        }

        &__action {
          // in case the action buttons are not links
          cursor: pointer;
        }

        &__datatablefilter {
          padding-left: 260px;
          position: relative;

          &-advanced {
            margin-bottom: $space-m;
            top: $space-xs;
          }
        }

        &__advancedfilters {
          background: var(--token-background-default);
          height: 0;
          overflow: hidden;
          transition: height $transition-time ease;

          &-active {
            height: size("base", 8);
          }
        }
      }
    }
  }
}

.e-stretch table:not([data-e-version="2"]).e-table {
  border-left-width: 0;
  border-right-width: 0;
  padding: $space-2xs;

  th,
  td {
    padding-left: $space-m;
    padding-right: $space-m;
  }
}

.e-stretch.e-stretch-top table:not([data-e-version="2"]).e-table {
  border-top-width: 0;
}

.e-stretch.e-stretch-bottom table:not([data-e-version="2"]).e-table {
  border-bottom-width: 0;
}
