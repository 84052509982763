@mixin can-have-focus-style {
  @each $part in & {
    $newSelector: ":where(html.e-keyboard-navigation) #{$part}";

    @if str-index(#{$part}, "html.FLIPPER-") {
      $newSelector: str-replace(#{$part}, "html.FLIPPER-", "html.e-keyboard-navigation.FLIPPER-");
    } @else if str-index(#{$part}, "html:not(.FLIPPER-") {
      $newSelector: str-replace(
        #{$part},
        "html:not(.FLIPPER-",
        "html.e-keyboard-navigation:not(.FLIPPER-"
      );
    } @else if str-index(#{$part}, "html.e-keyboard-navigation") {
      $newSelector: ':where(html.e-keyboard-navigation) #{str-replace(#{$part}, "html.e-keyboard-navigation", "")}';
    }

    @at-root #{$newSelector} {
      @content;
    }
  }
}

@mixin has-focus-style($offset: -#{$space-2xs}, $style: dashed, $width: 2px, $color: currentColor) {
  outline: $style $width $color;
  outline-offset: $offset;
}

@mixin has-focus-state($offset: -#{$space-2xs}, $style: dashed, $width: 2px, $color: currentColor) {
  &:focus {
    @include can-have-focus-style {
      @include has-focus-style($offset, $style, $width, $color);
    }
  }
}

@mixin has-inline-focus-state() {
  @include can-have-focus-style {
    &:focus {
      background: var(--token-highlight-default);
      border-radius: 0.125em;
      color: var(--token-neutral-000);
      outline: 1px solid var(--token-highlight-default);
      outline-offset: 0;
    }
  }
}

@mixin has-secondary-focus-style($offset: -#{$space-2xs}) {
  outline: dotted 2px currentcolor;
  outline-offset: $offset;
}

.e-focusable {
  @include has-focus-state($style: solid, $color: var(--token-highlight-default));

  &-inline {
    @include has-focus-state($offset: 0, $style: solid, $color: var(--token-highlight-default));
  }
}
