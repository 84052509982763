// Used: Ac, Combined Segments,value control
// maybe: Segment, Email campaigns recurring schedule (in not remove old css - Recipient source: Generated through an event )

.e {
  &-pnotify {
    left: 0;
    position: fixed;
    right: 0;
    top: 61px;

    .ui-pnotify-container {
      border: 1px solid var(--token-border-default);
      box-shadow: var(--token-shadow-strong);
      width: size("base", 75);

      .ui-pnotify-title {
        @include clearfix;

        margin: 0 0 $space-xs;
      }

      // scss-lint:disable SelectorDepth
      .ui-pnotify-icon {
        float: left;
        margin-top: $space-3xs;
        padding-right: $space-xs;

        .icon-info-sign,
        .icon-warning-sign {
          display: inline-block;
          height: size("icon", "small");
          width: size("icon", "small");
        }

        .icon-info-sign {
          &::after {
            color: var(--token-info-default);
            content: var(--token-glyphs-info-circle);
            font-family: var(--token-icon-default-fontFamily);
            font-size: var(--token-icon-default-sizeSmall);
          }
        }

        .icon-warning-sign {
          &::after {
            color: var(--token-warning-default);
            content: var(--token-glyphs-exclamation-circle);
            font-family: var(--token-icon-default-fontFamily);
            font-size: var(--token-icon-default-sizeSmall);
          }
        }
      }

      .ui-pnotify-closer {
        position: absolute;
        right: 20px;
        top: 15px;

        .icon-remove {
          display: inline-block;
          height: size("icon", "medium");
          width: size("icon", "medium");

          &::after {
            color: var(--token-icon-default-text);
            content: var(--token-glyphs-close);
            font-family: var(--token-icon-default-fontFamily);
            font-size: var(--token-icon-default-size);
          }
        }
      }

      .ui-pnotify-sticker {
        position: absolute;
        right: 40px;
        top: 15px;

        .icon-pause,
        .icon-play,
        .icon-remove {
          display: inline-block;
          height: size("icon", "small");
          margin-top: $space-2xs;
          width: size("icon", "small");
        }

        .icon-pause {
          &::after {
            color: var(--token-icon-default-text);
            content: var(--token-glyphs-playback-pause);
            font-family: var(--token-icon-default-fontFamily);
            font-size: var(--token-icon-default-sizeSmall);
          }
        }

        .icon-play {
          &::after {
            color: var(--token-icon-default-text);
            content: var(--token-glyphs-playback-play);
            font-family: var(--token-icon-default-fontFamily);
            font-size: var(--token-icon-default-sizeSmall);
          }
        }
      }

      &.alert {
        @include e-alert;
        @include e-alert-modifier("info");

        border-radius: 0;
        margin: $space-xs auto;
        padding-top: $space-m;
      }

      &.alert-error {
        @include e-alert-modifier("error");
      }

      &.ui-pnotify-shadow {
        box-shadow: none;
      }
    }

    &-bottomright {
      left: auto;
      top: auto;

      .ui-pnotify-container {
        width: size("base", 33);
      }
    }
  }
}
