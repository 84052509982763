.e-theme-switcher {
  &__split_thumbnail {
    display: grid;
  }

  &__left_thumbnail {
    clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);
    grid-area: 1 / 1;
  }

  &__right_thumbnail {
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
    grid-area: 1 / 1;
  }
}
