@import "../input/assets";

.e {
  &-field {
    margin: 0 0 $space-m;
    position: relative;

    &-fixwidth {
      max-width: size("base", 50);
    }

    &-inline {
      display: inline-block;
      margin-bottom: 0;
      margin-right: $space-s;
      padding: $space-s 0;
    }

    &-flex {
      display: flex;
    }

    &-error {
      .e-field__prefix__icon .e-icon {
        color: var(--token-text-error);
      }

      .e-input {
        @include e-input-modifier("error");
      }

      .e-field__message {
        color: var(--token-text-error);
        fill: var(--token-text-error);
      }
    }

    &__postfix {
      border-collapse: separate;
      display: table;
      width: 100%;

      &__text {
        @include typography("small");

        background-color: var(--token-background-strong);
        border: 1px solid var(--token-border-default);
        border-left-width: 0;
        border-radius: $border-radius;
        color: var(--token-text-default);
        display: inline-block;
        line-height: calc(var(--token-input-default-height) - 2px);
        margin-left: $space-3xs;
        padding: 0 $space-s;
        text-align: center;
        vertical-align: middle;
      }

      &__cell {
        display: table-cell;
        vertical-align: middle;
        width: 100%;

        &-short {
          white-space: nowrap;
          width: 1%;
        }

        &-shortsticky {
          font-size: 0;
          white-space: nowrap;
          width: 1%;

          .e-btn {
            border-left: 1px solid var(--token-border-default);
            margin-left: $space-3xs;
          }
        }

        &:first-child .e-field__postfix__text,
        &:first-child .e-input {
          border-radius: $border-radius;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          margin-right: -1px;
        }

        &:last-child .e-field__postfix__text,
        &:last-child .e-input {
          border-radius: $border-radius;
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          margin-left: 0;
        }
      }

      &-reverse {
        .e-field__postfix__text {
          border-left-width: 1px;
          border-right-width: 0;
          margin-left: 0;
          margin-right: $space-3xs;
        }
      }
    }

    &__prefix {
      display: inline-block;
      position: relative;

      &__icon {
        left: $space-s;
        line-height: 0;
        margin-top: -1px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: z("default");

        .e-icon {
          color: var(--token-text-default);
          font-size: var(--token-icon-default-sizeSmall);
          height: var(--token-icon-default-sizeSmall);
          width: var(--token-icon-default-sizeSmall);
        }
      }

      .e-input {
        padding-left: $space-2xl;
      }

      &-block {
        display: block;
      }
    }

    &__label {
      @include typography("body");

      display: block;
      margin: 0 0 $space-2xs;

      &-small {
        @include typography("small");
      }

      &-inline {
        display: inline-block;
        margin-bottom: 0;
        margin-right: $space-xs;
        vertical-align: middle;
      }

      &_info,
      &__info {
        float: right;
      }

      &-required {
        &::after {
          color: var(--token-text-error);
          content: "*";
          left: $space-2xs;
          position: relative;
          top: $space-3xs;
        }
      }

      &_optional {
        color: var(--token-text-faint);
        display: inline-block;
        font-style: italic;
        margin-left: $space-2xs;
      }
    }

    &__color {
      border-right: 1px solid var(--token-border-default);
      display: inline-block;
      height: 34px;
      margin: 0 0 0 (-$space-s);
      vertical-align: middle;
      width: size("base", 4);
    }

    &__message {
      @include typography("small");

      display: block;
      padding: $space-2xs 0;

      &:empty {
        display: none;
      }

      &-error {
        color: var(--token-text-error);
        fill: var(--token-text-error);

        &.e-field__message-floating {
          @include typography("body");

          align-items: center;
          background-color: var(--token-box-error-background);
          color: var(--token-text-error);
          display: flex;
          justify-content: center;

          &::before {
            color: var(--token-error-default);
            content: var(--token-glyphs-error);
            font-family: var(--token-icon-default-fontFamily);
            font-size: var(--token-icon-default-sizeSmall);
            left: $space-xs;
            position: absolute;
          }
        }
      }

      &-warning {
        color: var(--token-text-warning);
        fill: var(--token-text-warning);
      }

      &-success {
        color: var(--token-text-success);
        fill: var(--token-text-success);
      }

      &-info {
        color: var(--token-text-highlight);
        fill: var(--token-text-highlight);
      }

      &-faint {
        color: var(--token-text-faint);
        fill: var(--token-text-faint);
      }

      &-floating {
        border: 0;
        border-radius: $border-radius;
        box-shadow: var(--token-shadow-medium);
        left: -$space-2xs;
        padding: $space-2xs $space-s $space-2xs $space-xl;
        position: absolute;
        top: calc(100% + #{$space-xs});
      }

      .e-helperlink {
        padding-left: 0;
      }
    }

    &__indented {
      padding-left: $space-m;

      &-checkbox {
        padding-left: $space-l;
      }

      &-switch {
        padding-left: $space-2xl;
      }
    }
  }
}
