@import "../../core/focus/focus";
@import "../input/assets";

.e {
  &-suggest-input {
    &__input {
      @include has-focus-state;

      &-error {
        @include e-input-modifier("error");
      }
    }

    &__option-list {
      box-sizing: border-box;
      min-height: #{$space-m * 2 + 26px};
    }

    &__actionlist {
      &-hidden {
        display: none;
      }
    }

    &__use_value {
      @include word-wrap;

      flex: 1 1 auto;
      text-align: left;

      &-active {
        @include has-secondary-focus-style;
      }
    }
  }
}

e-suggest-input-option {
  display: none;
}
