e-card {
  background: var(--token-background-default);
  border: 1px solid var(--token-border-default);
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &::part(thumbnail-wrapper) {
    display: grid;
  }

  &::part(header) {
    display: grid;
    grid-area: 1 / 1;
    grid-template-columns: max-content max-content;
    height: max-content;
    justify-content: space-between;
    padding: $space-m;
    position: relative;
  }

  &::part(thumbnail) {
    aspect-ratio: 4 / 3;
    background: var(--token-background-strong);
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    display: flex;
    grid-area: 1 / 1;
    overflow: hidden;
    pointer-events: none;
    position: relative;
    width: 100%;
  }

  &::part(thumbnail without-content-wrapper) {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  &::part(content-wrapper) {
    border-top: 1px solid var(--token-border-default);
    display: flex;
    flex-direction: column;
    gap: $space-s;
    padding: $space-m;
  }

  &::part(content-wrapper without-thumbnail-wrapper) {
    border-top: 0;
  }

  &::part(content) {
    display: flex;
    flex-direction: column;
    gap: $space-xs;
  }

  &::part(actions) {
    display: flex;
    justify-content: end;
  }

  &::part(hidden) {
    display: none;
  }
}
