.e {
  &-snackbar {
    background-color: var(--token-box-default-background);
    border: 1px solid var(--token-box-default-border);
    border-radius: $border-radius;
    box-shadow: var(--token-shadow-medium);
    left: 0;
    margin: auto;
    min-width: size("base", 82);
    padding: $space-m;
    position: absolute;
    right: 0;
    top: $space-m;
    width: 70%;
    z-index: z("topnav") - 1;

    &-top {
      top: 75px;
    }

    &-bottom {
      bottom: $space-m;
      top: auto;
    }

    &-sticky {
      position: fixed;
    }

    &-autowidth {
      left: 50%;
      min-width: 0;
      right: auto;
      transform: translateX(-50%);
      width: auto;

      .e-snackbar__description {
        width: auto;
      }
    }

    &__content {
      display: table;
      width: 100%;
    }

    &__description {
      display: table-cell;
      padding-right: $space-xl;
      vertical-align: middle;
      width: 100%;
    }

    h3 {
      margin: 0;
    }

    .e-btn {
      margin-left: $space-s;

      &:first-child {
        margin-left: 0;
      }
    }

    &__actions {
      display: table-cell;
      text-align: right;
      vertical-align: middle;
      white-space: nowrap;

      &-sticky {
        .e-btn {
          margin-left: -1px;
        }
      }
    }
  }
}
