.e-confetti {
  &__canvas {
    height: 100vh;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: z("modal");
  }
}
