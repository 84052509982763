@mixin tab-bar-tab-content {
  @include has-focus-state;

  background: none;
  border: 0;
  border-radius: $border-radius;
  padding: $space-s;
}

@mixin tab-bar-tab-content-hover {
  background-color: var(--token-tab-default-backgroundHover);
}

@mixin tab-bar-indicator {
  background-color: var(--token-tab-selected-marker);
  content: "";
  position: absolute;
}

@mixin tab-bar-indicator-horizontal {
  border-radius: 20px 20px 0 0;
  bottom: 0;
  height: 0;
  left: #{$space-xs + $space-s};
  right: #{$space-xs + $space-s};
}

@mixin tab-bar-indicator-vertical {
  border-radius: 20px 0 0 20px;
  height: size("base", 4);
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
}
