// Used: AC

.ui-dialog {
  background-color: var(--token-box-default-background);
  border: 0;
  border-radius: 0;

  // scss-lint:disable ImportantRule ColorVariable
  box-shadow: 0 0 6px 0 color-mix(in srgb, var(--token-neutral-999) 30%, transparent);
  min-width: size("base", 40) !important; /* stylelint-disable-line declaration-no-important */
  padding: $space-m;
  z-index: z("modal");

  &-title {
    @include typography("heading2");

    margin: 0 0 $space-xs;
  }

  .ui-dialog-content {
    // scss-lint:disable ImportantRule SelectorFormat
    height: auto !important; /* stylelint-disable-line declaration-no-important */
  }

  .error {
    color: var(--token-text-error);
    line-height: size("base", 3);
    padding: $space-3xs 0 0;
  }

  .ui-form-row {
    @extend .e-field;

    .row-title {
      @extend .e-field__label;

      padding: 0;
    }
  }

  .ui-dialog-buttonset {
    @include e-buttongroup(".ui-button");

    height: auto !important; /* stylelint-disable-line declaration-no-important */

    .ui-button {
      @include e-btn;
    }
  }

  .ui-dialog-buttonpane {
    .ui-button {
      @include e-btn;

      border-radius: $border-radius;
      font-family: inherit;
      text-shadow: none;
    }

    .ui-button-create,
    .ui-button-ok {
      @include e-btn-style("primary", $btn-styles);

      border-radius: $border-radius;
    }
  }

  .ui-dialog-titlebar-close {
    font-size: 0;
    position: absolute;
    right: 30px;
    text-decoration: none;
    top: 10px;

    .ui-icon-closethick::before {
      color: var(--token-icon-default-text);
      content: var(--token-glyphs-close);
      font-family: var(--token-icon-default-fontFamily);
      font-size: var(--token-icon-default-size);
      height: 20px;
      position: absolute;
      width: 20px;
    }
  }

  .ui-corner,
  .ui-corner-right {
    left: 50%;
    margin-left: -$space-s;
    position: absolute;
    top: -$space-s;

    &::before {
      background-color: var(--token-box-default-background);
      box-shadow: $space-3xs $space-3xs $space-2xs
        color-mix(in srgb, var(--token-neutral-999) 6%, transparent);
      content: "";
      display: inline-block;
      height: 20px;
      transform: rotate(-135deg);
      width: 20px;
    }
  }

  .ui-corner-right {
    left: auto;
    right: $space-m;
  }

  &.e-ui-dialog-notitlebar {
    .ui-dialog-titlebar {
      display: none;
    }
  }
}

.ui-widget-overlay {
  background-color: var(--token-overlay-dark);
  height: 100%;
  left: 0;
  opacity: 0.25;
  position: absolute;
  top: 0;
  width: 100%;
}

.ui-dialog-titlebar-close {
  font-size: 0;
  position: absolute;
  right: 30px;
  text-decoration: none;
  top: 10px;
  z-index: z("default");

  &::before {
    color: var(--token-icon-default-text);
    content: var(--token-glyphs-close);
    font-family: var(--token-icon-default-fontFamily);
    font-size: var(--token-icon-default-size);
    height: 20px;
    position: absolute;
    width: 20px;
  }
}

.ui-dialog-content {
  body {
    background-color: var(--token-box-default-background);
  }

  .e-topnav {
    &-topnav {
      display: none;
    }

    &-container {
      max-width: auto;
      min-width: auto;
      padding-top: 0;
    }
  }
}
