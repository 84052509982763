@import "assets";
@import "styles";

.e {
  &-btn {
    @include e-btn;

    border-radius: $border-radius;

    @each $class, $data in $btn-styles {
      @if $class != "default" {
        &-#{$class} {
          @include e-btn-style($class, $btn-styles);
        }
      }
    }

    // scss-lint:disable SelectorFormat
    &.button_inactive {
      cursor: default;
      opacity: 0.5;
    }

    &__loading {
      float: left;

      .e-btn-onlyicon & {
        float: none;
      }

      .e-spinner {
        display: none;
      }

      &-active {
        .e-spinner {
          display: inline-block;
        }

        .e-btn & .e-icon,
        .e-btn & e-icon {
          display: none;
        }
      }
    }

    &-has_overlay {
      opacity: 1;
      position: relative;

      .e-btn__loading {
        background: color-mix(in srgb, var(--token-overlay-light) 50%, transparent);
        bottom: -1px;
        display: none;
        left: -1px;
        position: absolute;
        right: -1px;
        text-align: center;
        top: -1px;

        &-active {
          display: inline-block;
        }

        .e-spinner {
          margin: $space-2xs 0 0 (-$space-2xs);
        }
      }
    }

    .e-icon {
      font-size: var(--token-icon-default-sizeSmall);
      height: var(--token-icon-default-sizeSmall);
      margin: -2px $space-xs 0 0;
      width: var(--token-icon-default-sizeSmall);
    }

    &-dropdown {
      padding-right: $space-2xl;
      position: relative;

      &::after {
        content: var(--token-glyphs-angle-down);
        font-family: var(--token-icon-default-fontFamily);
        font-size: var(--token-icon-default-sizeSmall);
        position: absolute;
        right: $space-s;
      }

      &.e-btn-topnav {
        padding: 0 $space-xl 0 $space-m;

        &::after {
          margin-top: -$space-3xs;
        }
      }

      .e-icon {
        margin-right: 0;
      }
    }

    .e-spinner {
      margin: ($space-3xs + 1) $space-xs 0 (-$space-2xs + 1);
    }

    &-link {
      padding: 0 $space-m;
      text-decoration: underline;

      label {
        color: var(--token-text-default);
      }
    }

    &-onlyicon {
      @include e-btn-onlyicon;

      .e-icon {
        margin: -2px 0 0;
      }

      .e-spinner {
        margin: ($space-3xs + 1) 0 0;
      }
    }

    &-inlineicon {
      .e-icon {
        margin: -2px $space-3xs 0 $space-2xs;
      }
    }

    &-no_minwidth {
      min-width: 0;
    }

    &-fieldset {
      display: block;
      margin-top: -1px;
    }

    &-small {
      @include typography("small");

      font-weight: $text-bold-weight;
      height: var(--token-button-default-heightSmall);
      line-height: calc(var(--token-button-default-heightSmall) - 2px);
      padding: 0 $space-xs;

      &.e-btn-onlyicon {
        padding: 0;
        width: size("base", 3);

        .e-icon {
          font-size: var(--token-icon-default-sizeSmall);
          height: var(--token-icon-default-sizeSmall);
          width: var(--token-icon-default-sizeSmall);
        }
      }
    }

    &-fullwidth {
      width: 100%;
    }

    &-badge {
      height: size("base", 2);
      line-height: size-unitless("base", 2) - 2px;
      min-width: 0;
      padding: 0 $space-3xs;

      .e-icon {
        font-size: var(--token-icon-default-sizeSmall);
        height: var(--token-icon-default-sizeSmall);
        margin: -2px 0 0;
        width: var(--token-icon-default-sizeSmall);
      }
    }

    &-fileinput {
      overflow: hidden;
      position: relative;

      input {
        @include typography("body");

        cursor: pointer;
        direction: ltr;
        display: block;
        height: 100%;
        margin: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }
    }

    &-topnav,
    &-customerinfo {
      align-items: center;
      background: var(--token-shellbar-actionBackground);
      border-color: transparent;
      border-radius: 0;
      color: var(--token-shellbar-actionText);
      display: flex;
      height: size("shellbar", "horizonHeight");
      line-height: size("shellbar", "horizonHeight") - 2;

      &:hover,
      &:focus,
      &:active,
      &:visited {
        background: var(--token-shellbar-actionBackground);
        color: var(--token-shellbar-actionText);
        outline: none;

        .e-icon {
          color: var(--token-shellbar-actionText);
        }
      }

      &:hover,
      &:active:hover,
      &:focus,
      &:active:focus {
        background: var(--token-shellbar-actionBackgroundHover);
      }

      .e-icon {
        color: var(--token-shellbar-actionText);
      }
    }

    &-customerinfo {
      @include typography("body");

      min-width: 0;
      padding: 0 $space-xl 0 $space-l;

      &::after {
        margin-top: -$space-3xs;
        right: $space-m;
      }
    }

    &-large {
      height: var(--token-button-default-heightLarge);
      line-height: calc(var(--token-button-default-heightLarge) - 2px);
    }

    &-extended {
      line-height: 1.1em;

      small {
        display: block;
        font-size: map.get($typography, "small", "font-size");
      }
    }

    &-tile {
      height: 100%;
      line-height: 2em;
      min-height: size("base", 8);
      padding-bottom: $space-m;
      padding-top: $space-m;
      width: 100%;
    }

    &-checked {
      background: var(--token-highlight-200);
      color: var(--token-neutral-000);
    }

    &-narrow {
      padding: 0 $space-xs;

      &.e-btn-onlyicon {
        padding: 0;
        width: size("base", 3);
      }
    }

    &-sticky_left {
      // email-preview
      border-left: 0;
    }

    &-sticky_right {
      // email-preview
      border-right: 0;
    }
  }
}
