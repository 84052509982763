.e {
  &-popover {
    background-color: var(--token-box-strong-background);
    border: 1px solid var(--token-box-strong-border);
    border-radius: $border-radius;
    box-shadow: var(--token-shadow-medium);
    outline: 0;
    padding: $space-m;
    position: absolute;
    top: -99999px;
    width: auto;
    z-index: z("flying");

    &[x-placement*="bottom"] {
      margin-top: $space-s;
    }

    &[x-placement*="top"] {
      margin-bottom: $space-s;

      .e-popover__arrow {
        bottom: -#{size-unitless("base") - 2}px;
        top: auto;
        transform: rotate(225deg);
      }
    }

    &__arrow {
      background-color: var(--token-box-strong-background);
      border-left: 1px solid var(--token-box-strong-border);
      border-top: 1px solid var(--token-box-strong-border);
      height: size("base", 1.25);
      position: absolute;
      top: -#{size-unitless("base") - 2}px;
      transform: rotate(45deg);
      width: size("base", 1.25);
    }

    &-shellbar {
      $scrollbar-height-buffer: $space-m;

      max-height: calc(
        100vh - #{size("shellbar", "horizonHeight")} - #{2 * $space-s} - #{$scrollbar-height-buffer}
      );
      max-width: 480px;
      min-height: 320px;
      min-width: 320px;

      &-notifications {
        width: 480px;
      }

      &__scrollarea {
        display: block;
        margin: 0 #{-$space-m};
        max-height: calc(
          100vh - #{size("shellbar", "horizonHeight")} - #{4 * $space-s} -
            #{$scrollbar-height-buffer}
        );
        overflow: auto;
        padding: 0 $space-m;
      }
    }
  }

  &-popover_boundary {
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;

    &-shellbar {
      top: size("shellbar", "horizonHeight");
    }

    &-header {
      top: 60px;
    }

    &-navigation {
      left: size("base", 9);
    }

    &-footer {
      bottom: size("base", 6);
    }
  }
}
