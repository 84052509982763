@import "./styles";

@mixin e-alert {
  @include typography("body");

  margin-bottom: $space-m;
  padding: $space-xs $space-m;
  position: relative;
  text-align: left;

  &:empty {
    display: none;
  }
}

@mixin e-alert-modifier($modifier: "default") {
  $data: map.get($alert-style-token, $modifier);

  background: map.get($data, "background");
  border: 1px solid map.get($data, "border");
  color: map.get($data, "color");
  fill: map.get($data, "fill");
  margin-bottom: $space-m;
  padding: $space-m $space-m $space-m $space-4xl;

  &::before {
    color: map.get($data, "fill");
    content: map.get($data, "glyphs");
    font-family: var(--token-icon-default-fontFamily);
    font-size: var(--token-icon-default-size);
  }

  .e-alert__icon {
    display: none;
  }
}

.e {
  &-alert {
    @include e-alert;

    border-radius: $border-radius;
    min-height: size("base", 5);

    &::before {
      color: var(--token-neutral-800);
      content: var(--token-glyphs-info-circle);
      font-family: var(--token-icon-default-fontFamily);
      font-size: var(--token-icon-default-size);
      height: size("base", 3);
      left: $space-m;
      position: absolute;
      top: $space-m;
      width: size("base", 3);
    }

    @each $class, $data in $alert-style-token {
      $class-name: if($class == "default", "", "-#{$class}");

      &#{$class-name} {
        @include e-alert-modifier($class);
      }
    }

    a,
    a:visited,
    a:active,
    a:hover,
    a:focus {
      color: inherit;
      text-decoration: underline;
    }

    a:hover,
    a:focus {
      opacity: 0.85;
    }

    &-withicon {
      padding-left: $space-4xl;
      padding-right: $space-3xl;
    }

    &-sticky {
      margin: 0 0 -1px;
    }

    &-condensed {
      margin: 0 0 $space-3xs;
    }

    &-withclose {
      padding-right: $space-3xl;
    }

    &-inline {
      display: inline-block;
      height: size("base", 5);
      line-height: size-unitless("base", 5) - 2px;
      margin: 0 $space-xs;
      padding-bottom: 0;
      padding-top: 0;
      vertical-align: middle;

      &::before {
        top: $space-xs;
      }
    }

    &__close {
      cursor: pointer;
      position: absolute;
      right: $space-s;
      top: $space-s + 2px;
    }

    &-fixed {
      box-shadow: var(--token-shadow-strong);
      left: 0;
      margin: auto;
      position: fixed;
      right: 0;
      top: size-unitless("shellbar", "horizonHeight") + $space-s;
      width: size("base", 75);
      z-index: z("fixed-element");
    }

    &-canvas {
      border-color: transparent;
      bottom: $space-m;
      box-shadow: var(--token-shadow-strong);
      left: auto;
      position: fixed;
      right: $space-m;
      top: auto;
      width: size("base", 38);
      z-index: z("fixed-element");
    }

    &__icon {
      left: $space-m;
      position: absolute;
      top: $space-m;

      .e-icon {
        fill: inherit;
      }

      svg {
        height: size("base", 3);
        width: size("base", 3);
      }
    }
  }
}
