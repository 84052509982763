.e {
  &-sidepanel {
    background: var(--token-background-default);
    border: 0;
    box-shadow:
      0 0 5px color-mix(in srgb, var(--token-shadow-default) 10%, transparent),
      0 0 0 1px var(--token-border-default) inset;
    height: 100%;
    padding-bottom: $space-2xl;
    padding-top: size("base", 7);
    position: fixed;
    right: 0;
    top: 0;
    transform: translate3d(100%, 0, 0);
    transition: transform 0.3s ease-out;
    width: size("base", 41);
    z-index: z("modal-backdrop") + 1;

    &__header {
      @include center-content(false, "&__title");

      background: var(--token-background-dark);
      height: size("topnav", "height");
      left: 0;
      padding: 0 $space-xl 0 $space-m;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: z("default");

      &__title {
        @include typography("body");

        color: var(--token-text-light);
      }

      &__icon {
        color: var(--token-text-light);
        fill: var(--token-text-light);
        margin-right: $space-s;
      }
    }

    &__close {
      position: absolute;
      right: $space-s;
      top: 50%;
      transform: translateY(-50%);

      .e-icon {
        color: var(--token-text-light);
      }

      e-icon {
        cursor: pointer;
      }
    }

    &__content {
      height: 100%;
      overflow: auto;
    }

    &__footer {
      border-top: 1px solid var(--token-border-default);
      bottom: 0;
      height: size("base", 5);
      left: 0;
      padding: $space-s $space-m;
      position: absolute;
      width: 100%;
      z-index: z("default");
    }

    &-open {
      border: 1px solid var(--token-border-default);
      box-shadow: var(--token-shadow-medium);
      transform: translate3d(0, 0, 0);
    }
  }
}
