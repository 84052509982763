.e-step-bar {
  display: flex;
  justify-content: center;

  &-floating {
    bottom: $space-m;
    left: 50%;
    position: fixed;
    transform: translateX(-50%);
    width: calc(100% - #{$space-xl});
    z-index: z("fixed-element");
  }

  &-floating_with_navigation {
    left: calc(50% + #{size("menu", "width")});
    transform: translateX(calc(-50% - #{size("menu", "width") / 2}));
    width: calc(100% + #{-$space-xl - size-unitless("menu", "width")});
  }

  &__wrapper {
    background-color: var(--token-box-default-background);
    border: 1px solid var(--token-box-default-border);
    border-radius: $border-radius;
    box-shadow: var(--token-shadow-light);
    display: inline-flex;
    flex: 0 1 auto;
  }

  &__steps {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    list-style: none;
    margin: 0;
    padding: $space-xs;
  }

  &__separator {
    background-color: var(--token-separator-line);
    flex: 0 0 auto;
    height: 1px;
    margin: 0 $space-2xs;
    padding: 0;
    width: size("base", 4);
  }

  &__step {
    flex: 1 1 auto;
  }

  &__button {
    @include typography("body");
    @include has-focus-state;

    align-items: center;
    background-color: var(--token-action-default-background);
    border: 1px solid transparent;
    border-radius: $border-radius;
    color: var(--token-action-default-text);
    cursor: pointer;
    display: flex;
    font-weight: $text-bold-weight;
    gap: $space-xs;
    height: size("base", 6);
    justify-content: center;
    padding: 0 $space-xs;
    position: relative;

    &:where(:not(.e-step-bar__button-disabled):not(.e-step-bar__button-selected)):hover {
      background-color: var(--token-action-default-backgroundHover);
      color: var(--token-action-default-text);
    }

    &-selected {
      background-color: var(--token-action-selected-background);
    }

    &-disabled {
      color: var(--token-action-default-text);
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__bullet {
    align-items: center;
    aspect-ratio: 1;
    background-color: var(--token-action-default-bullet);
    border-radius: 100%;
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    width: size("base", 4);

    :where(.e-step-bar__button-selected) & {
      background-color: var(--token-action-selected-bullet);
    }

    :where(
        .e-step-bar__button:not(.e-step-bar__button-disabled):not(.e-step-bar__button-selected)
      ):hover
      & {
      background-color: var(--token-action-default-bulletHover);

      :where(.e-step-bar__button-selected) & {
        background-color: var(--token-action-selected-bulletHover);
      }
    }
  }

  &__label {
    flex: 1 1 auto;
    line-height: size("base", 2);
  }

  &__badge {
    align-items: center;
    aspect-ratio: 1;
    background-color: var(--token-label-error-background);
    border-radius: 100%;
    color: var(--token-label-error-text);
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    line-height: 1;
    margin-right: #{$space-2xs - size-unitless("base", 2)};
    transform: translate(-$space-xs, -$space-s);
    width: size("base", 2);
  }
}
