e-bubble {
  color: var(--token-neutral-000);
  outline: 0;
}

.e {
  &-bubble {
    background: currentcolor;
    border: 1px solid currentcolor;
    border-radius: size("base");
    box-shadow: var(--token-shadow-light);
    height: size("base", 2);
    left: 0;
    position: absolute;
    width: size("base", 2);

    &-base {
      background: var(--token-neutral-000);
      border: 1px solid var(--token-border-default);
    }

    &-range {
      background: transparent;
      border-radius: 50%;
      box-shadow:
        0 18px 0 9px var(--token-neutral-000),
        0 18px 0 10px var(--token-neutral-300),
        0 18px 0 10px transparent,
        0 18px 0 15px transparent;
      cursor: pointer;
      height: 1px;
      width: 1px;
    }

    &__line {
      background: var(--token-border-default);
      height: size("base", 4);
      position: absolute;
      width: 1px;
    }

    &-slider {
      background: var(--token-neutral-000);
      border: 1px solid var(--token-border-default);
      border-radius: 50%;
      bottom: 0;
      cursor: pointer;
      height: size("base", 3);
      transform: translateX(-10px);
      width: size("base", 3);
    }

    &-hover {
      background: var(--token-neutral-100);
      border: 1px solid var(--token-border-default);
      box-shadow: var(--token-shadow-medium);
    }

    &-active {
      background-color: var(--token-highlight-200);
      border: 1px solid var(--token-highlight-300);
      box-shadow: var(--token-shadow-medium);
      will-change: left;
      z-index: 1;
    }
  }
}

e-slider {
  &[disabled] {
    .e-bubble-slider {
      cursor: default;
    }
  }
}
